import axios from 'axios';

import { addAlert, createErrorAlert } from 'features/alertService';

const jobsListPinStatusUpdated = (payload) => {
	return {
		type: 'UPDATE_JOBS_LIST_PIN_STATUS_SUCCESS',
		payload
	};
};

const jobsListPinStatusError = () => {
	return {
		type: 'UPDATE_JOBS_LIST_PIN_STATUS_ERROR'
	};
};

const jobsListIsLoading = () => {
	return {
		type: 'FETCH_JOBS_LIST_REQUEST'
	};
};

const jobsListRequested = (payload) => {
	return {
		type: 'FETCH_JOBS_LIST_SUCCESS',
		payload
	};
};

const jobsListError = (error) => {
	return {
		type: 'FETCH_JOBS_LIST_ERROR',
		payload: error
	};
};

const fetchJobsList = (printId, page = 1, limit = 10, status = '') => {
	return async (dispatch) => {
		dispatch(jobsListIsLoading());
		try {
			const {
				data: { data = [], totalRecords = 0, isPinned = false }
			} = await axios.get(`jobs/${printId}?page=${page}&limit=${limit}&status=${status}`);

			dispatch(jobsListRequested({ data, totalRecords, isPinned }));
		} catch (e) {
			dispatch(jobsListError(e));
		}
	};
};

const updateJobsListPinStatus = (printId, currentPinStatus) => async (dispatch) => {
	dispatch(jobsListIsLoading());

	try {
		const { data: { isPinned } } = await axios.put(`/printers/${printId}/pinStatus`);

		const successMsg = isPinned
			? 'Printer is successfully pinned to the activity feed'
			: 'Printer is successfully unpinned from the activity feed';

		dispatch(addAlert({ message: successMsg }));
		dispatch(jobsListPinStatusUpdated(isPinned));
	} catch (error) {
		const errorMsg = currentPinStatus
			? 'Failed to unpin printer from activity feed'
			: 'Failed to pin printer to activity feed';

		dispatch(createErrorAlert({ message: errorMsg }));
		dispatch(jobsListPinStatusError());
	}
};

export { fetchJobsList, updateJobsListPinStatus };
