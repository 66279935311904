import styled from 'styled-components';

const Title = styled.h1.attrs((props) => ({
	children: props.text
}))`
	width: 100%;
	text-align: center;
`;

export default Title;
