import styled from 'styled-components';

export default styled.table`
	margin: 0;
	border-spacing: 0px;
	border-collapse: collapse;
	width: 100%;

	thead {
		border-bottom: 3px solid rgba(240, 240, 240, 0.5);
	}

	tbody tr:not(.nohover):is(:hover, :focus) td {
		background-color: rgba(240, 240, 240, 0.5);
	}

	tbody tr.nohover td {
		height: 50px;
		font-size: 1.125rem;
		text-align: center;
	}

	th,
	td {
		width: 200px;
		max-width: 300px;
		padding: 0;
		text-align: center;
		word-break: break-word;
	}

	th {
		font-size: 1.125rem;
		cursor: default;
	}

	td {
		font-size: 1rem;
	}
`;
