import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ErrorIndicator from 'components/ErrorIndicator';
import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';
import ErrorLabel from 'components/ui/ErrorLabel';

import { fetchApplicationsInfo } from 'features/applications/applications-info';

import { CreateLink, ItemContainer, ItemHeader, ItemIcon, ItemLink } from './style';

const ApplicationsItem = () => {
	const { count, hasWebhookError, loading, error } = useSelector((state) => state.applicationsInfo);

	const { isBanned } = useUserStatus();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchApplicationsInfo());
	}, []);

	if (error) return <ErrorIndicator />;

	return (
		<ItemContainer isBanned={isBanned}>
			<ItemLink
				as={isBanned && 'div'}
				to="/applications"
				aria-label="Go to the list of applications"
			>
				<ItemHeader>
					<ItemIcon icon="applications" />

					<h2>Applications</h2>
				</ItemHeader>

				<div>
					{loading ? (
						<PrimeSpinner
							message="Loading applications"
							justifyContent="flex-start"
							flexDirection="row"
						/>
					) : (
						<>
							<strong>{count}</strong> applications listed
							{hasWebhookError && <ErrorLabel paddingLeft />}
						</>
					)}
				</div>
			</ItemLink>

			<CreateLink
				as={isBanned && 'div'}
				to="/applications/create"
				aria-label="Create a new application"
			>
				+
			</CreateLink>
		</ItemContainer>
	);
};

export { ApplicationsItem };
