import styled, { css } from 'styled-components';

import { Button as CustomButton, CopyButton as RawCopyButton } from 'components/ui/Buttons';
import CustomContainer from 'components/ui/Container';
import FaIcon from 'components/ui/Icons';

export const Container = styled(CustomContainer)`
	width: calc(100% - 2rem);
	margin-left: 1rem;
	margin-right: 1rem;
	max-width: 600px;
	align-items: stretch;
`;

export const HeaderIcon = styled(FaIcon)`
	font-size: 40px;
`;

const baseForm = css`
	display: flex;
	align-items: center;
	margin: 30px 20px 45px;
	flex-wrap: wrap;

	@media (max-width: 600px) {
		flex-direction: row;
	}
`;

export const Form = styled.form`
	${baseForm}
`;

export const FakeForm = styled.div`
	${baseForm}
`;

export const Label = styled.label`
	font-weight: 600;
	cursor: pointer;
	order: 0;
	width: 110px;
	@media (max-width: 600px) {
		flex-grow: 1;
	}
`;

export const Input = styled.input`
	max-width: 350px;
	height: 50px;
	margin: 0 20px;
	padding: 0 10px;
	border: 3px solid #e6e6e6;
	border-radius: 5px;
	font-size: 16px;
	flex-grow: 1;
	order: 1;

	@media (max-width: 600px) {
		margin: 10px auto;
		max-width: 400px;
		width: 100%;
		order: 2;
	}
`;

export const TextInfo = styled.div`
	margin: 0 20px;
	padding: 0 10px;
	border-radius: 5px;
	font-size: 16px;
	flex-grow: 1;
	order: 1;

	display: flex;
	align-items: center;

	@media (max-width: 600px) {
		margin: 10px auto;
		max-width: 400px;
		width: 100%;
		order: 2;
	}
`;

export const Select = styled.select`
	max-width: 350px;
	height: 50px;
	padding: 0 10px;
	border: 3px solid #e6e6e6;
	appearance: none;
	background: #fff
		url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
		no-repeat 98% 50%;
	border-radius: 5px;
	font-size: 16px;
	flex-grow: 1;
	margin: 0 20px;
	order: 1;

	@media (max-width: 600px) {
		margin: 10px auto;
		max-width: 400px;
		width: 100%;
		order: 2;
	}
`;

export const Button = styled(CustomButton)`
	${(props) =>
		props.type === 'submit' &&
		css`
			width: 71px;
			height: 34px;
		`}
`;

export const UpdateButton = styled(Button)`
	order: 2;
	@media (max-width: 600px) {
		align-self: end;
		order: 1;
	}
`;

export const CopyButton = styled(RawCopyButton)`
	order: 2;
	@media (max-width: 600px) {
		order: 1;
		height: 50px;
	}
`;

export const KeyOption = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 15px 20px;

	&:last-child {
		margin-bottom: 20px;
	}

	strong {
		font-weight: 600;
		width: 110px;
		order: 0;
		@media (max-width: 600px) {
			width: auto;
			flex-grow: 1;
			height: 50px;
			line-height: 50px;
			margin-top: 10px;
		}
	}

	span {
		margin-left: 20px;
		order: 1;

		flex-grow: 1;
		@media (max-width: 600px) {
			margin-left: 0;
			order: 2;
			width: 100%;
		}

		text-overflow: ellipsis;
		overflow: hidden;
	}

	@media (max-width: 600px) {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
	}
`;

export const WebhookInfo = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 30px 20px 0;

	strong {
		font-weight: 600;
		display: flex;
		align-items: center;
		height: 38px;
	}

	${Button} {
		background: rgb(45, 150, 49);
		padding: 10px;
		color: white;
		margin-left: auto;
	}

	& > div {
		display: flex;
		width: 100%;
	}

	ul {
		margin: 0;
		padding: 0;
		li {
			margin: 0;
		}
	}

	&:last-child {
		margin-bottom: 20px;
	}
`;

export const WebhookConnectInfo = styled.div`
	width: 100%;
	margin-top: 10px;
`;

export const Error = styled.div`
	color: red;
	width: 100%;
	margin-bottom: 10px;
	text-align: center;
	margin-left: 10px;
	margin-right: 10px;
`;
