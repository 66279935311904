import axios from 'axios';
import useSWR from 'swr';

const userTasksFetcher = async () => {
	const {
		data: { data = {} },
		headers
	} = await axios.get('user-tasks');

	const totalAll = +headers['x-biz-total-all'];
	const leftToComplete = +headers['x-biz-left-to-complete'];

	return { list: data, totalAll, leftToComplete };
}

const useUserTasks = () => {
	const defaultData = {
		list: { completed: [], incomplete: [], supportCardDetails: null },
		totalAll: 0,
		leftToComplete: 0,
	};

	const { data = defaultData, error, isLoading, isValidating, mutate } = useSWR('userTasks', userTasksFetcher, {
		revalidateOnFocus: false
	});

	const markTaskCompleted = async (assistantTaskId, { applicationId, stationId } = {}) => {
		await axios.post('user-tasks', { assistantTaskId, applicationId, stationId });
		mutate();
	};

	const markSupportTaskCompleted = async () => {
		await axios.patch('/user-tasks/support');
		mutate();
	};

	const sendSupportRequest = async () => {
		await axios.post('/user-tasks/send-support-request');
		mutate();
	}

	return { data, error, isLoading, isValidating, mutate, markTaskCompleted, markSupportTaskCompleted, sendSupportRequest };
};

export default useUserTasks;
