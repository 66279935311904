import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMediaQuery } from 'usehooks-ts';

import Loader from 'components/Loader';
import FaIcon from 'components/ui/Icons';

import useUserTasks from 'hooks/swr/useUserTasks';

import CircularProgressbar from './CircularProgressbar';
import {
	AssistantContainer,
	AssistantWrapper,
	AssistantHeader,
	AssistantHeaderText,
	AssistantHeaderIcon,
	AssistantItem,
	AssistantItemCompleted,
	AssistantItemSupport,
	AssistantItemText,
	AssistantSidebar,
	AssistantTasks,
	AssistantTaskSupport,
	CancelButton,
	CloseButton,
	ConfirmationButtons,
	ConfirmationContainer,
	DoneButton,
	TaskActionRegularLink,
	TaskActionRouterLink,
	SupportTaskActionLink,
	IframeLink,
	ScheduleOnboardingIframeContainer,
	ScheduleOnboardingIframe,
	CloseAssistantBtnWrapper,
	CloseAssistantBtn,
} from './style';

dayjs.extend(relativeTime);

const Assistant = ({ closeAssistant }) => {
	const { triggerRefresh } = useSelector(({ assistant }) => assistant);
	const { data, isLoading, isValidating, mutate: updateUserTasksList } = useUserTasks();
	const isMobileIcon = useMediaQuery('(max-width: 320px)');

	useEffect(() => {
		updateUserTasksList();
	}, [triggerRefresh]);

	return (
		<AssistantContainer onClick={closeAssistant}>
			<AssistantWrapper>
				<AssistantSidebar onClick={(e) => e.stopPropagation()}>
					<Loader config={{ loading: isLoading || isValidating }}>
						<AssistantHeader>
							<CircularProgressbar
								value={data.list.completed.length}
								text={data.leftToComplete}
								total={data.totalAll}
								radius={isMobileIcon ? 30 : 40}
								strokeWidth={isMobileIcon ? 6 : 8}
								className="progressBar"
							/>

							<AssistantHeaderText>
								<h2>
									<AssistantHeaderIcon icon="stars" type="duotone" />
									Assistant
								</h2>
								<span>AI-Powered Tool for Streamlined and Efficient Print Operations Management</span>
							</AssistantHeaderText>
						</AssistantHeader>

						<SupportCardTask supportCardDetails={data.list.supportCardDetails} />

						<IncompleteTasks data={data.list.incomplete} closeAssistant={closeAssistant} />

						<CompletedTasks data={data.list.completed} isAllCompleted={!data.list.incomplete.length} />
					</Loader>
				</AssistantSidebar>

				<CloseAssistantBtnWrapper>
					<CloseAssistantBtn onClick={closeAssistant}>
						<FaIcon icon="angleLeft" />
					</CloseAssistantBtn>
				</CloseAssistantBtnWrapper>
			</AssistantWrapper>
		</AssistantContainer>
	);
};

export default Assistant;

const SupportCardTask = ({ supportCardDetails }) => {
	const { markSupportTaskCompleted, sendSupportRequest } = useUserTasks();

	const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);

	const handleSupportActionLink = () => {
		if (supportCardDetails.createNewTicket) {
			window.open('https://bizswoop.atlassian.net/servicedesk/customer/portal/4/group/4/create/10017', '_blank', 'noopener,noreferrer');
		} else {
			sendSupportRequest();
		}
	};

	if (!supportCardDetails) return null;

	return (
		<AssistantTaskSupport>
			<p>SUPPORT</p>

			<AssistantItemSupport>
				{showConfirmationPrompt ? (
					<ConfirmationPrompt
						setConfirmationPromptIndex={setShowConfirmationPrompt}
						handleConfirm={() => markSupportTaskCompleted()}
					/>
				) : (
					<>
						<AssistantItemText>
							<p>{supportCardDetails.name}</p>
							<span>{supportCardDetails.description}</span>
						</AssistantItemText>

						<CloseButton onClick={() => setShowConfirmationPrompt(true)}>
							<FaIcon icon="xmark" size="18px" color="#fff" hoverColor="#fff" />
						</CloseButton>

						<SupportTaskActionLink onClick={handleSupportActionLink}>{supportCardDetails.completionBtnText}</SupportTaskActionLink>
					</>
				)}
			</AssistantItemSupport>
		</AssistantTaskSupport>
	);
};

const CompletedTasks = ({ data, isAllCompleted }) => {
	return (
		<AssistantTasks>
			<p>{isAllCompleted ? 'Yay! All tasks completed.' : 'COMPLETED'}</p>

			{data.map(({ id, name, createdAt }) => (
				<AssistantItemCompleted key={id}>
					<FaIcon icon="circleCheck" size="18px" color="#3EA742" type="regular" />
					<p>{name}</p>
					<span>{dayjs(createdAt).fromNow()}</span>
				</AssistantItemCompleted>
			))}
		</AssistantTasks>
	);
};

const ConfirmationPrompt = ({ assistantTaskId, applicationId, stationId, setConfirmationPromptIndex, handleConfirm }) => {
	const { markTaskCompleted } = useUserTasks();

	const handleTaskCompletion = () => {
		if (handleConfirm) handleConfirm();
		else markTaskCompleted(assistantTaskId, { applicationId, stationId });
		setConfirmationPromptIndex(null);
	};

	return (
		<ConfirmationContainer>
			<p>Did you want to mark the task completed?</p>
			<span>
				Note: We recommend completing the task to automatically close the task and not marking
				manually as completed
			</span>
			<ConfirmationButtons>
				<CancelButton onClick={() => setConfirmationPromptIndex(null)}>Cancel</CancelButton>
				<DoneButton onClick={handleTaskCompletion}>Done</DoneButton>
			</ConfirmationButtons>
		</ConfirmationContainer>
	);
};

const IncompleteTasks = ({ data, closeAssistant }) => {
	const [confirmationPromptIndex, setConfirmationPromptIndex] = useState(null);
	const [showScheduleSessionIframe, setShowScheduleSessionIframe] = useState(false);

	if (!data.length) return null;

	return (
		<AssistantTasks>
			<p>NEXT STEPS</p>

			{data.map(
				({
					id,
					assistantTaskId,
					applicationId,
					stationId,
					name,
					description,
					dependency,
					completionLink,
					externalLink,
					iframeLink,
					completionBtnText
				}) => (
					<AssistantItem key={id}>
						{confirmationPromptIndex === id ? (
							<ConfirmationPrompt
								assistantTaskId={assistantTaskId}
								applicationId={applicationId}
								stationId={stationId}
								setConfirmationPromptIndex={setConfirmationPromptIndex}
							/>
						) : (
							<>
								<AssistantItemText>
									<p>{name}</p>
									<span>{description}</span>
									{dependency && <span className="dependency">{dependency}</span>}
								</AssistantItemText>

								<CloseButton onClick={() => setConfirmationPromptIndex(id)}>
									<FaIcon icon="xmark" size="18px" color="#b3b3b3" hoverColor="#585858" />
								</CloseButton>

								{iframeLink ? (
									<IframeLink onClick={() => setShowScheduleSessionIframe(true)}>{completionBtnText}</IframeLink>
								) : externalLink ? (
									<TaskActionRegularLink href={completionLink} disabled={dependency}>
										Start
									</TaskActionRegularLink>
								) : (
									<TaskActionRouterLink
										to={completionLink}
										disabled={dependency}
										onClick={closeAssistant}
									>
										Start
									</TaskActionRouterLink>
								)}
							</>
						)}
					</AssistantItem>
				)
			)}

			{showScheduleSessionIframe && (
				<ScheduleOnboardingIframeContainer>
					<button className="closeOnboardingIframe" onClick={() => setShowScheduleSessionIframe(false)}>
						<FaIcon icon="cross" />
					</button>
					<ScheduleOnboardingIframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0u_JRse_PmmtE_X0y_STNT1t4BhLrLWnfJAiKjh_NPqkYLTDQeoDlbvg7ybgm4XKOTOMQhIT5l?gv=true"></ScheduleOnboardingIframe>
				</ScheduleOnboardingIframeContainer>
			)}
		</AssistantTasks>
	);
};
