import ReactModal from 'react-modal';
import styled from 'styled-components';

const defaultStyles = {
	overlay: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.6)',
		zIndex: 999
	},
	content: {
		overflow: 'visible',
		maxWidth: '600px',
		height: 'fit-content',
		maxHeight: '90vh',
		margin: 'auto',
		padding: 'clamp(20px, 5vw, 40px)',
		border: 'none',
		borderRadius: '10px',
		background: 'rgba(0, 0, 0, 1)',
		boxShadow: '0 0 4px 0 #777777',
		zIndex: 1000
	}
};

export const Modal = ({ isOpen, onClose, styles, children }) => {
	if (!children) return null;

	return (
		<ReactModal isOpen={isOpen} onRequestClose={onClose} style={styles || defaultStyles}>
			{onClose ? (
				<CloseButton onClick={onClose} aria-label="Close the modal window">
					&#x00d7;
				</CloseButton>
			) : null}

			{children}
		</ReactModal>
	);
};

const CloseButton = styled.button`
	outline: none;
	position: absolute;
	top: -10px;
	right: -10px;
	width: 30px;
	height: 30px;
	margin: 0;
	padding: 0;
	border: none;
	border-radius: 50%;
	font-size: 22px;
	color: #ffffff;
	background-color: #000000;
	box-shadow: 0 0 4px 0 #777777;
	cursor: pointer;
	transition: all 0.1s ease-in-out;

	:is(:hover, :focus) {
		background-color: #ffffff;
		color: #000000;
	}
`;
