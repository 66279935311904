import { useMemo } from 'react';

import { CardElement } from '@stripe/react-stripe-js';
import countryList from 'react-select-country-list';
import styled from 'styled-components';
import { cardElementOptions as options } from 'utils/cardElementOptions';

// eslint-disable-next-line import/no-relative-parent-imports
import { Input, Label } from '../styles';

export const NewCardForm = ({ billingDetails, formType, setErrorMessage, onFormChange }) => {
	return (
		<div>
			<>
				<TopLabel htmlFor="cardholderName" required>
					Name on {formType === 'edit' ? 'Invoice' : 'card'}
				</TopLabel>

				<FormControl
					id="cardholderName"
					name="name"
					value={billingDetails.name}
					onChange={onFormChange}
				/>
			</>

			<>
				<Label htmlFor="cardField" required>
					Card information
				</Label>

				<CardWrapper>
					<CardElement
						id="cardField"
						options={options}
						aria-required="true"
						onChange={() => setErrorMessage(null)}
					/>
				</CardWrapper>
			</>
		</div>
	);
};

export const CardEditForm = ({ billingDetails, onFormChange, setErrorMessage }) => {
	return (
		<EditFormGroup>
			<NewCardForm
				formType="edit"
				billingDetails={billingDetails}
				onFormChange={onFormChange}
				setErrorMessage={setErrorMessage}
			/>

			<div>
				<Label htmlFor="line1" required>
					Address 1
				</Label>

				<FormControl
					id="line1"
					name="line1"
					value={billingDetails.address.line1 || ''}
					onChange={onFormChange}
				/>
			</div>

			<div>
				<Label htmlFor="line2">Address 2</Label>

				<FormControl
					id="line2"
					name="line2"
					value={billingDetails.address.line2 || ''}
					onChange={onFormChange}
					aria-required="false"
				/>
			</div>

			<div>
				<Label htmlFor="city" required>
					City
				</Label>

				<FormControl
					id="city"
					name="city"
					value={billingDetails.address.city || ''}
					onChange={onFormChange}
				/>
			</div>

			<div>
				<Label htmlFor="state" required>
					State
				</Label>

				<FormControl
					id="state"
					name="state"
					value={billingDetails.address.state || ''}
					onChange={onFormChange}
				/>
			</div>

			<div>
				<Label htmlFor="country" required>
					Country
				</Label>

				<SelectFormControl
					county={billingDetails.address.country || ''}
					onFormChange={onFormChange}
				/>
			</div>
		</EditFormGroup>
	);
};

const SelectFormControl = ({ county, onFormChange }) => {
	const countryOptions = useMemo(() => countryList().getData(), []);

	return (
		<FormControl as="select" id="country" name="country" value={county} onChange={onFormChange}>
			<option value="" hidden></option>

			{countryOptions.map(({ value, label }) => (
				<option value={value} key={value}>
					{label}
				</option>
			))}
		</FormControl>
	);
};

const TopLabel = styled(Label)`
	padding-top: 0.5rem;
`;

const FormControl = styled(Input).attrs((props) => ({
	'aria-required': props['aria-required'] || true
}))`
	&[name='country'] {
		padding: 0 0.5rem;
	}
`;

const CardWrapper = styled.div`
	width: 100%;
	height: 40px;
	padding: 0.5rem;
	border: solid 2px #e2e8f0;
	border-radius: 8px;
	color: #a4b1c3;
	background-color: #edf2f7;
`;

const EditFormGroup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	> div {
		width: 100%;
	}

	${FormControl}, ${CardWrapper} {
		background-color: #ffffff;
	}

	@media only screen and (min-width: 400px) {
		flex-direction: row;
		flex-wrap: wrap;

		> div:not(:first-of-type) {
			max-width: 48%;
		}
	}
`;
