import styled from 'styled-components';

export const ProfileMenuBlock = styled.div`
	position: absolute;
	left: 100%;
	bottom: 0;
	display: flex;
	flex-direction: column;
	min-width: 200px;
	width: max-content;
	max-width: 300px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
	background-color: #fff;
	transform: scale(0);
	transform-origin: left bottom;
	transition: all 300ms 200ms ease-in-out;

	&:hover {
		transform: scale(1);
	}
`;

export const ProfileData = styled.div`
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	padding: 10px 20px;

	span {
		display: block;
		padding-top: 10px;
		font-size: 0.9rem;
		word-wrap: break-word;
	}
`;

export const ProfileMenuItems = styled.div`
	ul {
		width: 100%;
		min-height: auto;
		list-style: none;

		& > li {
			display: flex;
			width: 100%;
			height: 40px;
			margin: 0;
			padding: 0;

			&[role='separator'] {
				height: 0;
				border-top: 1px solid rgba(0, 0, 0, 0.15);
			}

			&:not([role='separator']):hover {
				background-color: rgba(240, 240, 240, 0.5);
				a {
					color: #000;
				}
			}

			&:last-child {
				border-radius: 0 0 10px 10px;
			}

			a {
				padding: 10px 20px;
				font-size: 1rem;
				font-weight: 600;
				color: #585858;
				text-decoration: none;
			}
		}
	}
`;
