import styled, { css, keyframes } from 'styled-components';

export const AlertContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2000;
	width: 100%;
	max-width: 350px;
	padding: 0 10px;
`;

const slideIn = keyframes`
	from {
		transform: translateY(-100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
`;

const slideOut = keyframes`
	from {
		transform: translateY(0);
		opacity: 1;
	}
	to {
		transform: translateY(-100%);
		opacity: 0;
	}
`;

const borderColor = { error: '#ebccd1', success: '#ceebcc', taskCompletion: '#b2ccf4' };
const textColor = { error: '#a94442', success: '#42a949', taskCompletion: '#4f8fea' };

export const ANIMATION_DURATION = 700;

export const AlertBox = styled.div`
	width: 100%;
	border-radius: 10px;
	border: 3px solid #ebccd1;
	color: #a94442;
	background-color: white;
	min-height: 90px;
	display: grid;
	place-items: center;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	padding: 10px 20px;
	text-align: center;
	margin-bottom: 15px;
	animation: ${(props) => (props.hide ? slideOut : slideIn)} ${ANIMATION_DURATION}ms ease-in-out forwards;

	${(props) =>
		props.type &&
		css`
			border-color: ${borderColor[props.type]};
			color: ${textColor[props.type]};
		`}
`;
