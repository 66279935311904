import { Button } from './style';

const isLessInPriority = (plan, currPlan) => {
	// List of the plans names in ascending upgrade order
	const plans = ['Trial', 'Basics', 'Plus', 'Pro', 'Growth'];
	return plans.indexOf(plan) < plans.indexOf(currPlan);
};

const getButtonText = (isDowngrade, cancelDate, planName) => {
	if (cancelDate && planName === 'Trial') return '';

	if (isDowngrade) return planName === 'Trial' ? 'Downgrade to Free' : 'Downgrade';

	return 'Upgrade';
};

const SelectButton = ({ id, planName, currentPlan, selectedPlanId, submit }) => {
	const currPlanId = currentPlan?.id || null;
	const currPlanName = currentPlan?.name || null;
	const cancelDate = currentPlan?.cancelDate || null;
	const isLegacy = currentPlan?.isLegacy || false;

	if (selectedPlanId === id && id !== currPlanId)
		return <Button planName={planName} tabIndex={-1} noHover />;

	if (!currPlanId || isLegacy)
		return (
			<Button onClick={submit} planName={planName}>
				Select
			</Button>
		);

	if (currPlanId === id && cancelDate)
		return (
			<Button planName={planName} noHover>
				Plan set to cancel {cancelDate}
			</Button>
		);

	const isDowngrade = isLessInPriority(planName, currPlanName);

	return (
		<Button
			planName={planName}
			current={currPlanId === id}
			disabled={cancelDate && planName === 'Trial'}
			noHover={cancelDate && planName === 'Trial'}
			outline={isDowngrade}
			onClick={submit}
		>
			{currPlanId === id ? 'Your Plan' : getButtonText(isDowngrade, cancelDate, planName)}
		</Button>
	);
};

export default SelectButton;
