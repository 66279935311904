import styled from 'styled-components';
import CustomContainer from 'components/ui/Container';
import FaIcon from 'components/ui/Icons';
import { Button as CustomButton } from 'components/ui/Buttons';

export const Button = styled(CustomButton)`
	width: 120px;
	height: 40px;
`;

export const Container = styled(CustomContainer)`
	align-items: stretch;
	width: 600px;

	@media (max-width: 600px) {
		width: 90%;
		margin: 20px 20px auto;
	}
`;

export const HeaderIcon = styled(FaIcon)`
	font-size: 40px;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 30px 30px;
`;

export const Label = styled.label`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 30px;
	font-weight: 600;
	cursor: pointer;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const Input = styled.input`
	width: 100%;
	height: 50px;
	margin-left: 50px;
	padding: 0 10px;
	border: 3px solid #e6e6e6;
	border-radius: 5px;
	font-size: 16px;

	@media (max-width: 600px) {
		margin: 0;
	}
`;
