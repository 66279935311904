import styled, { css } from 'styled-components';

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.2);
	transform: translateX(${({ isVisible }) => (isVisible ? 0 : '-100%')});
	cursor: pointer;
	z-index: 100;
`;

export const Sidebar = styled.aside`
	position: fixed;
	top: 0;
	left: 0;
	width: 250px;
	height: 100vh;
	background-color: #fafafa;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	z-index: 100;

	${({ isVisible }) =>
		isVisible &&
		css`
			box-shadow: 5px 0 20px -5px rgba(0, 0, 0, 0.5);
			transform: translateX(0);
		`};
`;

export const ToggleSidebar = styled.button`
	position: absolute;
	top: 50%;
	right: -30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border: none;
	border-radius: 0 50% 50% 0;
	background-color: rgb(169 169 169 / 25%);
	transition: transform 0.3s ease-in-out;
	cursor: pointer;

	::before {
		content: '\u276F';
		font-size: 1.5rem;
		color: #818181;
		transform: translateX(5px);
	}

	:hover,
	:focus {
		::before {
			color: #000;
		}
	}

	${({ isVisible }) => {
		if (isVisible)
			return css`
				box-shadow: 10px 0 20px -10px rgba(0, 0, 0, 0.5);
				background-color: #fafafa;

				&::before {
					content: '\u271B';
					font-size: 2rem;
					transform: translateX(0) rotate(45deg);
				}
			`;

		return css`
			&:hover,
			&:focus {
				background-color: #fafafa;
				transform: translateX(10px);
			}
		`;
	}}
`;

export const SidebarContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-top: ${({ isMacOS }) => (isMacOS ? '26px' : '')};
`;

export const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px;

	h3 {
		margin: 0;
		padding: 0;
		font-size: 1.1rem;
		text-align: center;

		i {
			font-size: 1.1rem;
			color: #000000;
		}
	}

	strong,
	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	strong {
		margin: 10px 0 5px;
	}
`;

export const Buttons = styled.div`
	margin-top: auto;
`;

export const LogoutButton = styled.button`
	outline: none;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 10px 0;
	border: none;
	font-size: 1.2rem;
	font-weight: 600;
	background-color: #263a53;
	color: #ffffff;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	i {
		margin-right: 8px;
		font-size: 1.2rem;
		font-weight: 500;
		color: #ffffff;
	}

	:hover,
	:focus {
		box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.1);

		i {
			color: #ffffff;
		}
	}

	:first-of-type {
		font-size: 1.3rem;
		background-color: transparent;
		color: #263a53;

		i {
			font-size: 1.3rem;
			color: #263a53;
		}
	}
`;
