const initialState = {
	plan: null,
	isHavePaymentMethod: null,
	loading: true,
	error: null
};

const currentPlan = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_CURRENT_PLAN_REQUEST':
			return {
				...state
			};

		case 'FETCH_CURRENT_PLAN_SUCCESS':
			return {
				...state,
				plan: action.payload.plan,
				isHavePaymentMethod: action.payload.isHavePaymentMethod,
				loading: false,
				error: null
			};

		case 'FETCH_CURRENT_PLAN_ERROR':
			return {
				...state,
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};

export default currentPlan;
