import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';

import Header from 'components/Header';
import Pagination from 'components/Pagination';
import PeriodPicker from 'components/PeriodPicker';

import { fetchAdminPanelList } from 'actions/admin-panel-list';

import AdminTable from './AdminTable';
import { Container, DateRangeContainer, Form, Label } from './style';

const AdminPanel = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [usersFilter, setUsersFilter] = useState('');
	const [includeDateRange, setIncludeDateRange] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const isMobile = useMediaQuery('(max-width: 768px)');

	const { totalRecords } = useSelector(({ getAdminPanelList }) => getAdminPanelList);

	const pageLimit = 10;
	const totalPages = useMemo(() => {
		return Math.ceil(totalRecords / pageLimit);
	}, [totalRecords, pageLimit]);

	const fetchFilteredList = useCallback(
		debounce((includeDateRange, startDate, endDate, usersFilter, startPage = 1, pageLimit = 10) => {
			dispatch(
				fetchAdminPanelList({
					includeDateRange,
					startDate,
					endDate,
					filter: usersFilter,
					page: startPage,
					limit: pageLimit
				})
			);
			setCurrentPage(1);
		}, 500),
		[dispatch, fetchAdminPanelList]
	);

	useEffect(() => {
		dispatch(
			fetchAdminPanelList({
				includeDateRange,
				startDate,
				endDate,
				filter: usersFilter,
				page: currentPage,
				limit: pageLimit
			})
		);
	}, [currentPage, pageLimit]);

	useEffect(() => {
		fetchFilteredList(includeDateRange, startDate, endDate, usersFilter, 1, pageLimit);
	}, [includeDateRange, startDate, endDate, usersFilter, pageLimit, fetchFilteredList]);

	const onFilterUsers = (e) => setUsersFilter(e.target.value);

	const handlePageChange = (page) => setCurrentPage(page);

	const toggleDateRangeFilter = () => setIncludeDateRange(!includeDateRange);

	const checkDatesOrder = (date1, date2) => dayjs(date1).isAfter(dayjs(date2));

	const handleStartDateChange = (date) => {
		if (checkDatesOrder(date, endDate)) {
			setStartDate(endDate);
			setEndDate(date);
		} else {
			setStartDate(date);
		}
	};

	const handleEndDateChange = (date) => {
		if (checkDatesOrder(startDate, date)) {
			setEndDate(startDate);
			setStartDate(date);
		} else {
			setEndDate(date);
		}
	};

	return (
		<Container>
			<Header text="Users" />

			<div>
				<Form>
					<Label>
						Filter Users:
						<input type="text" value={usersFilter} onChange={onFilterUsers} />
					</Label>

					<DateRangeContainer>
						<PeriodPicker
							className="startDate"
							value={startDate}
							disabled={!includeDateRange}
							handleDateChange={handleStartDateChange}
						/>
						<PeriodPicker
							className="endDate"
							value={endDate}
							disabled={!includeDateRange}
							handleDateChange={handleEndDateChange}
						/>

						<input
							type="checkbox"
							value={includeDateRange}
							onClick={toggleDateRangeFilter}
							title="Enable/disable date range filter"
						/>
					</DateRangeContainer>
				</Form>

				<AdminTable isMobile={isMobile} />

				<Pagination
					currentPage={currentPage}
					onPageChange={handlePageChange}
					totalPages={totalPages}
					pageNeighbours={isMobile ? 0 : 2}
				/>
			</div>
		</Container>
	);
};

export default AdminPanel;
