export const SHOW_ASSISTANT = 'SHOW_ASSISTANT';
export const HIDE_ASSISTANT = 'HIDE_ASSISTANT';
export const TRIGGER_ASSISTANT_REFRESH = 'TRIGGER_ASSISTANT_REFRESH';

const initialState = {
	showAssistant: false,
	triggerRefresh: false
};

const assistant = (state = initialState, action) => {
	switch (action.type) {
		case 'SHOW_ASSISTANT':
			return { ...state, showAssistant: true };

		case 'HIDE_ASSISTANT':
			return { ...state, showAssistant: false };

		case 'TRIGGER_ASSISTANT_REFRESH':
			return { ...state, triggerRefresh: !state.triggerRefresh }

		default:
			return state;
	}
};

export default assistant;
