const formatPlan = (obj) => {
	if (!obj) return null;

	return {
		id: obj.id,
		name: obj.name || '',
		isLegacy: obj.isLegacy,
		included: obj.included,
		upTo: obj.upTo || '',
		perExcess: obj.perExcess || '',
		isFree: obj.isFree,
		term: obj.subTerm,
		cancelDate: obj.cancelDate,
		pastDueInvoices: obj.pastDueInvoices
	};
};

export default formatPlan;
