import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Header from 'components/Header';
import { PrimeSpinner } from 'components/Spinners';
import ErrorLabel from 'components/ui/ErrorLabel';
import PageHeader from 'components/ui/PageHeader';

import useUserTasks from 'hooks/swr/useUserTasks';

import {
	fetchApplication,
	testWebhookConnect,
	updateApplication
} from 'features/applications/application';
import { deleteApplication } from 'features/applications/applications-list';

import { WebhookHelpModal } from './WebhookHelpModal';
import {
	Button,
	Container,
	CopyButton,
	Error,
	FakeForm,
	Form,
	HeaderIcon,
	Input,
	KeyOption,
	Label,
	TextInfo,
	UpdateButton,
	WebhookConnectInfo,
	WebhookInfo
} from './style';

const Single = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { mutate: updateUserTasksList } = useUserTasks();

	const [formInputs, setFormInputs] = useState({});
	const [loadingMessage, setLoadingMessage] = useState('Loading application');

	const { loading, data, webhookTestConnectMark, error } = useSelector(
		({ application }) => application
	);

	const {
		name = '',
		webhookUrl = '',
		publicKey = '',
		secretKey = '',
		webhookFail = '',
		homeUrl = '',
		version = '',
		platform = ''
	} = data ?? {};

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchApplication(id));
	}, []);

	useEffect(() => {
		setFormInputs({
			name,
			webhookUrl,
			homeUrl,
			version,
			platform
		});
	}, [data]);

	const handleFormChange = (e) =>
		setFormInputs((prevFormInput) => ({
			...prevFormInput,
			[e.target.name]: e.target.value
		}));

	const handleFormUpdate = (e) => {
		e.preventDefault();
		const { name, webhookUrl, homeUrl } = e.target;

		setLoadingMessage('Updating application');

		if (name) dispatch(updateApplication({ name: name.value }, id));

		if (webhookUrl) {
			dispatch(updateApplication({ webhookUrl: webhookUrl.value }, id));
		}

		if (homeUrl) {
			dispatch(updateApplication({ homeUrl: homeUrl.value }, id));
		}
	};

	const handleTestWebhookConnect = async (e) => {
		e.preventDefault();

		setLoadingMessage('Testing connection');

		await dispatch(testWebhookConnect(id));

		updateUserTasksList();
	};

	const handleDeleteApplication = async (id) => {
		if (window.confirm('Are you sure you want to permanently delete this Application?')) {
			await dispatch(deleteApplication(id));
			updateUserTasksList();
			navigate('/applications');
		}
	};

	if (loading) return <PrimeSpinner message={loadingMessage} />;

	return (
		<Container>
			<PageHeader>
				<Link to="/applications" aria-label="Back to the list of applications">
					<HeaderIcon icon="backNavigation" type="regular" />
				</Link>

				<Header text="Application" icon="applications" />

				{data ? (
					<Button
						onClick={() => handleDeleteApplication(id)}
						arial-label={`Delete ${name} application`}
					>
						<HeaderIcon icon="delete" type="regular" />
					</Button>
				) : (
					<div style={{ width: 40 }} />
				)}
			</PageHeader>

			<div>
				{typeof error === 'string' ? (
					<Error>{error}</Error>
				) : (
					<>
						<Form onSubmit={handleFormUpdate}>
							{error?.name && <Error>{error?.name}</Error>}
							<Label htmlFor="applicationName">Name</Label>
							<Input
								type="text"
								id="applicationName"
								onChange={handleFormChange}
								value={formInputs?.name || ''}
								placeholder="Enter your application name"
								name="name"
							/>
							<UpdateButton type="submit" value="Update" />
						</Form>

						<Form onSubmit={handleFormUpdate}>
							{error?.homeUrl && <Error>{error?.homeUrl}</Error>}
							<Label htmlFor="homeUrl">URL</Label>
							<Input
								type="text"
								id="homeUrl"
								onChange={handleFormChange}
								value={formInputs?.homeUrl || ''}
								placeholder="Enter your URL"
								name="homeUrl"
							/>
							<UpdateButton type="submit" value="Update" />
						</Form>

						<FakeForm>
							<Label>Platform</Label>
							<TextInfo>
								{formInputs?.platform === 'wordpress' && 'WordPress'}
								{(formInputs?.platform === 'custom' || formInputs?.platform == null) && 'Custom'}
							</TextInfo>
						</FakeForm>

						<FakeForm>
							<Label>API Version</Label>
							<TextInfo>
								{formInputs?.version === '0' && 'Legacy API'}
								{(formInputs?.version === '1' || formInputs?.version == null) && 'REST API'}
							</TextInfo>
						</FakeForm>
						{formInputs.version !== '0' && (
							<Form onSubmit={handleFormUpdate}>
								{error?.webhookUrl && <Error>{error?.webhookUrl}</Error>}
								<Label htmlFor="webhookUrl">Webhook URL</Label>
								<Input
									type="text"
									id="webhookUrl"
									onChange={handleFormChange}
									value={formInputs?.webhookUrl || ''}
									placeholder="Enter your URL"
									name="webhookUrl"
								/>
								<UpdateButton type="submit" value="Update" />
							</Form>
						)}

						<KeyOption>
							<strong>Public Key</strong>
							<span>{publicKey}</span>
							<CopyButton
								copy={publicKey}
								aria-label="Copy the public key"
								title="Copy the public key"
							/>
						</KeyOption>

						<KeyOption>
							<strong>Secret Key</strong>
							<span>{secretKey}</span>
							<CopyButton
								copy={secretKey}
								aria-label="Copy the secret key"
								title="Copy the secret key"
							/>
						</KeyOption>

						{version !== '0' && (
							<WebhookInfo>
								<strong>Webhook Info</strong>
								<Button onClick={handleTestWebhookConnect}>Perform test connection</Button>
								<div>
									{webhookTestConnectMark && !webhookFail.hasError && (
										<WebhookConnectInfo>
											Successfully connected with webhook server
										</WebhookConnectInfo>
									)}
									{webhookFail.hasError && (
										<WebhookConnectInfo>
											<ErrorLabel paddingRight />
											{webhookFail.displayError && (
												<>
													{webhookFail.displayError}
													<br />
												</>
											)}
											Error connection to webhook server.
											<WebhookHelpModal />
										</WebhookConnectInfo>
									)}
								</div>
							</WebhookInfo>
						)}
					</>
				)}
			</div>
		</Container>
	);
};

export default Single;
