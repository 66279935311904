import AlertService from 'components/AlertService';
import { useAuthorization } from 'components/Authorization';
import Footer from 'components/Footer';
import NavBar from 'components/NavBar';
import NotificationBanners from 'components/NotificationBanners';
import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';

import { BasicBackground, Container } from './style';

const BasicLayout = ({ children }) => {
	const { isLoggedIn, profileConstraint } = useAuthorization();
	const { isBanChecking } = useUserStatus();

	return (
		<Container>
			{isLoggedIn && (
				<>
					<NavBar isAdmin={profileConstraint.isAdmin} />
					<NotificationBanners />
				</>
			)}

			{isBanChecking && <PrimeSpinner />}

			{!isBanChecking && (
				<BasicBackground withNav={isLoggedIn}>
					<AlertService />
					{children}
					{isLoggedIn && <Footer />}
				</BasicBackground>
			)}
		</Container>
	);
};

export default BasicLayout;
