import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useUserStatus } from 'components/UserStatus';

import { useCurrentPlan } from 'hooks/useCurrentPlan';
import { useUserSettings } from 'hooks/useUserSettings';

import DevModeEnabledBanner from './DevModeEnabledBanner';
import PaymentOverdueBanner from './PaymentOverdueBanner';
import PrintStatusBanner from './PrintStatusBanner';
import ServicePausedBanner from './ServicePausedBanner';
import VerifyAccountBanner from './VerifyAccountBanner';

const NotificationBanners = () => {
	const { settings, loading, error } = useUserSettings();
	const { pathname } = useLocation();

	const { isBanned, isBanChecking } = useUserStatus();

	if (loading || isBanChecking || error) return null;

	if (isBanned && pathname !== '/subscription-plans') return <ServicePausedBanner />;

	if (settings && settings?.enableDevMode) return <DevModeEnabledBanner />;

	return <BannersGroup />;
};

const BannersGroup = () => {
	const { currentPlan, loading, error } = useCurrentPlan();

	if (loading || error || currentPlan === null) return null;

	return (
		<BannersBox>
			<VerifyAccountBanner />
			<PaymentOverdueBanner currentPlan={currentPlan} />
			<PrintStatusBanner currentPlan={currentPlan} />
		</BannersBox>
	);
};

export default NotificationBanners;

const BannersBox = styled.div`
	margin-top: 60px;

	@media only screen and (min-width: 992px) {
		margin-top: 0;
	}
`;
