const initialState = {
	applicationAnalytics: null,
	stationAnalytics: null,
	printerAnalytics: null,
	dayOfWeekAnalytics: null,
	date: null,
	loading: true,
	error: null
};

const jobsAnalytics = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_JOBS_ANALYTICS_REQUEST':
			return {
				...initialState,
				date: action.payload.date
			};

		case 'FETCH_JOBS_ANALYTICS_SUCCESS':
			return {
				applicationAnalytics: action.payload.applicationAnalytics,
				stationAnalytics: action.payload.stationAnalytics,
				printerAnalytics: action.payload.printerAnalytics,
				dayOfWeekAnalytics: action.payload.dayOfWeekAnalytics,
				date: state.date,
				loading: false,
				error: null
			};

		case 'FETCH_JOBS_ANALYTICS_ERROR':
			return {
				...initialState,
				loading: false,
				error: action.payload
			};

		case 'FETCH_JOBS_APPLICATION_ANALYTICS_SUCCESS':
			return { ...state, applicationAnalytics: action.payload };

		case 'FETCH_JOBS_STATION_ANALYTICS_SUCCESS':
			return { ...state, stationAnalytics: action.payload };

		case 'FETCH_JOBS_PRINTER_ANALYTICS_SUCCESS':
			return { ...state, printerAnalytics: action.payload };

		default:
			return state;
	}
};

export default jobsAnalytics;
