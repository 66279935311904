import { Suspense, lazy } from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { useAuthorization } from 'components/Authorization';
import Loader from 'components/Loader';
import { useUserStatus } from 'components/UserStatus';

import Activities from './Activities';
import AdminPanel from './AdminPanel';
import Applications from './Applications';
import Deactivated from './Deactivated';
import Jobs from './Jobs/List';
import Main from './Main';
import Resources from './Resources';
import Settings from './Settings';
import Stations from './Stations';
import Stripe from './Stripe';

const Analytics = lazy(() => import('./Analytics'));

const Pages = () => {
	const authorization = useAuthorization();

	const isAdmin = authorization.profileConstraint.isAdmin;

	return (
		<Suspense fallback={<Loader config={{ loading: true, screen: true, full: true }} />}>
			<Routes>
				<Route index path="/" element={<Main />} />
				<Route path="/print-analytics" element={<Analytics />} />
				<Route path="/subscription-plans" element={<Stripe />} />
				<Route path="/deactivated" element={<Deactivated />} />
				<Route path="/resources" element={<Resources />} />
				<Route path="/settings" element={<Settings />} />

				<Route path="/" element={<PrivateOutlet />}>
					<Route path="/admin-panel/*" element={<AdminRoute isAdmin={isAdmin} />} />
					<Route path="/stations/*" element={<Stations />} />
					<Route path="/jobs/:printId" element={<Jobs />} />
					<Route path="/applications/*" element={<Applications />} />
					<Route path="/activity" element={<Activities />} />
				</Route>
			</Routes>
		</Suspense>
	);
};

const PrivateOutlet = () => {
	const { isBanned } = useUserStatus();

	return isBanned ? <Navigate replace to="/" /> : <Outlet />;
};

const AdminRoute = ({ isAdmin }) => {
	return isAdmin ? <AdminPanel /> : <Navigate replace to="/" />;
};

export default Pages;
