import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Header from 'components/Header';
import { PrimeSpinner } from 'components/Spinners';
import { Button } from 'components/ui/Buttons';
import PageHeader from 'components/ui/PageHeader';

import { fetchStation } from 'features/stations/station';
import { deleteStation } from 'features/stations/stations-list';

import KeyOptions from './KeyOptions';
import StationForm from './StationForm';
import { Container, Icon, OpeningLink, PrintersList } from './style';

const RenderList = ({ list, stationId }) => {
	if (!list.length) {
		return (
			<tr className="nohover">
				<td>There are no active printers connected to this station yet</td>
			</tr>
		);
	}

	return list.map(({ name, id, deletedAt }) => (
		<tr key={id}>
			<td>
				<OpeningLink isDeleted={deletedAt} to={{ pathname: `/jobs/${id}`, state: { stationId } }}>
					{name}
				</OpeningLink>
			</td>
		</tr>
	));
};

const Single = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	const [loadingMessage, setLoadingMessage] = useState('Loading station');

	const { station, printers, loading } = useSelector(({ station }) => station);

	useEffect(() => {
		dispatch(fetchStation(id));
	}, []);

	const handleDeleteStation = (id) => (e) => {
		e.preventDefault();

		if (window.confirm('Are you sure you want to permanently delete this station?')) {
			dispatch(deleteStation(id));
			navigate('/stations');
		}
	};

	if (loading) return <PrimeSpinner message={loadingMessage} />;

	const { name, publicKey, secretKey, displayOptions, version } = station;

	return (
		<Container>
			<PageHeader>
				<Link to="/stations" aria-label="Back to the list of stations">
					<Icon icon="backNavigation" type="regular" />
				</Link>

				<Header text="Station" icon="stations" />

				<Button onClick={handleDeleteStation(id)} arial-label={`Delete ${name} station`}>
					<Icon icon="delete" type="regular" />
				</Button>
			</PageHeader>

			<StationForm
				stationId={id}
				version={version}
				name={name}
				displayOptions={displayOptions}
				onSubmit={() => setLoadingMessage('Updating station')}
			/>

			<KeyOptions publicKey={publicKey} secretKey={secretKey} />

			<PrintersList aria-label="My printers">
				<thead>
					<tr>
						<th scope="col">Printers</th>
					</tr>
				</thead>

				<tbody>
					<RenderList list={printers} stationId={id} />
				</tbody>
			</PrintersList>
		</Container>
	);
};

export default Single;
