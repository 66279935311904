import styled from 'styled-components';

import { Button as CustomButton } from 'components/ui/Buttons';
import CustomContainer from 'components/ui/Container';
import FaIcon from 'components/ui/Icons';

export const Container = styled(CustomContainer)`
	width: 600px;
	align-items: stretch;

	@media (max-width: 600px) {
		width: 90%;
		margin: 20px 20px auto;
	}
`;

export const HeaderIcon = styled(FaIcon)`
	font-size: 40px;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 30px 30px;
`;

export const Label = styled.label`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 30px;
	font-weight: 600;
	cursor: pointer;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const Input = styled.input`
	width: 100%;
	max-width: 350px;
	height: 50px;
	padding: 0 10px;
	border: 3px solid #e6e6e6;
	border-radius: 5px;
	font-size: 16px;

	@media (max-width: 600px) {
		max-width: none;
	}
`;

export const Select = styled.select`
	width: 100%;
	max-width: 350px;
	height: 50px;
	padding: 0 10px;
	border: 3px solid #e6e6e6;
	appearance: none;
	background: #fff
		url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
		no-repeat 98% 50%;
	border-radius: 5px;
	font-size: 16px;

	@media (max-width: 600px) {
		max-width: none;
	}
`;

export const Button = styled(CustomButton)`
	width: 120px;
	height: 40px;
	font-weight: 600;
	color: #fff;
`;

export const WebhookInfo = styled.div`
	height: 50px;
	line-height: 50px;
`;

export const Error = styled.div`
	color: red;
	margin-bottom: 10px;
`;
