import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import axios from 'axios';
import jwt from 'jsonwebtoken';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

const http = axios.create({ baseURL: `${process.env.REACT_APP_LOGIN_BASE_API_URL}/api` });

const defaultProfileConstraint = {
	isAdmin: false
};

export const AuthorizationContext = createContext({
	isLoggedIn: false,
	isInitialChecking: true,
	profileConstraint: defaultProfileConstraint
});

export const useAuthorization = () => useContext(AuthorizationContext);

const Authorization = (props) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isInitialChecking, setIsInitialChecking] = useState(true);
	const [profileConstraint, setProfileConstraint] = useState(defaultProfileConstraint);

	const jwtTokenCheck = () => {
		const payload = getJWTPayload();
		if (payload === null) return setIsLoggedIn(false);

		const result = payload.exp > currentTime() || payload.nra > currentTime();

		setIsLoggedIn(result);
		setProfileConstraint({ isAdmin: Boolean(payload.isAdmin) });

		return result;
	};

	const fetchToken = useCallback(async () => {
		try {
			const {
				data: { token }
			} = await http.post(
				'/refresh',
				{},
				{ headers: { Authorization: `Bearer ${getAuthToken()}` } }
			);

			setAuthToken(token);
		} catch {
			setAuthToken(null);
		}
	}, []);

	const refreshToken = useCallback(
		async (force = false) => {
			if (force) return fetchToken();

			if (!isLoggedIn) return;

			const payload = getJWTPayload();
			if (payload === null) return;

			try {
				if (payload.exp - currentTime() < timeToRefresh()) {
					if (payload.nra > currentTime()) {
						await fetchToken();
					} else {
						setAuthToken(null);
					}
				}
			} catch {
				setAuthToken(null);
			}
		},
		[isLoggedIn, fetchToken]
	);

	useEffect(() => {
		if (isInitialChecking) {
			(async () => {
				if (jwtTokenCheck()) {
					try {
						await http.post(
							'/verify',
							{},
							{ headers: { Authorization: `Bearer ${getAuthToken()}` } }
						);
					} catch {
						await refreshToken(true);
					}
				}

				setIsInitialChecking(false);
			})();
		}

		if (!isLoggedIn) return;

		refreshToken();

		const intervalForRefresh = window.setInterval(refreshToken, intervalForCheck() * 1000);

		return () => window.clearInterval(intervalForRefresh);
	}, [isLoggedIn, isInitialChecking, refreshToken]);

	useEffect(() => {
		window.addEventListener('appAuthDataChanges', jwtTokenCheck);

		return () => {
			window.removeEventListener('appAuthDataChanges', jwtTokenCheck);
		};
	}, []);

	return (
		<AuthorizationContext.Provider value={{ isLoggedIn, isInitialChecking, profileConstraint }}>
			<Routes>
				<Route path="/connect" element={<Connect />} />
				<Route path="/login/:token" element={<Login />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="*" element={<Content {...props} />} />
			</Routes>
		</AuthorizationContext.Provider>
	);
};

const Content = (props) => props.children;

export default Authorization;

const AUTH_TOKEN_STORE = 'authToken';

const intervalForCheck = () => {
	const defaultValue = 60;
	const payload = getJWTPayload();
	if (payload == null) return defaultValue;
	return Math.min((payload.nra - currentTime()) / 20, defaultValue);
};

const timeToRefresh = () => {
	const defaultValue = 5 * 60;
	return defaultValue;
};

const getJWTPayload = () => jwt.decode(getAuthToken());

export const setAuthToken = (token) => {
	window.localStorage.setItem(AUTH_TOKEN_STORE, token);

	const event = new CustomEvent('appAuthDataChanges');

	window.dispatchEvent(event);
};

export const getAuthToken = () => {
	const data = window.localStorage.getItem(AUTH_TOKEN_STORE);

	try {
		return JSON.parse(data);
	} catch (e) {
		return data;
	}
};

const currentTime = () => Math.floor(Date.now() / 1000);

const Connect = () => {
	const { isLoggedIn, isInitialChecking } = useAuthorization();
	const navigation = useNavigate();
	const { origin } = new URL(window.location);

	useEffect(() => {
		if (isInitialChecking) return;

		if (document.referrer && !isLoggedIn) {
			const { origin: referrerOrigin } = new URL(document.referrer);

			if (referrerOrigin === process.env.REACT_APP_LOGIN_BASE_API_URL) {
				window.location.href = `${process.env.REACT_APP_LOGIN_BASE_API_URL}/need-access/${origin}/`;
			}
		}

		navigation('/');
	}, [isInitialChecking, isLoggedIn]);

	return null;
};

const Login = () => {
	const navigate = useNavigate();
	const { token } = useParams();

	useEffect(() => {
		(async () => {
			const authToken = await getLoginAuthToken(token);

			setAuthToken(authToken);
			navigate('/');
		})();
	}, [navigate, token]);

	return null;
};

const Logout = () => {
	const navigate = useNavigate();

	useEffect(() => {
		setAuthToken(null);
		navigate('/');
	}, [navigate]);

	return null;
};

const getLoginAuthToken = async (token) => {
	try {
		const { data } = await http.post(
			'/me/auth-token',
			{},
			{ headers: { Authorization: `Bearer ${token}` } }
		);

		return data.token;
	} catch (error) {
		return token;
	}
};
