import styled, { css } from 'styled-components';

import Container from 'components/ui/Container';
import CustomTable from 'components/ui/CustomTable';
import FaIcon from 'components/ui/Icons';

export const ContainerStyle = styled(Container)`
	margin-top: 1rem;

	@media (max-width: 768px) {
		margin-right: 20px;
		margin-left: 20px;
	}

	@media only screen and (min-width: 992px) {
		margin-top: 2rem;
	}
`;

export const HeaderIcon = styled(FaIcon)`
	font-size: ${(props) => (props.icon === 'external' ? '30px' : '40px')};

	@media (max-width: 768px) {
		font-weight: 500;
		color: #777777;
	}
`;

export const PinIcon = styled(FaIcon)`
	font-weight: 500;
	cursor: pointer;

	${({ active }) =>
		active &&
		css`
			&,
			&:hover {
				color: #ffc65c;
			}
		`}
`;

export const Table = styled(CustomTable)`
	width: 100%;

	th,
	td {
		width: 100px;
		padding: 15px 10px;
		font-size: 0.8rem;
	}

	thead th.jobsSelect,
	tbody td.jobsSelect {
		width: 20px;
		padding: 0 20px 0;

		input[type='checkbox'] {
			width: 16px;
			height: 16px;
			cursor: pointer;
		}
	}

	thead th.url,
	tbody td.url {
		width: 200px;
		word-break: break-all;
		text-align: start;
	}

	thead th.view,
	tbody td.view,
	thead th.reprint,
	tbody td.reprint {
		width: 100px;
	}

	thead th.status,
	tbody td.status {
		width: 120px;
	}

	thead th.reprint,
	tbody td.reprint {
		padding-right: 20px;
	}

	tbody td.appName span {
		display: none;
	}

	@media (max-width: 768px) {
		max-width: 600px;

		thead {
			display: none;
		}

		tbody tr:not(.nohover) {
			display: flex;
			flex-wrap: wrap;
			border: 3px solid lightgray;
			border-radius: 10px;
			margin: 10px 20px 15px;
			font-weight: 700;

			td {
				align-items: center;
				width: 50%;
				border-top: 3px solid lightgray;
				font-size: 18px;
				max-width: none;

				&.url,
				&.description {
					display: none;
				}

				&.jobsSelect {
					width: auto;
					height: 16px;
					margin: auto 0;
					border: 0;
				}

				&.view {
					order: 1;
				}

				&.appName {
					text-align: left;
					padding-left: 0;
					border: 0;
					color: #777777;

					span {
						display: block;
						color: #000;
						font-size: 16px;
						margin-top: 6px;
					}
				}

				&.reprint {
					order: 2;
					padding-right: 10px;
					border-left: 3px solid lightgray;
				}

				&.status {
					border-left: 3px solid lightgray;
					display: grid;
					place-content: center;
				}
			}

			&:hover td {
				background-color: transparent;
			}
		}
	}

	@media only screen and (min-width: 992px) {
		th,
		td {
			width: 250px;
			font-size: 1rem;
		}

		thead th.url,
		tbody td.url {
			width: 300px;
		}
	}

	@media only screen and (min-width: 1200px) {
		thead th:nth-child(2),
		tbody td:nth-child(2) {
			width: 350px;
		}
	}
`;

export const PrintButton = styled.button`
	padding: 5px 10px;
	border: 2px solid #e6e6e6;
	border-radius: 5px;
	background-color: #fff;
	font-weight: 600;
	cursor: pointer;

	&.multiplePrint:not(:disabled) {
		border-color: #3ea742;
		background-color: #3ea742;
		color: #fff;

		&:hover {
			background-color: #2d9631;
			border-color: #2d9631;
		}
	}

	&:hover {
		border-color: #777;
	}

	&:disabled {
		background-color: #f1f1f1;
		border-color: silver;
		cursor: not-allowed;
	}

	@media (max-width: 768px) {
		font-weight: 700;
	}
`;

export const ControlSettings = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
	padding: 0 20px;

	select {
		margin-left: 10px;
	}

	button {
		margin-left: 20px;
	}

	@media (max-width: 768px) {
		justify-content: space-between;
	}
`;

const statusColors = {
	pending: '#FFCD5E',
	archived: '#5D6367',
	failed: '#EE2842',
	done: '#409E43'
};

export const StatusBlock = styled.div`
	color: ${(props) => statusColors[props.status] || '#2d9631'};
	font-weight: bold;
	text-transform: capitalize;
`;
