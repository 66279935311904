import { Fragment, memo, useEffect, useState } from 'react';

import axios from 'axios';
import styled from 'styled-components';

import Header from 'components/Header';
import CustomContainer from 'components/ui/Container';

const fetchData = async () => {
	const { data } = await axios.get('/admin-panel/stats');

	return data;
};

const Stats = () => {
	const [lastLoad, setLastLoad] = useState(null);
	const [data, setData] = useState(null);

	useEffect(() => {
		const load = () => {
			fetchData().then(setData);
			setLastLoad(Math.floor(Date.now() / 1000));
		};

		load();
		const interval = setInterval(load, 10000);
		return () => clearInterval(interval);
	}, []);

	const instances = Object.keys(data?.connections?.instances ?? {});
	const os = Object.keys(data?.os ?? {});
	const versions = Object.keys(data?.versions ?? {});

	return (
		<Container>
			<Header text="Stats" />
			{data && (
				<Table>
					<tfoot>
						<tr className="info">
							<td colSpan="2">{lastLoad && <TimeDiff time={lastLoad} />}</td>
						</tr>
					</tfoot>
					<tbody>
						<tr>
							<th>Socket Connections</th>
							<td className="count">{data.connections.all}</td>
						</tr>
					</tbody>
					<tbody>
						<tr>
							<th colSpan={2}>Instances</th>
						</tr>
						{instances.length > 0 ? (
							instances.map((instance) => (
								<Fragment key={instance}>
									<tr key={instance}>
										<td>{data.connections.current === instance ? <i>{instance}</i> : instance}</td>
										<td className="count">{data.connections.instances[instance].connections}</td>
									</tr>

									{data.connections.instances[instance].taskId ? (
										<tr>
											<td colSpan={2}>{data.connections.instances[instance].taskId}</td>
										</tr>
									) : null}
								</Fragment>
							))
						) : (
							<tr>
								<td colSpan={2}>No data</td>
							</tr>
						)}
					</tbody>
					<tbody>
						<tr>
							<th colSpan={2}>Versions</th>
						</tr>
						{versions.length > 0 ? (
							versions.map((version) => (
								<tr key={version}>
									<td>{version}</td>
									<td className="count">{data.versions[version]}</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={2}>No data</td>
							</tr>
						)}
					</tbody>
					<tbody>
						<tr>
							<th colSpan={2}>Operating systems</th>
						</tr>
						{os.length > 0 ? (
							os.map((os) => (
								<tr key={os}>
									<td>{os}</td>
									<td className="count">{data.os[os]}</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={2}>No data</td>
							</tr>
						)}
					</tbody>
				</Table>
			)}
		</Container>
	);
};

const calcTimeDiff = (time) => Math.floor(Date.now() / 1000) - time;

const TimeDiff = memo(({ time }) => {
	const [diff, setDiff] = useState(calcTimeDiff(time));

	useEffect(() => {
		setDiff(calcTimeDiff(time));
		const interval = setInterval(() => setDiff(calcTimeDiff(time)), 1000);
		return () => clearInterval(interval);
	}, [time]);

	return (
		<>
			Updated {diff} second{diff > 1 && 's'} ago
		</>
	);
});

export default Stats;

const Container = styled(CustomContainer)`
	width: 500px;
	max-width: 100%;
`;

const Table = styled.table`
	width: calc(100% - 2rem);
	border-collapse: collapse;
	border: 1px solid #cbd5e0;
	margin: 1rem;

	tr {
		border-bottom: 1px solid #e2e8f0;

		&:last-child {
			border-bottom: none;
		}
	}

	tbody {
		tr:last-child {
			border-bottom: 1px solid #cbd5e0;
		}
	}

	td,
	th {
		border-right: 1px solid #e2e8f0;
		padding: 0.5rem;

		&:last-child {
			border-right: none;
		}
	}

	th {
		text-align: left;
	}

	.count,
	.info {
		text-align: right;
	}
`;
