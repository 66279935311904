import axios from 'axios';

const pinnedPrintersIsLoading = () => {
	return {
		type: 'FETCH_PINNED_PRINTERS_REQUEST'
	};
};

const pinnedPrintersRequested = (data) => {
	return {
		type: 'FETCH_PINNED_PRINTERS_SUCCESS',
		payload: data
	};
};

const pinnedPrintersError = (error) => {
	return {
		type: 'FETCH_PINNED_PRINTERS_ERROR',
		payload: error
	};
};

const fetchPinnedPrinters = () => async (dispatch) => {
	dispatch(pinnedPrintersIsLoading());

	try {
		const {
			data: { data }
		} = await axios.get('/printers/pinned');

		dispatch(pinnedPrintersRequested(data));
	} catch (error) {
		dispatch(pinnedPrintersError(error));
	}
};

export { fetchPinnedPrinters };
