import styled from 'styled-components';

const Icon = styled.i`
	${(props) => ({
		fontSize: props.size || '30px',
		color: props.color || '#777777',
		width: props.width || 'auto',
		height: props.height || 'auto'
	})}

	&:hover {
		color: ${(props) => props.hoverColor || props.color || '#585858'};
	}
`;

const getDefaultIconsProps = (icon) => {
	switch (icon) {
		case 'adminPanel':
			return { iconClass: 'fa-container-storage' };

		case 'angleLeft':
			return { iconClass: 'fa-angle-left' };

		case 'angleRight':
			return { iconClass: 'fa-angle-right' };

		case 'angleDown':
			return { iconClass: 'fa-angle-down' };

		case 'applicationPaid':
			return {
				isKit: true,
				iconClass: 'fa-light-window-maximize-circle-dollar'
			};

		case 'applications':
			return { iconClass: 'fa-window-restore' };

		case 'appsDashboard':
			return { iconClass: 'fa-cubes' };

		case 'activity':
			return { iconClass: 'fa-timeline' };

		case 'arrowFromSquare':
			return { iconClass: 'fa-arrow-up-right-from-square' };

		case 'arrowRightToBracket':
			return {
				color: '#55C792',
				hoverColor: '#75C792',
				iconClass: 'fa-arrow-right-to-bracket'
			};

		case 'backNavigation':
			return {
				color: '#777777',
				hoverColor: '#585858',
				iconClass: 'fa-caret-circle-left'
			};

		case 'bags':
			return {
				color: '#a0d3e3',
				iconClass: 'fa-bags-shopping'
			};

		case 'confirmCopy':
			return {
				color: '#3ea742',
				hoverColor: '#3ea742',
				iconClass: 'fa-check-circle active'
			};

		case 'copy':
			return {
				color: '#777777',
				hoverColor: '#585858',
				iconClass: 'fa-copy'
			};

		case 'create':
			return {
				color: '#3ea742',
				hoverColor: '#2d9631',
				iconClass: 'fa-plus-circle'
			};

		case 'check':
			return {
				color: '#3ea742',
				iconClass: 'fa-check'
			};

		case 'cross':
			return {
				color: '#ff623e',
				iconClass: 'fa-times'
			};

		case 'code':
			return {
				color: '#000000',
				iconClass: 'fa-code'
			};

		case 'circleCheck':
			return {
				iconClass: 'fa-circle-check'
			};

		case 'dashboard':
			return { iconClass: 'fa-draw-circle' };

		case 'delete':
			return {
				color: '#ff623e',
				hoverColor: '#ff380b',
				iconClass: 'fa-minus-circle'
			};

		case 'exclamation':
			return {
				color: '#ffffff',
				hoverColor: '#ffffff',
				iconClass: 'fa-exclamation-circle'
			};

		case 'exclamationTriangle':
			return {
				color: '#ffffff',
				hoverColor: '#ffffff',
				iconClass: 'fa-exclamation-triangle'
			};

		case 'external':
			return {
				color: '#c2c2c2',
				hoverColor: '#777777',
				iconClass: 'fa-external-link'
			};

		case 'hardware':
			return {
				color: '#a65ac9',
				iconClass: 'fa-hard-drive'
			};

		case 'home':
			return {
				iconClass: 'fa-home-lg-alt'
			};

		case 'info':
			return {
				color: '#a3b0c2',
				hoverColor: '#a3b0c2',
				iconClass: 'fa-info-circle'
			};

		case 'info-square':
			return { iconClass: 'fa-info-square' };

		case 'life-ring':
			return { iconClass: 'fa-life-ring' };

		case 'logout':
			return { iconClass: 'fa-sign-out fa-flip-horizontal' };

		case 'plans':
			return { iconClass: 'fa-ballot-check' };

		case 'printer':
			return {
				color: '#585858',
				iconClass: 'fa-print'
			};

		case 'printAnalytics':
			return {
				iconClass: 'fa-file-invoice'
			};

		case 'profile':
			return {
				color: '#88c8dc',
				hoverColor: '#42A6C7',
				iconClass: 'fa-user-circle'
			};

		case 'question':
			return {
				color: '#86cdab',
				iconClass: 'fa-question'
			};

		case 'resources':
			return {
				iconClass: 'fa-book-bookmark'
			};

		case 'rocket':
			return {
				color: '#ffd270',
				iconClass: 'fa-rocket-launch'
			};

		case 'settings':
			return {
				iconClass: 'fa-cog'
			};

		case 'stations':
			return { iconClass: 'fa-speakers' };

		case 'shapes':
			return {
				color: '#fe898b',
				iconClass: 'fa-shapes'
			};

		case 'stars':
			return { iconClass: 'fa-stars' };

		case 'trash':
			return {
				color: '#ff623e',
				hoverColor: '#ff380b',
				iconClass: 'fa-trash-alt'
			};

		case 'thumbtack':
			return {
				color: '#777777',
				hoverColor: '#777777',
				iconClass: 'fa-thumbtack'
			};

		case 'warning':
			return {
				color: '#ffcc00',
				hoverColor: '#ffcc00',
				iconClass: 'fa-exclamation-triangle'
			};

		case 'xmark':
			return {
				iconClass: 'fa-xmark'
			};

		case 'empty':
		default:
			return {
				iconClass: ''
			};
	}
};

const getIconTypeClass = (type) => {
	switch (type) {
		case 'regular':
			return 'far';

		case 'solid':
			return 'fas';

		case 'duotone':
			return 'fad';

		case 'light':
		default:
			return 'fal';
	}
};

const FaIcon = ({ icon = 'empty', type = 'light', className, ...props }) => {
	const { iconClass, isKit, ...defaultProps } = getDefaultIconsProps(icon);

	const iconType = isKit ? 'fa-kit' : getIconTypeClass(type);

	const classes = `${className} ${iconClass} ${iconType}`;

	return <Icon className={classes} {...defaultProps} {...props} aria-hidden="true" />;
};

export default FaIcon;
