import styled, { css } from 'styled-components';

export const Label = styled.label`
	display: inline-block;
	padding: 1rem 0 0.25rem;
	font-weight: 700;
	color: #5f6873;
	cursor: pointer;

	${({ required }) =>
		required &&
		css`
			&::after {
				content: ' *';
				color: #ff253a;
				font-weight: 500;
			}
		`};
`;

export const Input = styled.input`
	width: 100%;
	height: 40px;
	padding: 0.5rem;
	font-size: 16px;
	font-weight: 500;
	font-family: Roboto, Open Sans, Segoe UI, sans-serif;
	border: solid 2px #e2e8f0;
	border-radius: 8px;
	background-color: #edf2f7;
`;

const statusColors = {
	success: '#53c691',
	warning: '#ffc107',
	error: '#ff253a'
};

export const StatusMessage = styled.p`
	margin: 0.25rem 0;
	text-align: center;
	white-space: pre-line;
	color: ${({ status }) => statusColors[status] || '#ff253a'};
`;

const SpinnerOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	border-radius: 8px;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.1);
`;

const Spinner = styled.svg`
	width: 50px;
	height: 50px;
	animation: rotate 2s linear infinite;

	circle {
		stroke: rgb(58, 182, 127);
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;

export const LoadingSpinner = () => {
	return (
		<SpinnerOverlay>
			<Spinner viewBox="0 0 50 50">
				<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
			</Spinner>
		</SpinnerOverlay>
	);
};
