const updateStatus = (list, data) => {
	const { id, newStatus } = data;
	return list.map((item) => {
		if (item.id === id) {
			return {
				...item,
				id,
				block: newStatus
			};
		} else {
			return item;
		}
	});
};

const initialState = {
	list: [],
	totalRecords: null,
	loading: true,
	error: null
};

const getAdminPanelList = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_ADMIN_PANEL_REQUEST':
			return {
				...state
			};

		case 'FETCH_ADMIN_PANEL_SUCCESS':
			return {
				list: action.payload.list,
				totalRecords: action.payload.totalAll,
				loading: false,
				error: null
			};

		case 'FETCH_ADMIN_PANEL_ERROR':
			return {
				list: [],
				totalRecords: null,
				loading: false,
				error: action.payload
			};

		case 'FETCH_UPDATE_BLOCK_STATUS_REQUEST':
			return {
				...state
			};

		case 'FETCH_UPDATE_BLOCK_STATUS_SUCCESS':
			return {
				list: updateStatus(state.list, action.payload),
				totalRecords: state.totalRecords,
				loading: false,
				error: null
			};

		case 'FETCH_UPDATE_BLOCK_STATUS_ERROR':
			return {
				list: null,
				totalRecords: null,
				loading: false,
				error: action.payload
			};

		default:
			return state;
	}
};

export default getAdminPanelList;
