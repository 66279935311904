import { useEffect, useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { createSetupIntent, manageSubscription } from 'api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';

import useUserTasks from 'hooks/swr/useUserTasks';
import usePaymentMethods from 'hooks/usePaymentMethods';

import { currentPlan } from 'actions/current-plan';

import { ReactComponent as StripeBadge } from 'assets/stripe-badge.svg';

import OrderSummary from './OrderSummary';
import { ActivePaymentMethod, PaymentMethodsForm } from './PaymentMethods';
import PlanTermSwitcher from './PlanTermSwitcher';
import PromoCodeField from './PromoCodeField';
import { LoadingSpinner, StatusMessage } from './styles';

const getButtonText = (currPlanId, id, isFree) => {
	if (!currPlanId) return 'Subscribe';

	if (!id || currPlanId === id) return 'Update Subscription';

	return isFree ? 'Cancel' : 'Subscribe to Plan';
};

const defaultBillingDetails = { name: '', address: {} };

const validateBillingDetails = ({ name, address }) => {
	if (!name) return { message: 'Name on Invoice cannot be empty' };

	const fieldsList = [
		{ field: 'line1', label: 'Address 1' },
		{ field: 'city', label: 'City' },
		{ field: 'state', label: 'State' },
		{ field: 'country', label: 'Country' }
	];

	for (let { field, label } of fieldsList) {
		if (!address[field]) return { message: `${label} field cannot be emptry` };
	}

	return { message: null };
};

const CheckoutForm = ({ currPlan, plan, isHavePaymentMethod, formRef }) => {
	const { name: planName, perMonth, perMonthAnn, upTo, id, isFree } = plan;
	const { isBanned } = useUserStatus();

	const currPlanId = currPlan?.id || null;
	const currPlanTerm = currPlan?.term || null;
	const isCurrPlanFree = currPlan?.isFree;

	const stripe = useStripe();
	const elements = useElements();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const {
		cards,
		loading,
		error,
		setError,
		setPaymentMethod,
		updatePaymentMethod,
		deletePaymentMethod
	} = usePaymentMethods(id);

	const { mutate: updateUserTasksList } = useUserTasks();

	const [errorMessage, setErrorMessage] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [isShownCardsList, setIsShownCardsList] = useState(false);

	const [planTerm, setPlanTerm] = useState(currPlanTerm || 'annual');

	const [discount, setDiscount] = useState(null);
	const [billingDetails, setBillingDetails] = useState(defaultBillingDetails);

	const [setupIntentLoading, setSetupIntentLoading] = useState(false);

	const totalPrice = planTerm === 'monthly' ? perMonth : perMonthAnn * 12;

	const isDiffTerm = currPlanTerm !== planTerm;

	const showSubmitButton =
		!isHavePaymentMethod || (id && currPlanId !== id) || isDiffTerm || discount?.promoCodeId;

	useEffect(() => {
		if (!formRef.current) return;
		formRef.current.scrollIntoView();
	}, []);

	useEffect(() => {
		setIsShownCardsList(false);
		setError(null);
		setErrorMessage(null);
		setDiscount(null);
	}, [id, planTerm]);

	const resetBillingDetails = () => setBillingDetails(defaultBillingDetails);

	const handleShowCardsList = (e) => {
		e.preventDefault();
		setErrorMessage(null);
		setError(null);

		setIsShownCardsList((isShown) => !isShown);
	};

	const handleFormChanges = (e) => {
		setErrorMessage(null);

		setBillingDetails((prevState) => {
			if (e.target.name === 'name') return { ...prevState, name: e.target.value };

			return { ...prevState, address: { ...prevState.address, [e.target.name]: e.target.value } };
		});
	};

	const setupNewPayment = async () => {
		setErrorMessage(null);

		if (!stripe || !elements) return setErrorMessage('Try again later');

		if (!billingDetails.name) return setErrorMessage('Name on card field cannot be empty');

		setSetupIntentLoading(true);

		const { client_secret, error: setupError } = await createSetupIntent();

		if (setupError) {
			setSetupIntentLoading(false);
			return setErrorMessage(setupError);
		}

		const cardElement = elements.getElement(CardElement);

		const { error, setupIntent } = await stripe.confirmCardSetup(client_secret, {
			payment_method: {
				card: cardElement,
				billing_details: billingDetails
			}
		});

		setSetupIntentLoading(false);

		return { error, setupIntent };
	};

	const handleAttachNewCard = async (e) => {
		e.preventDefault();

		const { error, setupIntent } = await setupNewPayment();

		if (error) {
			return setErrorMessage(error.message || 'Something Wrong');
		}

		if (setupIntent.payment_method) {
			await updatePaymentMethod(setupIntent.payment_method);
		}
	};

	const handleSwitchCard = async (e, cardId) => {
		e.preventDefault();

		await updatePaymentMethod(cardId);
	};

	const handleUpdateCard = async (e) => {
		e.preventDefault();

		const { message } = validateBillingDetails(billingDetails);

		if (message) return setErrorMessage(message);

		const { error, setupIntent } = await setupNewPayment();

		if (error) {
			setErrorMessage(error.message || 'Something Wrong');
		}

		if (setupIntent.payment_method) {
			// e.target.id - is previous card.id that needs to be updated [replaced with]
			await updatePaymentMethod(setupIntent.payment_method, e.target.id);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setSubmitting(true);

		if (!stripe || !elements) return;

		if (!isHavePaymentMethod && !isFree) {
			setSubmitting(false);

			const { error, setupIntent } = await setupNewPayment();

			if (error) {
				setSubmitting(false);
				return setErrorMessage(error.message || 'Something Wrong');
			}

			if (setupIntent.payment_method) {
				await setPaymentMethod(setupIntent.payment_method);
			}
		}

		const promoCodeId = (discount && !discount.info && discount.promoCodeId) || null;

		const { subStatus, manageError = null } = await manageSubscription(id, planTerm, promoCodeId);

		if (manageError) {
			setErrorMessage(manageError);
			setSubmitting(false);
			return;
		}

		if (subStatus === 'active' || subStatus === 'trialing' || subStatus === 'updated') {
			await dispatch(currentPlan());
		}

		updateUserTasksList();

		setSubmitting(false);

		navigate('/');
	};

	if ((!id && !currPlanId) || (isCurrPlanFree && (!id || currPlanId === id))) return null;

	if (isShownCardsList)
		return (
			<Container>
				{loading && <PrimeSpinner />}

				{setupIntentLoading && <LoadingSpinner />}

				{!loading && (
					<PaymentMethodsForm
						cards={cards}
						billingDetails={billingDetails}
						errorMessage={errorMessage || error}
						setErrorMessage={setErrorMessage}
						onFormChange={handleFormChanges}
						onHandleShowPM={handleShowCardsList}
						onAttachNewCard={handleAttachNewCard}
						onSwitchCard={handleSwitchCard}
						onUpdateCard={handleUpdateCard}
						onDeleteCard={deletePaymentMethod}
						resetBillingDetails={resetBillingDetails}
					/>
				)}
			</Container>
		);

	return (
		<Container ref={formRef}>
			{loading && <PrimeSpinner message="Processing plan subscription" />}

			{!loading && (
				<Form onSubmit={handleSubmit}>
					{(submitting || setupIntentLoading) && <LoadingSpinner />}

					{((!isHavePaymentMethod && !isFree) || (currPlanId && !id)) && (
						<Title>
							{!isHavePaymentMethod && !isFree ? (
								'Enter your card details'
							) : (
								<span>Payment Methods</span>
							)}
						</Title>
					)}

					<Title>
						{!currPlanId && id
							? isFree
								? 'Your 50 Free Prints Trial Will Start Now'
								: 'Your subscription will start now'
							: null}

						{currPlanId && id
							? isFree
								? 'Cancel your subscription'
								: 'Your subscription will update'
							: null}
					</Title>

					{id && !isFree && (
						<>
							<PromoCodeField
								setDiscount={setDiscount}
								planId={id}
								planTerm={planTerm}
								currPlanId={currPlanId}
							/>

							<PlanTermSwitcher planTerm={planTerm} setPlanTerm={setPlanTerm} />
						</>
					)}

					{id && (
						<OrderSummary
							planName={planName}
							currPlanId={currPlanId}
							upTo={upTo}
							id={id}
							isFree={isFree}
							total={totalPrice}
							discount={discount}
						/>
					)}

					{currPlanId && isFree ? (
						<Description>
							Your current subscription will cancel effective at the end of the active subscription
							period
						</Description>
					) : null}

					{!isFree && !error && (
						<ActivePaymentMethod
							cards={cards}
							billingDetails={billingDetails}
							setErrorMessage={setErrorMessage}
							onShowCardsList={handleShowCardsList}
							onFormChange={handleFormChanges}
						/>
					)}

					{!isFree && error && <StatusMessage>{error}</StatusMessage>}

					{errorMessage && <StatusMessage>{errorMessage}</StatusMessage>}

					{showSubmitButton && (
						<Button type="submit" disabled={isBanned || submitting}>
							{getButtonText(currPlanId, id, isFree)}
						</Button>
					)}

					<a href="https://stripe.com/" target="_blank" rel="noreferrer" title="Powered by Stripe">
						<StripeBadge width="120px" alt="Powered by Stripe" title="" />
					</a>
				</Form>
			)}
		</Container>
	);
};

export default CheckoutForm;

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 470px;
	min-height: 200px;
	margin: 2rem auto 1rem;

	svg {
		margin: auto;
	}
`;

const Form = styled.form`
	position: relative;
	width: 100%;
	padding: 1rem;
	border: solid 2px #e2e8f0;
	background: white;

	svg {
		display: block;
		width: 120px;
		margin: 10px auto 0;

		:hover {
			background-color: #635bff10;
		}
	}

	@media only screen and (min-width: 450px) {
		border-radius: 10px;
	}
`;

const Button = styled.button`
	width: 100%;
	height: 40px;
	margin-top: 0.5rem;
	border: solid 2px;
	border-radius: 8px;
	background-color: #53c691;
	color: white;
	cursor: pointer;

	&:not(:disabled):hover {
		background-color: #43dd96;
	}

	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}
`;

const Title = styled.h1`
	margin-top: 0;
	font-size: 1.3rem;
	text-align: center;

	span {
		font-size: 2rem;
		color: #585858;
	}
`;

const Description = styled.p`
	color: #a3b0c2;
	font-size: 1rem;
`;
