import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ErrorIndicator from 'components/ErrorIndicator';
import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';
import FaIcon from 'components/ui/Icons';

import { useCurrentPlan } from 'hooks/useCurrentPlan';

import { fetchJobsCount } from 'actions/jobs-count';

import { ItemContainer, ItemHeader, ItemIcon, ItemLink } from './style';

const AnalyticsItem = () => {
	const { perDay, perMonth, loading, error } = useSelector(({ jobsCount }) => jobsCount);

	const { currentPlan, loading: planInfoLoading } = useCurrentPlan();

	const { isBanned } = useUserStatus();

	const isPastDue = currentPlan && !!currentPlan.pastDueInvoices?.length;

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchJobsCount());
	}, []);

	if (error) return <ErrorIndicator />;

	return (
		<ItemContainer>
			<ItemLink to="/print-analytics" aria-label="Go to the Print Analytics page">
				<ItemHeader>
					<ItemIcon icon="printAnalytics" />

					<h2>Print Analytics</h2>
				</ItemHeader>

				<div>
					{loading || planInfoLoading ? (
						<PrimeSpinner
							message="Loading analytics"
							justifyContent="flex-start"
							flexDirection="row"
						/>
					) : (
						<>
							<div>
								<b>{perDay}</b> jobs today
							</div>

							<div>
								<b>{perMonth}</b> jobs total for month
							</div>
						</>
					)}
				</div>
			</ItemLink>

			{isBanned && isPastDue && (
				<ServicePausedLink
					to="/print-analytics"
					aria-label="BizPrint Service Paused. Go to the Print Analytics page to reactivate your service"
				>
					<FaIcon icon="exclamationTriangle" type="solid" />
				</ServicePausedLink>
			)}
		</ItemContainer>
	);
};

const ServicePausedLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25%;
	max-width: 100px;
	border: 0;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: #ff5c5a;
`;

export { AnalyticsItem };
