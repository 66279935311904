import axios from 'axios';

import { createErrorAlert } from 'features/alertService';

const jobsCountIsLoading = () => {
	return {
		type: 'FETCH_JOBS_COUNT_REQUEST'
	};
};
const jobsCountRequested = (newPrintJobsCount) => {
	return {
		type: 'FETCH_JOBS_COUNT_SUCCESS',
		payload: newPrintJobsCount
	};
};
const jobsCountError = (error) => {
	return {
		type: 'FETCH_JOBS_COUNT_ERROR',
		payload: error
	};
};

const fetchJobsCount = (date) => async (dispatch) => {
	dispatch(jobsCountIsLoading());

	try {
		const {
			data: { perDay = 0, perMonth = 0, total = 0 }
		} = await axios.get('analytics/jobs', { params: { date } });

		dispatch(jobsCountRequested({ perDay, perMonth, total }));
	} catch (e) {
		if (e?.response?.data?.error) {
			dispatch(createErrorAlert({ message: e.response.data.error }));
		}
		dispatch(jobsCountError(e));
	}
};

export { fetchJobsCount };
