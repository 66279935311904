import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import FaIcon from 'components/ui/Icons';

import { fetchPinnedPrinters } from 'actions/pinned-printers';

import {
	ArrowBracketIcon,
	HelpBanner,
	PinHeader,
	PinIcon,
	PinRow,
	PrinterIcon,
	Seperator
} from './style';

const PinnedLogs = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		data: pinnedPrinters,
		loading,
		error
	} = useSelector(({ pinnedPrinters }) => pinnedPrinters);

	useEffect(() => {
		dispatch(fetchPinnedPrinters());
	}, []);

	return (
		<>
			<PinHeader className="nohover" hasPins={pinnedPrinters.length}>
				<td className="icon">
					<PinIcon icon="thumbtack" />
				</td>
				<td className="info">
					{!loading && !error && pinnedPrinters.length ? (
						<p>Pinned Printer Logs</p>
					) : (
						<>
							<p>
								No pinned print logs
								<HelpBanner
									href="https://app.monstercampaigns.com/c/v7fxfsugaz5zajw93lal/"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Show Pinned Print Logs Setup Guide"
								>
									<FaIcon icon="info-square" />
								</HelpBanner>
							</p>
							<span>
								Easily pin a print logs by selecting a station, click the printer and pin the log!
							</span>
						</>
					)}
				</td>
			</PinHeader>

			{!loading &&
				!error &&
				pinnedPrinters.map(({ id, printerName, stationName }) => (
					<PinRow className="nohover" key={id} onClick={() => navigate(`/jobs/${id}`)}>
						<td className="icon">
							<PrinterIcon icon="printer" />
						</td>
						<td className="info">
							<p>{printerName}</p>
							<span>{stationName} Station</span>
							<Link to={`/jobs/${id}`}>
								<ArrowBracketIcon icon="arrowRightToBracket" />
							</Link>
						</td>
					</PinRow>
				))}
			<Seperator />
		</>
	);
};

export default PinnedLogs;
