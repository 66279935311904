import { useEffect, useState } from 'react';

import { checkAppliedDiscount, verifyPromoCode } from 'api';
import styled from 'styled-components';

import FaIcon from 'components/ui/Icons';

import { Input, LoadingSpinner, StatusMessage } from './styles';

const PromoCodeField = ({ setDiscount, currPlanId, planId, planTerm }) => {
	const [loading, setLoading] = useState(false);
	const [promoCode, setPromoCode] = useState(null);
	const [verification, setVerification] = useState(null);

	const [isShownPromo, setIsShownPromo] = useState(false);

	const checkDiscount = async (planId, planTerm) => {
		setLoading(true);

		const { message, status, promo } = await checkAppliedDiscount(planId, planTerm);

		if (status) {
			setVerification({ status, message, info: true });
			return setLoading(false);
		}

		if (promo) {
			setDiscount({
				promoCodeId: null,
				code: promo.code || null,
				type: promo.coupon.percent_off ? 'percent' : 'fixed',
				value: Number(promo.coupon.percent_off || promo.coupon.amount_off || 0),
				duration: promo.coupon.duration_in_months,
				planId,
				info: true
			});

			setVerification({ status: 'success', message, info: true });
		}

		setLoading(false);
	};

	useEffect(() => {
		setVerification(null);
		checkDiscount(planId, planTerm);
	}, [planTerm]);

	useEffect(() => {
		setVerification(null);
	}, [planTerm]);

	const handlePromoCodeChange = (e) => {
		setPromoCode(e.target.value.trim());
		setVerification(null);
	};

	const showPromoBlock = (e) => {
		e.preventDefault();
		setIsShownPromo(true);
	};

	const applyPromoCode = async (e) => {
		e.preventDefault();

		setVerification(null);
		setLoading(true);

		const { message, status, promo, error } = await verifyPromoCode(promoCode, planId, planTerm);

		if (error || status === 'error') {
			setVerification({ status: 'error', message: error || message });
			return setLoading(false);
		}

		if (promo) {
			setDiscount({
				promoCodeId: promo.id,
				code: promo.code,
				type: promo.coupon.percent_off ? 'percent' : 'fixed',
				value: Number(promo.coupon.percent_off || promo.coupon.amount_off || 0),
				duration: promo.coupon.duration_in_months,
				planId
			});

			setVerification({ status: 'success', message });
		}
		setLoading(false);
	};

	return (
		<>
			{loading && <LoadingSpinner />}

			<PromoDisplayBlock>
				<b>
					<FaIcon icon="info" type="solid" /> Have a Promotion Code?
				</b>

				<div>
					<input type="checkbox" id="showPromo" value={isShownPromo} onChange={showPromoBlock} />
					<label htmlFor="showPromo">Click here to enter your code</label>
				</div>
			</PromoDisplayBlock>

			{isShownPromo && (
				<div>
					<Input
						name="promotionCode"
						value={promoCode || ''}
						onChange={handlePromoCodeChange}
						autoComplete="off"
					/>

					{verification && (
						<StatusMessage status={verification.status}>
							{currPlanId === planId && verification.info ? '' : verification.message}
						</StatusMessage>
					)}

					<Button type="button" disabled={!promoCode || loading} onClick={applyPromoCode}>
						{loading ? 'Verification' : 'Apply Promotion Code'}
					</Button>
				</div>
			)}
		</>
	);
};

export default PromoCodeField;

const PromoDisplayBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 1.5rem 0 5px;
	color: #585858;

	b {
		display: flex;
		align-items: center;
		font-weight: 800;

		i {
			margin-right: 5px;
			font-size: 1.3rem;
			color: #585858;

			:hover {
				color: #585858;
			}
		}
	}

	> div {
		position: relative;

		input[type='checkbox'] {
			position: absolute;
			opacity: 0;

			:focus + label {
				outline: #000000 auto 5px;
			}
		}

		label {
			border-bottom: 1px dotted lightgray;

			:hover {
				border-bottom-style: solid;
				color: #000000;
				cursor: pointer;
			}
		}
	}

	@media only screen and (max-width: 480px) {
		flex-direction: column;

		> div {
			margin-top: 5px;
		}
	}
`;

const Button = styled.button`
	width: 100%;
	height: 40px;
	margin: 0.5rem 0 0;
	border: solid 2px #e8e7e7;
	border-radius: 8px;
	background-color: #efefef;
	color: black;
	cursor: pointer;

	&:hover {
		background-color: #e9e9e9;
	}

	&:disabled,
	&:disabled:hover {
		color: #a0a5aa;
		background-color: #f7f7f7;
		border-color: #dddddd;
		cursor: not-allowed;
	}

	> div {
		display: flex;
		align-items: baseline;
		width: fit-content;
		margin: 0 auto;
	}
`;
