import assistant from './assistant';
import getAdminPanelList from 'reducers/admin-panel-list';
import currentPlan from 'reducers/current-plan';
import jobsAnalytics from 'reducers/jobs-analytics';
import jobsCount from 'reducers/jobs-count';
import getJobsList from 'reducers/jobs-list';
import pinnedPrinters from 'reducers/pinned-printers';
import subscriptionPlans from 'reducers/subscription-plans';
import getUserSettings from 'reducers/user-settings';

import alertService from 'features/alertService';
import application from 'features/applications/application';
import applicationsInfo from 'features/applications/applications-info';
import applicationsList from 'features/applications/applications-list';
import station from 'features/stations/station';
import stationsCount from 'features/stations/stations-count';
import stationsList from 'features/stations/stations-list';
import accountInfo from 'features/user/account-info';

const reducer = {
	assistant,
	accountInfo,
	application,
	applicationsInfo,
	applicationsList,
	alertService,
	station,
	stationsCount,
	stationsList,

	jobsCount,
	getJobsList,
	getAdminPanelList,
	subscriptionPlans,
	currentPlan,
	getUserSettings,
	jobsAnalytics,
	pinnedPrinters
	//refactor-end
};

export default reducer;
