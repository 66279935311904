import axios from 'axios';

export const createSetupIntent = async () => {
	try {
		const {
			data: { data: client_secret }
		} = await axios.post('/create-setup-intent');

		return { client_secret };
	} catch (e) {
		return { error: 'An error occurred while adding new payment method.\nTry again later' };
	}
};
