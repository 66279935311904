import styled, { css } from 'styled-components';

const MenuIcon = styled.div`
	position: relative;
	width: 30px;
	height: 30px;

	&::before,
	&::after {
		content: '';
	}

	& span,
	&::before,
	&::after {
		position: absolute;
		width: 30px;
		height: 4px;
		background-color: ${({ active }) => (active && '#585858') || '#777777'};
		transition: all 0.5s ease;
	}

	&::after {
		bottom: 0;
	}

	span {
		top: 13px;
	}

	${(props) =>
		props.active &&
		css`
			&::after,
			&::before {
				transform: scale(0);
			}

			span:nth-child(1) {
				transform: rotate(45deg);
			}

			span:nth-child(2) {
				transform: rotate(-45deg);
			}
		`};
`;

const BurgerMenu = (props) => (
	<MenuIcon tabIndex="0" {...props}>
		<span></span>
		<span></span>
	</MenuIcon>
);

export default BurgerMenu;
