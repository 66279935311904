import styled, { css } from 'styled-components';

import FaIcon from 'components/ui/Icons';

export const HelpBanner = styled.a`
	i {
		font-size: 25px;
		cursor: pointer;
	}
`;

export const PinIcon = styled(FaIcon)`
	font-weight: 500;
	font-size: 40px;
	padding: 25px 0;
`;

export const PrinterIcon = styled(FaIcon)`
	font-weight: 700;
`;

export const ArrowBracketIcon = styled(FaIcon)`
	font-weight: 500;
`;

const pinElementMixin = css`
	&.nohover .icon {
		text-align: center;
		max-width: 100px;
	}

	.info {
		font-size: 18px;
		color: gray;
		padding: 10px 10px 10px 0;
		width: auto;

		p {
			display: flex;
			align-items: center;
			column-gap: 10px;
			margin: 0 0 10px;
		}
	}
`;

export const PinRow = styled.tr`
	position: relative;
	cursor: pointer;

	${pinElementMixin}

	.info {
		a {
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
		}

		p,
		span {
			display: block;
			width: calc(100% - 80px);
		}
	}
`;

export const PinHeader = styled.tr`
	${pinElementMixin}

	${({ hasPins }) =>
		hasPins &&
		css`
			background-color: #f8f8f8;

			${PinIcon} {
				color: #ffc65c;
				font-size: 25px;
				padding: 5px 0;
			}

			.info p {
				margin: 0;
			}
		`}
`;

export const Seperator = styled.tr`
	border-bottom: 3px solid #ffc65c;
`;
