import Title from 'components/Title';

import { HeaderIcon, HeaderStyle } from './style';

const Header = ({ text, icon, type, ...props }) => (
	<HeaderStyle>
		{icon && <HeaderIcon icon={icon} type={type} />}
		<Title text={text} {...props} />
	</HeaderStyle>
);

export default Header;
