import styled from 'styled-components';

const CardDetails = ({ card }) => {
	if (!card) return null;

	const [iconClass, iconTitle] = getCardIcon(card.brand);

	return (
		<CardInfo>
			<span aria-label={`The last four digits of the card are ${card.last4}`}>
				{card.last4} •••• •••• ••••
			</span>

			<i className={iconClass} title={iconTitle} aria-label={iconTitle} />
		</CardInfo>
	);
};

export default CardDetails;

const getCardIcon = (cardBrand) => {
	const cardBrands = {
		amex: ['fab fa-cc-amex', 'American Express card'],
		diners: ['fab fa-cc-diners-club', 'Diners Club card'],
		discover: ['fab fa-cc-discover', 'Discover card'],
		jcb: ['fab fa-cc-jcb', 'JCB card'],
		mastercard: ['fab fa-cc-mastercard', 'MasterCard card'],
		visa: ['fab fa-cc-visa', 'Visa card']
	};

	return cardBrands[cardBrand] || ['fal fa-credit-card', 'Credit or debit card'];
};

const CardInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-right: 10px;
	padding: 7px 10px;
	border: solid 2px #e2e8f0;
	border-radius: 5px;
	overflow: hidden;
	cursor: default;

	span {
		display: inline-block;
		direction: rtl;
		white-space: nowrap;
		overflow: hidden;
	}

	i {
		margin-left: 10px;
		font-size: 1.4rem;
	}
`;
