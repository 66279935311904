import styled, { css } from 'styled-components';

import ErrorIndicator from 'components/ErrorIndicator';
import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';

import { useCurrentPlan } from 'hooks/useCurrentPlan';

import { ItemContainer, ItemIcon, ItemLink } from './style';

const PaymentItem = () => {
	const { currentPlan, loading, error } = useCurrentPlan();

	const { isBanned } = useUserStatus();

	if (error) return <ErrorIndicator />;

	const { name, isLegacy, pastDueInvoices, upTo, included, perExcess, isFree, cancelDate } =
		currentPlan;

	const isPastDue = pastDueInvoices && pastDueInvoices.length;

	return (
		<ItemContainer planname={name}>
			<ItemLink to="/subscription-plans" aria-label="Go to the subscription plans page">
				<ItemHeader>
					<ItemIcon icon="plans" size="34px" />

					<div>
						<h2>My Plan</h2>

						<span>
							<b>{name}</b>

							{cancelDate && <span>{`- Plan set to cancel ${cancelDate}`}</span>}
						</span>
					</div>
				</ItemHeader>

				<ItemInfo isBannerShown={!isBanned && isPastDue}>
					{!isBanned && isPastDue ? (
						<>
							<ItemIcon icon="exclamation" />
							Account Payment Overdue
						</>
					) : loading ? (
						<PrimeSpinner
							message="Loading plan details"
							justifyContent="flex-start"
							flexDirection="row"
						/>
					) : (
						<>
							{isLegacy && `${upTo} Print Jobs per month`}
							{!isLegacy && `${isFree ? upTo : included} Prints included`}
							<br />

							<ServiceInfo>
								{isFree
									? `Account suspends at ${upTo} print limit`
									: `${perExcess / 100} per usage over`}
							</ServiceInfo>
						</>
					)}
				</ItemInfo>
			</ItemLink>
		</ItemContainer>
	);
};

export const ItemHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 1.5rem 0;

	& > div {
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		min-height: 45px;
		font-size: 1.1rem;

		h2 {
			font-size: 1.1rem;
			font-weight: normal;
			margin: 0;
		}

		span {
			display: flex;
			align-items: flex-start;

			> span {
				align-self: center;
				margin: 0;
				padding-left: 5px;
				font-size: 0.8rem;
				line-height: 1.1rem;
			}
		}
	}
`;

export const ServiceInfo = styled.div`
	font-size: 0.8rem;
	margin-top: 5px;
`;

const ItemInfo = styled.div`
	padding: 0 1.5rem 1rem;

	${({ isBannerShown }) =>
		isBannerShown &&
		css`
			display: flex;
			align-items: center;
			width: 100%;
			height: 50px;
			padding-bottom: 0;
			border-radius: 0 0 20px 20px;
			font-size: 1.2rem;
			text-align: center;
			vertical-align: middle;
			background-color: #ff5c5a;
			color: #ffffff;
		`}
`;

export { PaymentItem };
