import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useJobsCount } from 'hooks/useJobsCount';

import { NoticeBox } from './style';

const PrintStatusBanner = ({ currentPlan }) => {
	const { perMonth, total, loading, error } = useJobsCount();

	if (currentPlan === null || !currentPlan.upTo) return null;

	if (loading || error) return null;

	if (currentPlan.isFree && total >= Number(currentPlan.upTo)) {
		return (
			<PrintPausedBox>
				<b>Trial Ended:</b> {currentPlan.upTo} print limit has been reached for the trial period
				<br />
				<Link to="subscription-plans">Upgrade your plan</Link>
			</PrintPausedBox>
		);
	}

	if (!currentPlan.isFree && perMonth >= Number(currentPlan.upTo)) {
		return (
			<PrintPausedBox>
				<b>PRINT SERVICE PAUSED:</b> Print plan transactions limit has been reached for the month
				<br />
				<Link to="subscription-plans">Upgrade your plan</Link>
			</PrintPausedBox>
		);
	}

	return null;
};

export default PrintStatusBanner;

const PrintPausedBox = styled(NoticeBox)`
	background-color: red;
`;
