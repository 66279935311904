import styled from 'styled-components';

import FaIcon from 'components/ui/Icons';

export const CardIcon = styled(FaIcon)``;

export const Container = styled.div`
	display: grid;
	grid-template-columns: minmax(auto, 400px) minmax(auto, 400px);
	grid-gap: 30px;
	place-items: center;
	align-items: stretch;
	margin: auto 20px;

	@media (max-width: 768px) {
		grid-template-columns: minmax(auto, 400px);
	}
`;

export const CardLink = styled.a`
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 2px solid #efefef;
	border-radius: 15px;
	padding: 15px 20px;
	text-decoration: none;
	color: #000000;
	background-color: #ffffff;

	&:hover {
		box-shadow: 0 0 4px 0 rgb(45, 150, 49);
	}

	> ${CardIcon} {
		align-self: end;
		font-size: 25px;
	}
`;

export const CardTitle = styled.div`
	display: flex;
	align-items: center;
	column-gap: 20px;
`;

export const CardInfo = styled.span`
	margin-top: 10px;
`;
