import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Container from 'components/ui/Container';
import CustomTable from 'components/ui/CustomTable';
import PageHeader from 'components/ui/PageHeader';

const bannedMixin = css`
	position: relative;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		opacity: 0.5;
		cursor: not-allowed;
		top: 0;
		left: 0;
	}
`;

export const ActivitiesItemContainer = styled(Container)`
	margin-top: 0;
	border-radius: 30px 30px 0 0;
	border-bottom: none;
	width: 100%;
	max-width: 840px;
	margin: 0 15px;

	${(props) => props.isBanned && bannedMixin}

	table {
		width: 100%;
	}

	h1 {
		margin: 10px 0 5px;
	}
`;

export const ActivitiesContainer = styled(Container)`
	width: 90%;

	@media (min-width: 990px) {
		width: 100%;
		max-width: 960px;
	}
`;

export const ActivitiesHeader = styled(PageHeader)`
	justify-content: center;
`;

export const EmptyText = styled.td``;

export const ActivitiesTable = styled(CustomTable)`
	width: 100%;
	border-top: 2px solid lightgray;

	tbody tr.nohover {
		td {
			text-align: left;
		}

		${EmptyText} {
			text-align: center;
			font-size: 17px;
			padding: 10px;
		}
	}
`;

export const ActivitiesRow = styled.tr`
	color: black;

	&:nth-child(even) {
		background-color: #f8f8f8;
	}

	td {
		max-width: none;
		width: 100%;
		text-align: left;

		&.icon {
			padding: 25px 30px;
			width: auto;
		}

		&.info {
			padding-right: 10px;
		}

		p {
			margin: 0 0 10px;
		}

		span {
			color: gray;
			font-size: 15px;

			@media (max-width: 640px) {
				font-size: 14px;
			}
		}

		a {
			text-decoration: none;
			color: blue;
		}
	}
`;

export const ActivitiesLink = styled(NavLink)`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #1d3354;
	padding: 15px 0;
	text-decoration: none;
	color: white;
	font-size: 20px;
	border-radius: 0 0 30px 30px;
	max-width: 840px;
	margin: 0 15px;

	&:hover {
		background-color: #293f61;
	}

	${(props) => props.as === 'div' && bannedMixin}

	i {
		color: white;
		margin-left: 15px;

		&:hover {
			color: white;
		}

		@media (max-width: 640px) {
			font-size: 22px;
		}
	}

	@media (max-width: 640px) {
		font-size: 18px;
	}
`;
