import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { createErrorAlert } from 'features/alertService';

const initialState = {
	account: null,
	loading: true,
	error: null
};

const accountInfoSlice = createSlice({
	name: 'account-info',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		handleData: (state, action) => {
			state.loading = false;
			state.error = null;
			state.account = action.payload;
		},
		handleError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.account = null;
		}
	}
});

export default accountInfoSlice.reducer;

export const fetchUserAccountInfo = () => {
	return async (dispatch) => {
		dispatch(accountInfoSlice.actions.loading());

		try {
			const { data } = await axios.get(`user/account-info`);

			dispatch(accountInfoSlice.actions.handleData(data));
		} catch (e) {
			if (e?.response?.data?.error) {
				dispatch(createErrorAlert({ message: e.response.data.error }));
			}
			dispatch(accountInfoSlice.actions.handleError(e));
		}
	};
};
