import { Features } from './style';

const IncludedFeatures = ({ planName }) => {
	const includedFeatures = {
		Plus: ['Remove BizSwoop Branding'],
		Pro: ['Remove BizSwoop Branding', 'Print Mapping Add-ons', 'Send as WhatsApp, Email, SMS'],
		Growth: [
			'Remove BizSwoop Branding',
			'Print Mapping Add-ons',
			'Send as WhatsApp, Email, SMS',
			'Dedicated Account Manager',
			'Marketplace Credits ($500)'
		]
	};

	if (includedFeatures[planName])
		return (
			<Features planName={planName}>
				<h3>Includes:</h3>

				<ul>
					{includedFeatures[planName].map((feature, id) => (
						<li key={id}>
							<b>✓</b> {feature}
						</li>
					))}
				</ul>
			</Features>
		);

	return null;
};

export default IncludedFeatures;
