import { useState } from 'react';

import styled from 'styled-components';

import { Modal } from 'components/Modal';
import FaIcon from 'components/ui/Icons';

const HELP_GUIDE_URL =
	'https://www.loom.com/embed/015f0f272959499f902c638432c31ad9?sid=602332cc-d92f-4985-a1fe-af45af369355';

export const WebhookInfoModal = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	const closeModal = () => {
		setLoading(false);
		setIsModalOpen(false);
	};

	const openModal = () => {
		setLoading(true);
		setIsModalOpen(true);
	};

	return (
		<>
			<Modal onClose={closeModal} isOpen={isModalOpen}>
				<ModalContent>
					<IframeContainer isLoading={loading}>
						<iframe allowFullScreen src={HELP_GUIDE_URL} onLoad={() => setLoading(false)}></iframe>
					</IframeContainer>

					<Description>Help! Where do I get my Webhook URL to enter?</Description>
				</ModalContent>
			</Modal>

			<HelpBannerTrigger onClick={openModal} aria-label="Show Webhooks URL Setup Guide">
				<FaIcon icon="info-square" size="22px" />
			</HelpBannerTrigger>
		</>
	);
};

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const IframeContainer = styled.div`
	position: relative;
	width: 100%;
	aspect-ratio: 16 / 9;
	background-color: rgba(255, 255, 255, 0.9);

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	::before {
		content: '';
		display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #ccc;
		background-image: linear-gradient(135deg, #ddd 40%, #eee 45%, #fff 50%, #eee 55%, #ddd 60%);
		background-size: 200% 100%;
		animation: shine 1.5s linear infinite;
	}

	@keyframes shine {
		to {
			background-position-x: -200%;
		}
	}
`;

const Description = styled.p`
	margin: 0;
	padding: 0;
	font-size: 1.2rem;
	text-align: center;
	color: #ffffff;
`;

const HelpBannerTrigger = styled.a`
	margin: 0 auto 0 10px;
	text-decoration: none;
	color: #bdc3c7;
`;
