import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DashboardItem from 'components/ui/DashBoardItem';
import FaIcon from 'components/ui/Icons';

export const ItemLink = styled(Link)`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 0 1rem 1.5rem;
	text-decoration: none;
	color: black;
`;

export const CreateLink = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	max-width: 100px;
	border: 0;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	font-size: 3rem;
	color: #fff;
	background-color: #2d9631;
	text-decoration: none;

	&:hover {
		background-color: #3ea742;
		cursor: pointer;
	}
`;

//planname property special not camel case because NavLink trigger error
const getBackgroundColor = (planname) => {
	switch (planname) {
		case 'Trial':
			return '#8bc9dc';
		case 'Basics':
			return '#a0befa';
		case 'Plus':
			return '#b49efa';
		case 'Pro':
			return '#ffac33';
		case 'Growth':
			return '#ff5d5b';
		default:
			return '#ffffff';
	}
};

export const ItemContainer = styled(DashboardItem)`
	flex-flow: row;
	padding: 0;
	background-color: ${({ planname }) => getBackgroundColor(planname)};

	&:nth-child(2) a {
		padding: 0;
	}
`;

export const ItemHeader = styled.div`
	display: flex;
	align-items: center;

	h2 {
		font-size: 1.1rem;
		font-weight: normal;
		margin: 0;
	}
`;

export const ItemIcon = styled(FaIcon)`
	font-size: 34px;
	margin-right: 1rem;
`;
