import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchUserAccountInfo } from 'features/user/account-info';

export const useAccountInfo = () => {
	const { account, loading, error } = useSelector((state) => state.accountInfo);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchUserAccountInfo());
	}, []);

	return { account, loading, error };
};
