import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { NoticeBox } from './style';

const DevModeEnabledBanner = () => (
	<DevModeBox>
		<p>Development Mode Active</p>
		Turn off Development Mode in <Link to="settings">Settings</Link>
	</DevModeBox>
);

export default DevModeEnabledBanner;

const DevModeBox = styled(NoticeBox)`
	margin-top: 60px;
	background-color: #f2900f;

	& p {
		font-size: 1.4rem;
	}

	@media only screen and (min-width: 992px) {
		margin-top: 0;
	}
`;
