import { useState } from 'react';

import axios from 'axios';
import styled from 'styled-components';

import { useAccountInfo } from 'hooks/useAccountInfo';

import { NoticeBox } from './style';

const VerifyAccountBanner = () => {
	const { account, loading, error } = useAccountInfo();

	const [isSending, setIsSending] = useState(false);

	if (loading || error) return null;

	const resendEmail = async (e) => {
		e.preventDefault();

		try {
			setIsSending(true);

			await axios.post('/user/resend-activation-email');
		} catch (error) {}

		setIsSending(false);
	};

	if (account.isVerified === false) {
		return (
			<PrintPausedBox>
				Please confirm your email address ({account.email}) in the next 14 days.
				<div>
					<button
						type="button"
						onClick={resendEmail}
						aria-label="Resend verification code to email"
						disabled={isSending}
					>
						Resend email
					</button>

					<span role="separator"></span>

					<a
						href="https://bizswoop.atlassian.net/servicedesk/customer/portal/4/group/4/create/10048"
						target="_blank"
						rel="noreferrer"
						aria-label="Raise a help request to BizSwoop Support Team"
					>
						Didn't receive the emails?
					</a>
				</div>
			</PrintPausedBox>
		);
	}

	return null;
};

export default VerifyAccountBanner;

const PrintPausedBox = styled(NoticeBox)`
	padding: 15px;
	font-size: 0.9rem;
	background-color: #e1ecf8;
	color: #5d5c5c;

	div {
		display: inline-block;

		span {
			border-right: 1px solid grey;
			margin: 0 5px;
		}
	}

	button,
	a {
		all: unset;
		display: inline-block;
		margin: 0 2px;
		padding: 2px 5px;
		color: #1936b0;
		background-color: #b6d2ee;
		border: 1px solid #8eafce;
		border-radius: 4px;
		cursor: pointer;

		:hover,
		:focus {
			color: #000000;
		}

		:focus {
			outline: 1px solid #000000;
		}
	}

	button {
		:disabled {
			color: grey;
			border-color: grey;
		}
	}

	@media only screen and (min-width: 800px) {
		button,
		a {
			background-color: transparent;
			border: none;
		}
	}

	@media only screen and (min-width: 900px) {
		font-size: 1rem;
	}
`;
