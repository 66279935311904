import { useEffect, useRef, useState } from 'react';

import styled, { css } from 'styled-components';

const AuthIframe = ({ service, origin, children }) => {
	const [isShown, setIsShown] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const iframe = useRef(null);

	const readMessage = (event) => {
		if (event.origin !== process.env.REACT_APP_LOGIN_BASE_API_URL) return;

		const { data } = event;

		switch (data?.type) {
			case 'CHECK_USER_LOGGED_IN':
				setIsShown(data.isLoggedIn);
				break;

			case 'CLOSE_IFRAME':
				setIsShown(false);
				break;

			case 'SET_TOKEN':
				window.localStorage.setItem('authToken', data.authToken);
				window.location.reload();
				break;

			default:
				break;
		}
	};

	const onIframeLoad = () => setIsLoaded(true);

	useEffect(() => {
		const authToken = localStorage.getItem('authToken');

		if (authToken) setIsShown(false);

		window.addEventListener('message', readMessage, false);

		return () => window.removeEventListener('message', readMessage, false);
	}, []);

	return (
		<>
			<Iframe
				src={`${process.env.REACT_APP_LOGIN_BASE_API_URL}/frame-service-login?service=${service}&origin=${origin}`}
				title="SSO Authentication Iframe"
				onLoad={onIframeLoad}
				tabIndex={1}
				isShown={isShown}
				ref={iframe}
				width={320}
				height={150}
			/>

			<IframeChildren isHidden={isLoaded && isShown}>{children}</IframeChildren>
		</>
	);
};

export default AuthIframe;

const Iframe = styled.iframe`
	position: fixed;
	right: 0;
	bottom: -500px;
	width: 100%;
	margin: 0;
	border: none;
	opacity: 0;
	box-shadow: 0 0 4px 1px #757575;
	transition: all 1s ease-in-out;
	z-index: 100;

	${({ isShown }) =>
		isShown &&
		css`
			bottom: 0;
			opacity: 1;
		`}

	@media only screen and (min-width: 450px) {
		max-width: 320px;
		top: 20px;
		right: -500px;
		border-radius: 15px;

		${({ isShown }) =>
			isShown &&
			css`
				right: 20px;
			`}
	}
`;

const IframeChildren = styled.div`
	visibility: visible;

	@media only screen and (min-width: 450px) {
		${({ isHidden }) =>
			isHidden &&
			css`
				visibility: hidden;
			`}
	}
`;
