import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const ErrorLabel = styled.i.attrs({
	className: 'fas fa-exclamation-triangle'
})`
	color: red;

	${(props) =>
		props.paddingLeft &&
		css`
			padding-left: 0.5rem;
		`}

	${(props) =>
		props.paddingRight &&
		css`
			padding-right: 0.5rem;
		`}
`;

ErrorLabel.propTypes = {
	paddingLeft: PropTypes.bool,
	paddingRight: PropTypes.bool
};

export default ErrorLabel;
