import React from 'react';
import { FooterBlock, FooterNav, FooterLink, LogoBlock, Img, Paragraph } from './style';

const Footer = () => {
	return (
		<FooterBlock>
			<LogoBlock>
				<FooterLink href="https://www.bizswoop.com">
					<Img
						src="https://www.bizswoop.com/wp-content/uploads/2019/05/Logox96.png"
						alt="Responsive image"
					/>
				</FooterLink>

				<b>BizSwoop</b>
			</LogoBlock>

			<FooterNav>
				<ul>
					<li>
						<FooterLink href="https://www.bizswoop.com/privacy/">Privacy Policy</FooterLink>
					</li>

					<li>
						<FooterLink href="https://www.bizswoop.com/terms/">Terms</FooterLink>
					</li>
				</ul>
			</FooterNav>

			<Paragraph>
				All product names, logos, and brands are property of their respective owners.
			</Paragraph>
			<Paragraph>
				BizSwoop a CPF Concepts, LLC Brand – All Rights Reserved. Copyright @{' '}
				{new Date().getFullYear()}
			</Paragraph>
		</FooterBlock>
	);
};

export default Footer;
