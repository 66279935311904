import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ErrorIndicator from 'components/ErrorIndicator';
import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';

import { fetchStationsCount } from 'features/stations/stations-count';

import { CreateLink, ItemContainer, ItemHeader, ItemIcon, ItemLink } from './style';

const StationsItem = () => {
	const { countStations, countPrinters, loading, error } = useSelector(
		({ stationsCount }) => stationsCount
	);

	const { isBanned } = useUserStatus();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchStationsCount());
	}, []);

	if (error) return <ErrorIndicator />;

	return (
		<ItemContainer isBanned={isBanned}>
			<ItemLink as={isBanned && 'div'} to="/stations" aria-label="Go to the list of stations">
				<ItemHeader>
					<ItemIcon icon="stations" />

					<h2>Stations</h2>
				</ItemHeader>

				{loading ? (
					<PrimeSpinner
						message="Loading stations"
						justifyContent="flex-start"
						flexDirection="row"
					/>
				) : (
					<div>
						<div>
							<strong>{countStations}</strong> stations listed
						</div>

						<div>
							<strong>{countPrinters}</strong> printers connected
						</div>
					</div>
				)}
			</ItemLink>

			<CreateLink as={isBanned && 'div'} to="/stations/create" aria-label="Create a new station">
				+
			</CreateLink>
		</ItemContainer>
	);
};

export { StationsItem };
