import React, { useEffect, useMemo, useState } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import Header from 'components/Header';
import Pagination from 'components/Pagination';
import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';
import PageHeader from 'components/ui/PageHeader';

import { fetchJobsList, updateJobsListPinStatus } from 'actions/jobs-list';

import {
	ContainerStyle,
	ControlSettings,
	HeaderIcon,
	PinIcon,
	PrintButton,
	StatusBlock,
	Table
} from './style';

const Jobs = () => {
	const dispatch = useDispatch();
	const stationId = useLocation()?.state?.stationId || '';
	const { isBanned } = useUserStatus();
	let { printId } = useParams();
	const isMobile = useMediaQuery('(max-width: 645px)');

	const {
		list,
		totalRecords,
		isPinned,
		loading: jobsListLoading
	} = useSelector(({ getJobsList }) => getJobsList);

	const [currentPage, setCurrentPage] = useState(1);
	const [jobsFilter, setJobsFilter] = useState('all');
	const [jobsSelected, setJobsSelected] = useState([]);
	const [jobsList, setJobsList] = useState(list);

	const pageLimit = 10;
	const totalPages = useMemo(() => {
		return Math.ceil(totalRecords / pageLimit);
	}, [totalRecords, pageLimit]);

	useEffect(() => {
		dispatch(fetchJobsList(printId, currentPage, pageLimit, jobsFilter));
		setJobsSelected([]);
	}, [currentPage, jobsFilter]);

	useEffect(() => {
		setJobsList(list);
	}, [list]);

	const handlePageChange = (page) => setCurrentPage(page);

	const handleJobsFilter = (e) => {
		setJobsFilter(e.target.value);
		setCurrentPage(1);
	};

	const handleJobSelection = (event) => {
		if (event.target.checked) {
			setJobsSelected((jobs) => [...jobs, Number(event.target.dataset['jobId'])]);
		} else {
			setJobsSelected((jobs) =>
				jobs.filter((jobId) => jobId !== Number(event.target.dataset['jobId']))
			);
		}
	};

	const handleAllJobs = () =>
		setJobsSelected((jobs) => {
			if (jobs.length === list.length) return [];
			return list.map(({ id }) => id);
		});

	const reprintJob = (jobId) => {
		setJobsList((list) => changeJobStatus(list, jobId, 'sending...'));

		axios
			.post(`/jobs/${jobId}/reprint`)
			.then(() => {
				setJobsList((list) => changeJobStatus(list, jobId, 'reprinting...'));

				setJobsSelected((list) => list.filter((id) => id !== jobId));
			})
			.catch(() => {
				setJobsList((list) => changeJobStatus(list, jobId, 'failed'));
			});
	};

	const handlePinPrinter = () => {
		if (!jobsListLoading) {
			dispatch(updateJobsListPinStatus(printId, isPinned));
		}
	};

	if (jobsListLoading) return <PrimeSpinner message="Loading print jobs" />;

	return (
		<>
			<ContainerStyle>
				<PageHeader>
					<Link to={`/stations/${stationId}`} aria-label="Back to the list of printers">
						<HeaderIcon icon="backNavigation" type="regular" />
					</Link>

					<Header text="Print Jobs" icon="printer" type="solid" />

					{isBanned ? (
						<HeaderIcon />
					) : (
						<PinIcon icon="thumbtack" onClick={handlePinPrinter} active={isPinned} />
					)}
				</PageHeader>

				<ControlSettings>
					<label htmlFor="jobsFilter">
						Display jobs:
						<select id="jobsFilter" onChange={handleJobsFilter}>
							<option value="all">All</option>
							<option value="done">Done</option>
							<option value="failed">Failed</option>
							<option value="archived">Archived</option>
						</select>
					</label>

					<PrintButton
						disabled={!jobsSelected.length}
						className="multiplePrint"
						onClick={() => jobsSelected.forEach(reprintJob)}
					>
						Print Selected
					</PrintButton>
				</ControlSettings>

				<Table aria-label="My printers">
					<thead>
						<tr>
							<th scope="col" className="jobsSelect" title="Select/Deselect all jobs">
								<input
									type="checkbox"
									onChange={handleAllJobs}
									checked={list.length && jobsSelected.length === list.length}
								/>
							</th>

							<th scope="col" className="url">
								URL
							</th>

							<th scope="col" className="view">
								View
							</th>

							<th scope="col" className="appName">
								Application
							</th>

							<th scope="col" className="description">
								Order
							</th>

							<th scope="col" className="createdAt">
								Time Stamp
							</th>

							<th scope="col" className="status">
								Status
							</th>

							<th scope="col" className="reprint">
								Action
							</th>
						</tr>
					</thead>

					<tbody>
						<RenderJobsList
							list={jobsList}
							onJobSelect={handleJobSelection}
							checkedJobs={jobsSelected}
							reprintJob={reprintJob}
						/>
					</tbody>
				</Table>
			</ContainerStyle>

			<Pagination
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPages={totalPages}
				pageNeighbours={isMobile ? 0 : 3}
			/>
		</>
	);
};

const formatDate = (date) =>
	new Date(date)
		.toLocaleString({
			hour12: false,
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit'
		})
		.split(',')
		.reverse()
		.join(' ');

const changeJobStatus = (list, jobId, status) =>
	list.map((job) => (job.id === jobId ? { ...job, status } : job));

const RenderJobsList = ({ list, onJobSelect, checkedJobs, reprintJob }) => {
	if (!list.length) {
		return (
			<tr className="nohover">
				<td colSpan="8">There are no printed jobs yet</td>
			</tr>
		);
	}

	return list.map(({ id, url, description, applicationName, createdAt, status }) => (
		<tr key={id}>
			<td className="jobsSelect">
				<input
					type="checkbox"
					data-job-id={id}
					onChange={onJobSelect}
					checked={checkedJobs.includes(id)}
					title="Select/Deselect A Job"
				/>
			</td>

			<td className="url">{url}</td>

			<td className="view">
				<a href={url} target="_blank" rel="noreferrer" title="Open an order">
					<HeaderIcon icon="external" />
				</a>
			</td>

			<td className="appName">
				<span>{description}</span>
				{applicationName}
			</td>

			<td className="description">{description}</td>

			<td className="createdAt">{formatDate(createdAt)}</td>

			<td className="status">
				<StatusBlock status={status}>{status}</StatusBlock>
			</td>

			<td className="reprint">
				<PrintButton onClick={() => reprintJob(id)}>Print</PrintButton>
			</td>
		</tr>
	));
};

export default Jobs;
