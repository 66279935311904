import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@media only screen and (min-width: 992px) {
		margin-top: 1rem;
	}
`;

export const ItemsContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	max-width: 900px;
	margin: auto;

	@media only screen and (min-width: 992px) {
		margin-top: auto;
		margin-bottom: auto;
	}
`;
