import { fetchUserSettings, updateUserSettings } from 'actions/user-settings';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useUserSettings = () => {
	const { settings, loading, error } = useSelector(({ getUserSettings }) => getUserSettings);

	const dispatch = useDispatch();

	const updateSettings = (settings) => dispatch(updateUserSettings(settings));

	useEffect(() => {
		dispatch(fetchUserSettings());
	}, []);

	return { settings, loading, error, updateSettings };
};
