import { Link } from 'react-router-dom';

import { useAccountInfo } from 'hooks/useAccountInfo';

import { ProfileData, ProfileMenuBlock, ProfileMenuItems } from './style';

const ProfileMenu = () => {
	const { account } = useAccountInfo();

	const { fullName, email } = account || {};

	return (
		<ProfileMenuBlock>
			<ProfileData>
				<b>{fullName || email || 'FullName not specified'}</b>

				<span>{email || 'Email not specified'}</span>
			</ProfileData>

			<ProfileMenuItems>
				<ul>
					<li>
						<a
							target="_blank"
							rel="noreferrer"
							href={`${process.env.REACT_APP_LOGIN_BASE_API_URL}/user-setting`}
						>
							Account settings
						</a>
					</li>

					<li>
						<a target="_blank" rel="noreferrer" href={process.env.REACT_APP_LOGIN_BASE_API_URL}>
							Apps dashboard
						</a>
					</li>

					<li role="separator"></li>

					<li>
						<Link to="/subscription-plans">My Plan</Link>
					</li>

					<li>
						<Link to="/logout">Log out</Link>
					</li>
				</ul>
			</ProfileMenuItems>
		</ProfileMenuBlock>
	);
};

export default ProfileMenu;
