import { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import FaIcon from './Icons';

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	height: auto;
	margin: 0;
	padding: 0;
	border: none;
	border-radius: 8px;
	color: black;
	background-color: transparent;
	cursor: pointer;

	${(props) =>
		props.type === 'submit' &&
		css`
			background-color: #3ea742;
			color: #fff;

			&:not(:disabled):is(:hover, :focus) {
				background-color: #2d9631;
			}

			&:disabled {
				opacity: 0.4;
			}
		`}

	${(props) =>
		props.copy &&
		css`
			outline: none;
			padding: 7px;
			border-radius: 5px;

			&:focus {
				box-shadow: ${(props) => (!props.active && '0 0 0 1.5px #e6e6e6') || 'none'};
			}
		`}
`;

const ButtonIcons = styled(FaIcon)`
	font-size: 30px;
`;

export const Button = ({ children = '', value = '', type = 'button', ...props }) => (
	<StyledButton type={type} {...props}>
		{children || value}
	</StyledButton>
);

export const CopyButton = ({ copy = '', ...props }) => {
	const [active, setActive] = useState(false);

	const copyToClipboard = () => {
		setActive(true);
		setTimeout(() => setActive(false), 1000);
		navigator.clipboard.writeText(copy);
	};

	return (
		<Button {...props} onClick={copyToClipboard} active={active} copy>
			<FaIcon icon={active ? 'confirmCopy' : 'copy'} />
		</Button>
	);
};

export const DeleteButton = (props) => {
	const [isMobile, setIsMobile] = useState(false);
	const [hovered, setHovered] = useState(false);

	useEffect(() => {
		const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

		const mobile = Boolean(
			userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
		);

		setIsMobile(mobile);
	}, []);

	const handleHover = (e) => {
		e.preventDefault();
		setHovered((prev) => !prev);
	};

	return (
		<Button {...props}>
			<ButtonIcons
				icon={hovered && !isMobile ? 'trash' : 'delete'}
				type="regular"
				onMouseEnter={handleHover}
				onMouseLeave={handleHover}
			/>
		</Button>
	);
};
