import React from 'react';

import styled, { css } from 'styled-components';

import AuthIframe from 'components/AuthIframe';
import Footer from 'components/Footer';

import anywhere from 'assets/features/anywhere.png';
import instant from 'assets/features/instant.png';
import noLimits from 'assets/features/noLimits.png';
import bg from 'assets/login-page-bg.png';
import logoMd from 'assets/logo-md.png';
import logoSm from 'assets/logo-sm.png';
import logo from 'assets/logo.png';
import brotherLogo from 'assets/printers-logo/brother.png';
import canonLogo from 'assets/printers-logo/canon.png';
import dymoLogo from 'assets/printers-logo/dymo.png';
import epsonLogo from 'assets/printers-logo/epson.png';
import hpLogo from 'assets/printers-logo/hp.png';
import starLogo from 'assets/printers-logo/star.png';

const Landing = () => {
	const { origin } = new URL(window.location);

	return (
		<Layout>
			<Wrapper>
				<Container>
					<AuthIframe service="print" origin={origin}>
						<Nav>
							<ul>
								<li>
									<Link
										type="secondary"
										href={`${process.env.REACT_APP_LOGIN_BASE_API_URL}/need-access/${origin}?product=print`}
									>
										Sign In
									</Link>
								</li>

								<li>
									<Link
										type="primary"
										target="_blank"
										aria-label="Sign up and try BizPrint for free"
										href={`${process.env.REACT_APP_LOGIN_BASE_API_URL}/need-register?product=print`}
									>
										Get 25 Prints Free
									</Link>
								</li>
							</ul>
						</Nav>
					</AuthIframe>

					<Header>
						<img
							src={logo}
							srcSet={`${logoSm}, ${logoMd} 2x, ${logo} 3x`}
							width="175"
							height="175"
							alt="Logo"
						/>

						<Heading>BizPrint Cloud Print Platform</Heading>

						<Description>
							Automatically print orders anywhere in your Restaurant,
							<br /> Retail Store or Fulfillment Station Instantly.
						</Description>

						<LinksGroup>
							<Link
								type="primary"
								target="_blank"
								aria-label="Sign up and try BizPrint for free"
								href={`${process.env.REACT_APP_LOGIN_BASE_API_URL}/need-register?product=print`}
							>
								Get 25 Prints Free
							</Link>

							<Link
								type="outline"
								target="_blank"
								aria-label="Visit BizPrint site to get more information"
								href="https://getbizprint.com/"
							>
								Learn More
							</Link>
						</LinksGroup>
					</Header>
				</Container>

				<PrintersBlock>
					<Info>The printer service that is simple to setup and easy to use</Info>

					<p>Works on all major brands of printers and many more, including:</p>

					<PrintersItem>
						<img src={hpLogo} width="55" height="50" alt="Hp" loading="lazy" />

						<img src={dymoLogo} width="123" height="50" alt="Dymo" loading="lazy" />

						<img src={canonLogo} width="123" height="50" alt="Canon" loading="lazy" />

						<img src={epsonLogo} width="107" height="50" alt="Epson" loading="lazy" />

						<img src={brotherLogo} width="123" height="50" alt="Brother" loading="lazy" />

						<img src={starLogo} width="84" height="50" alt="Star" loading="lazy" />
					</PrintersItem>
				</PrintersBlock>
			</Wrapper>

			<Wrapper>
				<Container>
					<FeaturesBlock>
						<FeaturesItem>
							<img src={noLimits} width="654" height="535" alt="" loading="lazy" />
							<Heading as="h2">No Limits</Heading>
							<p>Connect all your websites, apps and print to multiple printers, no limitations</p>
						</FeaturesItem>

						<FeaturesItem>
							<img src={anywhere} width="654" height="535" alt="" loading="lazy" />
							<Heading as="h2">Print Anywhere</Heading>
							<p>Using the cloud you can setup printers to different locations and stations</p>
						</FeaturesItem>

						<FeaturesItem>
							<img src={instant} width="654" height="535" alt="" loading="lazy" />
							<Heading as="h2">Instantly Print</Heading>
							<p>
								Order receipts, invoices, packing slips print automatically when customers place
								order online
							</p>
						</FeaturesItem>
					</FeaturesBlock>
				</Container>
			</Wrapper>

			<Wrapper>
				<Card>
					<Heading as="h2">Start Printing Today for Free</Heading>

					<span>No credit card needed</span>

					<Link
						type="primary"
						target="_blank"
						aria-label="Sign up and try BizPrint for free"
						href={`${process.env.REACT_APP_LOGIN_BASE_API_URL}/need-register?product=print`}
					>
						Get 25 Prints Free
					</Link>
				</Card>
			</Wrapper>

			<Footer />
		</Layout>
	);
};
export default Landing;

const Heading = styled.h1`
	margin: 0;
	text-align: center;
	font-size: 1.8rem;
	color: #585858;

	@media only screen and (min-width: 1200px) {
		font-size: 2.2rem;
	}

	@media only screen and (min-width: 1600px) {
		font-size: 2.5rem;
	}
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-bottom: 20px;
	padding: 40px 20px;
	background-color: rgb(248, 249, 249);

	${Heading} {
		margin: 0;
		font-size: 1.6rem;
	}

	span {
		margin: 20px 0 10px;
	}

	@media only screen and (min-width: 768px) {
		margin: 20px 0 30px;
	}

	@media only screen and (min-width: 992px) {
		margin: 30px 0 60px;

		${Heading} {
			font-size: 1.8rem;
		}
	}

	@media only screen and (min-width: 1200px) {
		${Heading} {
			font-size: 2rem;
		}

		span {
			font-size: 1.2rem;
		}
	}
`;

const Info = styled.p`
	margin: 0;
	padding: 0;
	text-align: center;
	font-size: 1.1rem;

	@media only screen and (min-width: 425px) {
		font-size: 1.2rem;
	}

	@media only screen and (min-width: 992px) {
		font-size: 1.4rem;
	}
`;

const FeaturesItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 290px;
	margin: 10px 10px 20px;

	img {
		width: 75px;
		height: auto;
	}

	${Heading} {
		margin: 10px 0;
		font-size: 1.4rem;
	}

	p {
		margin: auto 0;
		font-weight: normal;
		line-height: 1.6rem;
		text-align: center;
		color: #777777;
	}

	@media only screen and (min-width: 425px) {
		img {
			width: 80px;
		}
	}

	@media only screen and (min-width: 768px) {
		margin-bottom: 30px;

		${Heading} {
			font-size: 1.6rem;
		}

		img {
			width: 90px;
		}
	}
`;

const FeaturesBlock = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 20px 0;

	@media only screen and (min-width: 768px) {
		margin-top: 40px;
	}
`;

const PrintersItem = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	margin: 20px 0 0;

	img {
		margin: 0 20px 0;
	}
`;

const PrintersBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 15px 20px;
	background-size: 100% 20%;
	background: rgb(239, 235, 250) url(${bg}) repeat-x 0 0;

	${Info} {
		margin-bottom: 30px;

		& + p {
			margin: 0;
			padding: 0;
			font-size: 1rem;
			text-align: center;
		}
	}

	@media only screen and (min-width: 425px) {
		font-size: 1.2rem;
	}

	@media only screen and (min-width: 768px) {
		padding: 50px 90px 20px;
		background-size: contain;
	}

	@media only screen and (min-width: 992px) {
		padding-bottom: 40px;
	}
`;

const getLinkStyles = (linkType) => {
	switch (linkType) {
		case 'secondary':
			return {
				color: '#585858',
				bg: '#ffffff',
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
				colorHover: '#000000',
				bgHover: '#ffffff'
			};
		case 'outline':
			return {
				color: '#8bc9dc',
				bg: '#ffffff',
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
				colorHover: '#ffffff',
				bgHover: '#8bc9dc',
				border: '1px solid #8bc9dc'
			};
		case 'primary':
		default:
			return {
				color: '#ffffff',
				bg: '#3ea742',
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
				colorHover: '#ffffff',
				bgHover: '#2d9631'
			};
	}
};

const Link = styled.a`
	display: inline-block;
	padding: 10px 20px;
	border-radius: 3px;
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	user-select: none;

	${(props) => {
		const styles = getLinkStyles(props.type);
		return css`
			color: ${styles.color};
			background-color: ${styles.bg};
			box-shadow: ${styles.boxShadow};
			border: ${styles.border || 'none'};

			&:hover {
				color: ${styles.colorHover};
				background-color: ${styles.bgHover};
			}
		`;
	}};

	@media only screen and (min-width: 768px) {
		padding: 10px 20px;
		font-size: 1.1rem;
		font-weight: 600;
	}

	@media only screen and (min-width: 992px) {
		${(props) =>
			props.type === 'secondary' &&
			css`
				background-color: transparent;
				box-shadow: none;
			`}
	}

	@media only screen and (min-width: 1200px) {
		font-size: 1.2rem;
	}

	@media only screen and (min-width: 1600px) {
		font-size: 1.4rem;
	}
`;

const LinksGroup = styled.div`
	display: flex;
	flex-direction: column;

	${Link} {
		margin: 10px;
	}

	@media only screen and (min-width: 425px) {
		flex-direction: row;
	}
`;

const Description = styled.p`
	margin: 0;
	padding: 0;
	font-size: 1.2rem;
	font-weight: normal;
	line-height: 1.6rem;
	text-align: center;
	color: #787878;

	br {
		display: none;
	}

	@media only screen and (min-width: 550px) {
		br {
			display: block;
		}
	}
`;

const Header = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${Description} {
		margin: 10px 0;
		padding: 0 2px;
	}

	img {
		width: 150px;
		height: 150px;
		margin-top: 20px;
	}

	@media only screen and (min-width: 425px) {
		margin-top: 0;
	}

	@media only screen and (min-width: 768px) {
		img {
			width: 175px;
			height: 175px;
			margin-top: 0;
		}
	}

	@media only screen and (min-width: 1200px) {
		img {
			width: 200px;
			height: 200px;
			margin-top: 0;
		}

		${Description} {
			margin: 20px 0;
			font-size: 1.6rem;
			line-height: 2.1rem;
		}
	}

	@media only screen and (min-width: 1600px) {
		${Description} {
			margin: 20px 0;
			font-size: 1.6rem;
			line-height: 2.1rem;
		}
	}
`;

const Nav = styled.nav`
	margin-top: 10px;
	width: 100%;

	ul,
	li {
		margin: 0;
		padding: 0;
	}

	ul {
		display: flex;
		justify-content: space-around;
		list-style: none;
	}

	@media only screen and (min-width: 425px) {
		padding-right: 10px;

		ul {
			justify-content: flex-end;

			li:not(:first-child) {
				margin-left: 20px;
			}
		}
	}
`;

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin: 0 auto;

	@media only screen and (min-width: 425px) {
		width: 95%;
	}

	@media only screen and (min-width: 768px) {
		width: 90%;
	}

	@media only screen and (min-width: 1200px) {
		width: 85%;
	}

	@media only screen and (min-width: 1600px) {
		width: 80%;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Layout = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	color: #585858;

	${Wrapper}:first-child {
		min-height: 100vh;
		flex-grow: 1;
	}
`;
