import axios from 'axios';

import { getAuthToken } from 'components/Authorization';

const checkUserBan = new Event('check-user-ban');

axios.defaults.baseURL = '/api/connect-ui';

axios.interceptors.request.use(function (config) {
	const token = getAuthToken();
	config.headers.Authorization = `Bearer ${token}`;

	return config;
});

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		const { status } = error.response;

		if (status === 402) window.dispatchEvent(checkUserBan);

		return Promise.reject(error);
	}
);
