import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import FaIcon from 'components/ui/Icons';

import Container from 'pages/Electron/components/Container';
import Header from 'pages/Electron/components/Header';
import Heading from 'pages/Electron/components/Heading';

import { createStation } from 'features/stations/stations-list';

import { CreateButton, Form, FormControl } from './style';

const CreateStation = () => {
	const [name, setName] = useState('');
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const from = useLocation()?.state?.from || '/';

	const onFormChange = (e) => setName(e.target.value?.trimStart());

	async function createNewStation(e) {
		e.preventDefault();

		await dispatch(createStation({ name: name?.trim() }));

		navigate('/stations');
	}

	return (
		<>
			<Container>
				<Header>
					<Link to={from} aria-label="Back to the list of stations">
						<FaIcon icon="backNavigation" type="regular" size="40px" />
					</Link>

					<Heading>Create Station</Heading>

					<FaIcon height="40px" width="40px" />
				</Header>

				<Form onSubmit={createNewStation}>
					<FormControl>
						<label htmlFor="name">Name:</label>

						<input
							id="name"
							name="name"
							value={name}
							placeholder="Enter your station name"
							onChange={onFormChange}
							required
						/>
					</FormControl>

					<CreateButton type="submit">Create</CreateButton>
				</Form>
			</Container>
		</>
	);
};

export default CreateStation;
