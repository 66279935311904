import { useEffect } from 'react';

import styled, { css } from 'styled-components';

import FaIcon from 'components/ui/Icons';

// eslint-disable-next-line import/no-relative-parent-imports
import { StatusMessage } from '../styles';
import CardDetails from './CardDetails';
import { NewCardForm } from './CardForms';
import { ButtonsGroup, CardButton, CardControls, CardItem } from './styles';

const CARDS_ON_FILE_LIMIT = 2;

const PaymentMethodsList = ({
	cards,
	billingDetails,
	errorMessage,
	setErrorMessage,
	onFormChange,
	handleShowPM,
	handleShowEditForm,
	onAttachNewCard,
	onSwitchCard,
	onDeleteCard,
	resetBillingDetails
}) => {
	useEffect(() => {
		resetBillingDetails();
	}, []);

	const deleteCard = (e, { funding, id }) => {
		e.preventDefault();

		if (window.confirm(`Are you sure you want to permanently delete this ${funding} card?`)) {
			onDeleteCard(id);
		}
	};

	return (
		<>
			{cards.map((card, id) => (
				<CardItem key={card.id}>
					<CardTypeInfo>
						{card.funding} card
						{card.isDefaultPM && <DefaultBadge>Default</DefaultBadge>}
					</CardTypeInfo>

					<CardControls>
						<CardDetails card={card} />

						{id === 0 ? (
							<CardButton onClick={(e) => handleShowEditForm(e, card)}>Edit</CardButton>
						) : (
							<>
								<DeleteCardButton
									onClick={(e) => deleteCard(e, card)}
									title={`Delete ${card.funding} card`}
									disabled={card.services.length}
								>
									<FaIcon icon="delete" type="regular" />
								</DeleteCardButton>

								<CardButton onClick={(e) => onSwitchCard(e, card.id)}>Use linked card</CardButton>
							</>
						)}
					</CardControls>

					{card.services.length ? (
						<ServicesAppliedInfo>
							Active for {card.services.join(', ')} Service{card.services.length > 1 && 's'}
						</ServicesAppliedInfo>
					) : null}
				</CardItem>
			))}

			{cards.length < CARDS_ON_FILE_LIMIT && (
				<>
					<CardTypeInfo>
						<b>Add new credit or debit card</b>
					</CardTypeInfo>

					<NewCardControls>
						<NewCardForm
							billingDetails={billingDetails}
							setErrorMessage={setErrorMessage}
							onFormChange={onFormChange}
						/>

						<CreateButton hideOn="small-screen" onClick={onAttachNewCard}>
							Use new card
						</CreateButton>
					</NewCardControls>
				</>
			)}

			{errorMessage && <StatusMessage>{errorMessage}</StatusMessage>}

			<ButtonsGroup>
				<CancelButton onClick={handleShowPM}>Cancel</CancelButton>

				{cards.length < CARDS_ON_FILE_LIMIT && (
					<CreateButton hideOn="middle-screen" onClick={onAttachNewCard}>
						Use new card
					</CreateButton>
				)}
			</ButtonsGroup>
		</>
	);
};

export default PaymentMethodsList;

const CardTypeInfo = styled.p`
	margin: 1rem 0 4px;

	&::first-letter {
		text-transform: capitalize;
	}
`;

const ServicesAppliedInfo = styled.p`
	margin: 0.25rem 0 4px;
	font-size: 0.9rem;
	color: #53c691;
`;

const DefaultBadge = styled.span.attrs(() => ({
	title: 'The card is set as default payment method'
}))`
	margin-left: 4px;
	padding: 0 6px;
	font-size: 0.75rem;
	font-weight: 600;
	color: #004085;
	border: 1px solid #b8daff;
	border-radius: 4px;
	background-color: #cce5ff;
	cursor: help;
`;

const DeleteCardButton = styled.button`
	width: 30px;
	padding: 0;
	margin-right: 10px;
	border: none;
	background: none;
	cursor: pointer;

	&:hover i {
		font-weight: bold;
	}

	&:disabled,
	&:disabled:hover {
		i {
			color: grey;
		}
	}
`;

const NewCardControls = styled(CardControls)`
	margin-bottom: 10px;

	& > div {
		width: 100%;
	}

	@media only screen and (min-width: 500px) {
		& > div {
			margin-right: 10px;
		}
	}
`;

const CancelButton = styled(CardButton)`
	padding: 8px 20px;
`;

const getButtonDisplayProps = (hideOn) => {
	const displayValues = {
		'small-screen': { defaultVal: 'none', midScreen: 'inline-block' },
		'middle-screen': { defaultVal: 'inline-block', midScreen: 'none' }
	};

	return displayValues[hideOn] || {};
};

const CreateButton = styled(CardButton)`
	height: 40px;
	align-self: flex-end;
	margin-left: auto;
	padding: 0 20px;

	${({ hideOn }) => {
		if (hideOn) {
			const { defaultVal, midScreen } = getButtonDisplayProps(hideOn);

			return css`
				display: ${defaultVal};

				@media only screen and (min-width: 500px) {
					display: ${midScreen};
				}
			`;
		}
	}}
`;
