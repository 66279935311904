import { useState } from 'react';

import styled from 'styled-components';

import { Modal } from 'components/Modal';
import FaIcon from 'components/ui/Icons';

const TROUBLESHOOTING_GUIDE_URL =
	'https://www.loom.com/embed/015f0f272959499f902c638432c31ad9?sid=6d57b731-5f19-4d54-bf82-96847b4f97ae';

const SUPPORT_CONTACT_URL =
	'https://bizswoop.atlassian.net/servicedesk/customer/portal/4/group/4/create/10039';

export const WebhookHelpModal = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	const closeModal = () => {
		setLoading(false);
		setIsModalOpen(false);
	};

	const openModal = () => {
		setLoading(true);
		setIsModalOpen(true);
	};

	return (
		<>
			<Modal onClose={closeModal} isOpen={isModalOpen}>
				<ModalContent>
					<IframeContainer isLoading={loading}>
						<iframe
							allowFullScreen
							src={TROUBLESHOOTING_GUIDE_URL}
							onLoad={() => setLoading(false)}
						></iframe>
					</IframeContainer>

					<Description>
						<span>Oh No! &#x1F614;</span>

						<span>Let's review how to get the correct Webhooks URL</span>
					</Description>

					<SupportLink href={SUPPORT_CONTACT_URL} target="_blank" rel="noopener noreferrer">
						<FaIcon icon="life-ring" type="solid" size="1rem" /> Need more help? Contact us now
					</SupportLink>
				</ModalContent>
			</Modal>

			<HelpBannerTrigger onClick={openModal}>
				Help? Troubleshoot and Fix Connection
			</HelpBannerTrigger>
		</>
	);
};

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const IframeContainer = styled.div`
	position: relative;
	width: 100%;
	aspect-ratio: 16 / 9;
	background-color: rgba(255, 255, 255, 0.9);

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	::before {
		content: '';
		display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #ccc;
		background-image: linear-gradient(135deg, #ddd 40%, #eee 45%, #fff 50%, #eee 55%, #ddd 60%);
		background-size: 200% 100%;
		animation: shine 1.5s linear infinite;
	}

	@keyframes shine {
		to {
			background-position-x: -200%;
		}
	}
`;

const Description = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: clamp(5px, 3vw, 20px);
	width: 80%;
	margin: auto;
	padding: 0 0 10px;
	border-bottom: 1px solid #ccc;
	font-size: 1.2rem;
	text-align: center;
	color: #ffffff;
`;

const SupportLink = styled.a`
	text-decoration: none;
	padding: 6px;
	border-radius: 4px;
	background-color: #57c993;
	color: #000000;
	text-align: center;

	i {
		color: #000000;
	}

	:is(:hover, :focus) {
		background-color: #4db684;

		i {
			color: #000000;
		}
	}
`;

const HelpBannerTrigger = styled.a`
	display: block;
	width: fit-content;
	margin: 10px auto 0;
	padding: 5px 20px;
	color: #2042cb;
	border: 1px solid #2042cb;
	border-radius: 4px;
	text-decoration: none;
	transition: all 200ms ease-in-out;
	cursor: pointer;

	:hover,
	:focus {
		background-color: #2042cb;
		color: #ffffff;
	}
`;
