import styled from 'styled-components';

import leftBottom from './left-bottom.png';
import leftTop from './left-top.png';
import rightBottom from './right-bottom.png';
import rightTop from './right-top.png';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 100vw;
	width: 100%;
	min-height: 100vh;
	height: 100%;
`;

export const BasicBackground = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	min-width: 100vw;
	padding-top: ${(props) => (props.withNav ? '60px' : 0)};
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: left top, right top, left bottom, right bottom;
	background-image: url(${leftTop}), url(${rightTop}), url(${leftBottom}), url(${rightBottom});
	background-size: 50%;

	@media only screen and (min-width: 452px) {
		background-size: 30%;
	}

	@media only screen and (min-width: 992px) {
		margin-top: 0;
		padding: ${(props) => (props.withNav ? '0 25px 0 100px' : 0)};
		background-size: auto;
	}
`;
