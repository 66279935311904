import styled, { css } from 'styled-components';

export default styled.div`
	position: relative;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 400px;
	min-height: 125px;
	padding: 1rem 1.5rem;
	margin: 1rem;
	border-radius: 20px;
	font-size: 1rem;
	background-color: #fff;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);

	&:hover {
		box-shadow: 0 0 4px 0 rgb(45, 150, 49);
		cursor: pointer;
	}

	${({ isBanned }) =>
		isBanned &&
		css`
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 20px;
				background-color: #ffffff;
				opacity: 0.5;
				cursor: not-allowed;
			}
		`}
`;
