import styled from 'styled-components';

import { closeAuthWindow } from 'pages/Electron/ipc';

export const TrafficLights = () => {
	const closeWindow = async (e) => {
		e.preventDefault();

		await closeAuthWindow();
	};

	return (
		<NavPanel>
			<ButtonsGroup>
				<CloseButton onClick={closeWindow} />

				<TrafficLightButton />

				<TrafficLightButton />
			</ButtonsGroup>
		</NavPanel>
	);
};

const NavPanel = styled.nav`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	width: max(100%, 100vw);
	height: 26px;
	padding-left: 10px;
	background-color: rgb(36, 36, 38);
	z-index: 9999;
`;

const TrafficLightButton = styled.button.attrs(() => ({ tabIndex: -1 }))`
	position: relative;
	outline: none;
	height: 12px;
	width: 12px;
	padding: 0;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 100%;
	background-color: rgb(99, 99, 102);

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
`;

const CloseButton = styled(TrafficLightButton)`
	background-color: rgb(255, 105, 97);
	cursor: pointer;

	&:before,
	&:after {
		width: 8px;
		height: 1px;
		margin: auto;
		border-radius: 1px;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`;

const ButtonsGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	&:hover,
	&:focus {
		${CloseButton} {
			&:before,
			&:after {
				background-color: #4d0000;
			}
		}
	}
`;
