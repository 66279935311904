import styled from 'styled-components';

import FaIcon from 'components/ui/Icons';

export const HeaderStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const HeaderIcon = styled(FaIcon)`
	margin-right: 15px;
	color: #777777;
	&:hover {
		color: #777777;
	}
`;
