import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg) ;
  }
`;

const LoaderDiv = styled.div`
	position: relative;

	${(props) =>
		props.config.screen &&
		css`
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		`}

	${(props) =>
		props.config.full &&
		css`
			width: 100%;
			height: 100%;
		`}
	${(props) =>
		props.config.loading &&
		css`
			&:before {
				animation: ${rotate} 1s linear infinite;
				display: block;
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				border: 2px solid #ccc;
				border-right-color: transparent;
				z-index: 2;
			}
			&:after {
				display: block;
				content: '';
				cursor: progress;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 1;
				background: rgba(255, 255, 255, 0.6);
			}
		`}
`;

const appear = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
`;

const LoaderDone = styled.div.attrs({ children: '✔' })`
	color: #3ea742;
	font-size: 4rem;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
	animation: ${appear} 1.5s linear;
`;

const Loader = ({ children, config, ...rest }) => {
	return (
		<LoaderDiv config={config} {...rest}>
			{config?.done && <LoaderDone />}
			{children}
		</LoaderDiv>
	);
};

export default Loader;
