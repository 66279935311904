import styled, { css } from 'styled-components';

import FaIcon from 'components/ui/Icons';

export const PickerContainer = styled.div`
	position: relative;
	align-self: flex-start;
	place-self: center;

	@media (min-width: 1440px) {
		grid-column: span 2;
	}
`;

export const ListContainer = styled.div`
	position: absolute;
	display: flex;
	border: 2px solid lightgray;
	width: max-content;
	top: -20px;
	left: -5px;
	z-index: 1;
`;

export const ListStyling = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
`;

export const FirstColumn = styled(ListStyling)`
	width: 30%;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: column;
	padding-top: 50px;
	max-height: 500px;
`;

export const SecondColumn = styled(ListStyling)`
	display: flex;
	flex-direction: column;
	background-color: white;
	width: 100%;
`;

export const ColumnItem = styled.li`
	padding: 10px 15px;
	font-weight: 600;
	cursor: default;
	${(props) => props.disabled && 'color: lightgray;'}
	${(props) => props.active && 'background-color: #3FBC80;'}

	&:hover {
		${(props) =>
			!props.disabled &&
			css`
				background-color: #3fbc80;
				cursor: pointer;
			`}
	}
`;

export const DownArrow = styled(FaIcon)`
	color: #000;
	font-size: 20px;
	font-weight: 500;
	margin-left: 15px;
`;

export const DropdownList = styled.button`
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px 5px 20px;
	border: 3px solid lightgray;
	cursor: pointer;

	&:disabled {
		cursor: default;
		background-color: #fbfbfb;
		color: #aaaaaa;

		${DownArrow} {
			color: #959595;
		}
	}
`;
