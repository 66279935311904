import styled from 'styled-components';

export const ButtonsGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
`;

export const CardButton = styled.button`
	min-width: fit-content;
	width: 22%;
	padding: 0 10px;
	border: solid 2px #e2e8f0;
	border-radius: 5px;
	background-color: #ffffff;
	color: #000000;
	cursor: pointer;

	&:hover {
		border-color: #000000;
	}
`;

export const CardControls = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const CardItem = styled.div`
	margin-bottom: 10px;
`;
