import React from 'react';

import { TailSpin } from 'react-loader-spinner';

import {
	Container,
	FormContainer,
	FormSpinnerContainer,
	LoadingMessage,
	SpinnerSvg
} from './style';

export const PrimeSpinner = ({ message, flexDirection, justifyContent }) => {
	return (
		<Container flexDirection={flexDirection} justifyContent={justifyContent}>
			<SpinnerSvg viewBox="0 0 50 50">
				<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
			</SpinnerSvg>
			{message && <span>{message}</span>}
		</Container>
	);
};

export const FormSpinner = () => {
	return (
		<FormContainer>
			<FormSpinnerContainer>
				<TailSpin color="rgb(83,198,145)" />
			</FormSpinnerContainer>
		</FormContainer>
	);
};

export const Loader = ({ message = 'Loading', color, isShown }) => {
	if (!isShown) return null;

	return <LoadingMessage color={color}>{message}</LoadingMessage>;
};
