import { Button } from 'components/ui/Buttons';
import React, { useMemo } from 'react';
import { DotsBlock, MoveIcon, NavBlock, PageLink, PagesList } from './style';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
	let i = from;
	const range = [];

	while (i <= to) {
		range.push(i);
		i += step;
	}
	return range;
};

const Pagination = ({ currentPage = 1, onPageChange, totalPages = 0, pageNeighbours = 3 }) => {
	const createPageNumbers = () => {
		/**
		 * totalNumbers: the total page numbers to show on the control
		 * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
		 */
		const totalNumbers = pageNeighbours * 2 + 3;
		const totalBlocks = totalNumbers + 2;

		if (totalPages > totalBlocks) {
			const startPage = Math.max(2, currentPage - pageNeighbours);
			const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
			let pages = range(startPage, endPage);

			/**
			 * hasLeftSpill: has hidden pages to the left
			 * hasRightSpill: has hidden pages to the right
			 * spillOffset: number of hidden pages either to the left or to the right
			 */
			const hasLeftSpill = startPage > 2;
			const hasRightSpill = totalPages - endPage > 1;
			const spillOffset = totalNumbers - (pages.length + 1);

			switch (true) {
				// handle: (1) < {5 6} [7] {8 9} (10)
				case hasLeftSpill && !hasRightSpill: {
					const extraPages = range(startPage - spillOffset, startPage - 1);
					pages = [LEFT_PAGE, ...extraPages, ...pages];
					break;
				}

				// handle: (1) {2 3} [4] {5 6} > (10)
				case !hasLeftSpill && hasRightSpill: {
					const extraPages = range(endPage + 1, endPage + spillOffset);
					pages = [...pages, ...extraPages, RIGHT_PAGE];
					break;
				}

				// handle: (1) < {4 5} [6] {7 8} > (10)
				case hasLeftSpill && hasRightSpill:
				default: {
					pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
					break;
				}
			}

			return [1, ...pages, totalPages];
		}
		return range(1, totalPages);
	};

	const pages = useMemo(createPageNumbers, [totalPages, currentPage, pageNeighbours]);

	if (totalPages <= 1) return null;

	const handleMoveLeft = (e) => {
		e.preventDefault();
		if (currentPage - 1 < 1) return;
		onPageChange(currentPage - 1);
	};

	const handleMoveRight = (e) => {
		e.preventDefault();
		if (currentPage + 1 > totalPages) return;
		onPageChange(currentPage + 1);
	};

	function handlePageChange(page) {
		return (evt) => {
			evt.preventDefault();
			onPageChange(page);
		};
	}

	return (
		<NavBlock>
			<Button onClick={handleMoveLeft}>
				<MoveIcon icon="backNavigation" />
			</Button>

			<PagesList>
				{pages.map((page, index) => {
					if (page === LEFT_PAGE)
						return (
							<DotsBlock aria-hidden="true" key={page}>
								&#8230;
							</DotsBlock>
						);

					if (page === RIGHT_PAGE)
						return (
							<DotsBlock aria-hidden="true" key={page}>
								&#8230;
							</DotsBlock>
						);

					return (
						<li key={index}>
							<PageLink
								href="#"
								key={page}
								page={page}
								active={currentPage === page}
								onClick={handlePageChange(page)}
							>
								{page}
							</PageLink>
						</li>
					);
				})}
			</PagesList>

			<Button onClick={handleMoveRight}>
				<MoveIcon icon="backNavigation" reflect />
			</Button>
		</NavBlock>
	);
};

export default Pagination;
