const initialState = {
	settings: {},
	loading: true,
	error: null
};

const getUserSettings = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_USER_SETTINGS_REQUEST':
			return {
				...state,
				loading: true
			};

		case 'FETCH_USER_SETTINGS_SUCCESS':
			return {
				settings: { ...action.payload },
				loading: false,
				error: null
			};

		case 'FETCH_USER_SETTINGS_ERROR':
			return {
				settings: {},
				loading: false,
				error: action.payload
			};

		default:
			return state;
	}
};

export default getUserSettings;
