import { useState } from 'react';

import useSWR from 'swr';
import { useMediaQuery } from 'usehooks-ts';

import { RenderActivitiesList, activitiesListFetcher } from 'components/ActivitiesItem';
import PinnedLogs from 'components/ActivitiesItem/PinnedLogs';
import {
	ActivitiesContainer,
	ActivitiesHeader,
	ActivitiesTable
} from 'components/ActivitiesItem/style';
import Header from 'components/Header';
import Pagination from 'components/Pagination';
import { PrimeSpinner } from 'components/Spinners';

const Activities = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const pageLimit = 25;

	const { data, error, isLoading } = useSWR([pageLimit, currentPage], activitiesListFetcher, {
		shouldRetryOnError: false,
		revalidateOnFocus: false
	});
	const isMobile = useMediaQuery('(max-width: 575px)');

	if (isLoading) return <PrimeSpinner message="Loading activity feed" />;

	const handlePageChange = (page) => setCurrentPage(page);

	return (
		<>
			<ActivitiesContainer>
				<ActivitiesHeader>
					<Header text="Activity" icon="activity" />
				</ActivitiesHeader>
				<ActivitiesTable aria-label="My Activity">
					<tbody>
						<PinnedLogs />
						<RenderActivitiesList list={data?.data} error={error} />
					</tbody>
				</ActivitiesTable>
			</ActivitiesContainer>
			<Pagination
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPages={data?.totalPages}
				pageNeighbours={isMobile ? 0 : 2}
			/>
		</>
	);
};

export default Activities;
