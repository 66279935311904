import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Create from './Create';
import List from './List';
import Single from './Single';

const Stations = () => {
	return (
		<Routes>
			<Route path="/create" element={<Create />} />
			<Route path="/:id" element={<Single />} />
			<Route path="/" index element={<List />} />
		</Routes>
	);
};

export default Stations;
