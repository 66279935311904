export const cardElementOptions = {
	iconStyle: 'solid',
	style: {
		base: {
			iconColor: '#a4b1c3',
			fontWeight: 500,
			fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
			fontSize: '16px',
			fontSmoothing: 'antialiased',
			':-webkit-autofill': {
				color: '#fce883'
			},
			'::placeholder': {
				color: '#a4b1c3'
			}
		},
		invalid: {
			iconColor: '#e95582',
			color: '#d4869d'
		}
	}
};
