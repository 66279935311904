import { CircularProgress, CircularProgressText } from './style';

const CircularProgressbar = ({ total, value, text, className, radius = 50, strokeWidth = 10 }) => {
	const percentage = (value / total) * 100;
	const normalizedRadius = radius - strokeWidth / 2;
	const circumference = normalizedRadius * 2 * Math.PI;
	const strokeDashoffset = ((100 - percentage) / 100) * circumference;

	return (
		<svg height={radius * 2 + 1} width={radius * 2 + 1} className={className}>
			<CircularProgress
				r={normalizedRadius}
				cx={radius}
				cy={radius}
				stroke="#D6D6D6"
				strokeWidth={strokeWidth}
				strokeDasharray={`${circumference} ${circumference}`}
				stroke-dashoffset={0}
				transform={`rotate(-90 ${radius} ${radius})`}
			></CircularProgress>
			<CircularProgress
				r={normalizedRadius}
				cx={radius}
				cy={radius}
				stroke="#2BA185"
				strokeWidth={strokeWidth}
				strokeDasharray={`${circumference} ${circumference}`}
				strokeDashoffset={strokeDashoffset}
				transform={`rotate(-90 ${radius} ${radius})`}
			></CircularProgress>
			<CircularProgressText x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
				{text}
			</CircularProgressText>
		</svg>
	);
};

export default CircularProgressbar;
