import axios from 'axios';

const manageSubscription = async (planId, planTerm, promoCodeId) => {
	try {
		const {
			data: { subStatus }
		} = await axios.post('/subscription', { planId, planTerm, promoCodeId });

		return { subStatus, manageError: null };
	} catch (error) {
		return { subStatus: null, manageError: 'something wrong' };
	}
};

export default manageSubscription;
