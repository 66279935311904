import styled from 'styled-components';

import { NoticeBox } from './style';

const ServicePausedBanner = () => {
	return (
		<ServicePausedBox>
			<h2>Service is Paused</h2>
			BizPrint service has been paused and account restricted. Contact our{' '}
			<a href="https://getbizprint.com/support" target="_blanc" rel="noreferrer noopener">
				customer support team
			</a>
		</ServicePausedBox>
	);
};

export default ServicePausedBanner;

const ServicePausedBox = styled(NoticeBox)`
	margin-top: 60px;
	padding-bottom: 5px;
	background-color: #ff7313;

	h2 {
		margin: 0;
		padding: 0;
		font-size: 1.4rem;
		font-weight: 600;
	}

	a {
		font-weight: bold;
		text-underline-offset: 3px;
		color: #2c1c8a;

		&:hover {
			color: #241a58;
		}
	}

	@media only screen and (min-width: 992px) {
		margin-top: 0;
	}
`;
