import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { useAuthorization } from 'components/Authorization';
import Loader from 'components/Loader';

import { isMacOS } from 'utils/loadingContext';

import { Footer } from 'pages/Electron/components/Footer';
import { Logo } from 'pages/Electron/components/Logo';
import { SideBar } from 'pages/Electron/components/SideBar';
import { TrafficLights } from 'pages/Electron/components/TrafficLights';

import { useAccountInfo } from 'hooks/useAccountInfo';

import CreateStation from './Stations/Create';
import StationList from './Stations/List';

const ElectronRoutes = () => {
	const { isLoggedIn, isInitialChecking } = useAuthorization();

	if (isInitialChecking) return <Loader config={{ loading: true, screen: true }} />;

	if (!isLoggedIn) window.location.href = process.env.REACT_APP_LOGIN_BASE_API_URL;

	return (
		<Routes>
			<Route element={<Layout />}>
				<Route path="/" element={<Navigate to="/stations" />} />

				<Route path="/stations">
					<Route index element={<StationList />} />
					<Route path="create" element={<CreateStation />} />
				</Route>
			</Route>
		</Routes>
	);
};

export default ElectronRoutes;

const Layout = () => {
	const isWinControlsShown = isMacOS();

	const { account } = useAccountInfo();

	return (
		<Container>
			{isWinControlsShown ? <TrafficLights /> : null}

			<ContentContainer isWinControlsShown={isWinControlsShown}>
				<SideBar isMacOS={isWinControlsShown} account={account} />

				<Logo />

				<Outlet />

				<Footer />
			</ContentContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100vh;
	overflow-y: auto;

	/* Customize the scrollbar */
	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track styles */
	::-webkit-scrollbar-track {
		background: #fafafa;
	}

	/* Thumb styles */
	::-webkit-scrollbar-thumb {
		background: #c6c6c6;
		border-radius: 6px;
	}

	/* Handle hover styles */
	::-webkit-scrollbar-thumb:hover {
		background: #b2b2b2;
	}
`;

const ContentContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	row-gap: 10px;
	width: 100%;
	height: 100vh;
	margin-top: ${({ isWinControlsShown }) => (isWinControlsShown ? '24px' : 0)};
`;
