import styled from 'styled-components';

import { CopyButton } from 'components/ui/Buttons';

const KeyOptions = ({ publicKey, secretKey }) => {
	return (
		<KeysBlock>
			<KeyOption>
				<b>Public Key</b>
				<span>{publicKey}</span>

				<CopyButton copy={publicKey} aria-label="Copy the public key" title="Copy the public key" />
			</KeyOption>

			<KeyOption>
				<b>Secret Key</b>
				<span>{secretKey}</span>

				<CopyButton copy={secretKey} aria-label="Copy the secret key" title="Copy the secret key" />
			</KeyOption>
		</KeysBlock>
	);
};

export default KeyOptions;

const KeysBlock = styled.div`
	margin: 10px 0;
	padding: 5px 0;
	border: 3px solid rgba(240, 240, 240, 0.5);
	border-left: none;
	border-right: none;
`;

const KeyOption = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	margin: 3px 20px;

	span {
		flex-grow: 1;
		margin-left: 40px;
		line-break: anywhere;
	}

	@media (max-width: 600px) {
		justify-content: space-between;

		span {
			order: 3;
			width: 100%;
			margin: 0;
		}
	}
`;
