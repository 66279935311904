import styled from 'styled-components';

// List.jsx
export const Table = styled.table`
	margin-top: 1.2rem;
	border-spacing: 0px;
	border-collapse: collapse;
	width: 100%;

	thead {
		border-bottom: 3px solid rgba(240, 240, 240, 0.5);
	}

	tbody tr:not(.nohover):hover {
		background-color: rgba(240, 240, 240, 0.5);

		td:last-of-type {
			background-color: rgba(225, 255, 240, 0.5);
		}
	}

	tbody tr.nohover td {
		height: 50px;
		font-size: 1.125rem;
		text-align: center;
	}

	th,
	td {
		width: 300px;
		padding: 10px 30px;
		text-align: center;
		word-break: break-word;
	}

	th {
		font-size: 1.125rem;
		cursor: default;
	}

	td {
		font-size: 1rem;
	}

	tbody td {
		padding: 0;
		height: 30px;
	}

	tr:not(.nohover) {
		th:first-of-type,
		td:first-of-type {
			text-align: left;
		}

		th:last-of-type,
		td:last-of-type {
			width: 120px;
			padding: 0;
		}
	}
`;

export const Label = styled.label`
	display: block;
	padding: 10px 30px;
	cursor: pointer;
`;

export const RadioButton = styled.input`
	display: block;
	margin: auto;
	width: 25px;
	height: 25px;
	cursor: pointer;
`;

export const ButtonsGroup = styled.div`
	width: 100%;
	min-height: 35px;
	margin-top: 5px;
`;

export const AssignButton = styled.button`
	display: block;
	margin-left: auto;
	padding: 8px 16px;
	background-color: #50c792;
	color: #fff;
	border: none;
	border-radius: 6px;
	font-weight: bold;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: #3ab67f;
	}

	:disabled {
		background-color: #ebebe4;
		color: #c6c6c6;
	}
`;

// Create.jsx
export const CreateButton = styled(AssignButton)`
	padding: 10px 40px;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 30px 30px 20px;
`;

export const FormControl = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 30px;

	label {
		font-size: 1.1rem;
		font-weight: 600;
		cursor: pointer;
	}

	input {
		width: 100%;
		height: 50px;
		margin-left: 40px;
		padding: 0 10px;
		border: 3px solid #e6e6e6;
		border-radius: 5px;
		font-size: 16px;
	}
`;
