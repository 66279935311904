import { NavLink, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/Header';
import CustomContainer from 'components/ui/Container';

import Stats from 'pages/AdminPanel/Stats';
import Users from 'pages/AdminPanel/Users';

const AdminPanelRoot = () => {
	return (
		<Routes>
			<Route path="users" element={<Users />} />
			<Route path="Stats" element={<Stats />} />
			<Route path="/" element={<AdminPanel />} />
		</Routes>
	);
};

const AdminPanel = () => {
	return (
		<Container>
			<Header text="Admin Panel" />
			<UL>
				<li>
					<NavLink to="users">Users</NavLink>
				</li>
				<li>
					<NavLink to="stats">Stats</NavLink>
				</li>
			</UL>
		</Container>
	);
};

export default AdminPanelRoot;

const Container = styled(CustomContainer)`
	width: 500px;
	max-width: 100%;
`;

const UL = styled.ul`
	display: flex;
	justify-content: space-between;

	width: calc(100% - 4rem);
	list-style: none;
	padding: 0.5rem;
	margin: 2rem;

	li {
		margin: 0.5rem 0;
		width: calc(50% - 1rem);
		aspect-ratio: 1;

		a {
			padding: 1rem;
			border: solid 1px #cbd5e0;
			border-radius: 1rem;
			font-size: 1.5rem;
			color: #4a5568;
			text-decoration: none;

			&:hover {
				color: #2d3748;
				background-color: #e2e8f0;
			}

			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;
