import styled from 'styled-components';

import CardDetails from './CardDetails';
import { NewCardForm } from './CardForms';
import { CardButton, CardControls, CardItem } from './styles';

const ActivePaymentMethod = ({ cards, onShowCardsList, ...props }) => {
	if (cards && cards.length) {
		// cards[0] - either active PM for current subscription or customer's default PM
		return (
			<CardItem>
				<Description>Use credit or debit card linked to BizSwoop account</Description>

				<CardControls>
					<CardDetails card={cards[0]} />

					<CardButton onClick={onShowCardsList}>Change</CardButton>
				</CardControls>
			</CardItem>
		);
	}

	return <NewCardForm {...props} />;
};

export default ActivePaymentMethod;

const Description = styled.p`
	margin: 1rem 0 0.25rem;
	font-size: 0.9rem;
	text-align: center;

	@media only screen and (min-width: 400px) {
		text-align: start;
	}
`;
