import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Header from 'components/Header';
import { PrimeSpinner } from 'components/Spinners';
import { DeleteButton } from 'components/ui/Buttons';
import Container from 'components/ui/Container';
import FaIcon from 'components/ui/Icons';
import PageHeader from 'components/ui/PageHeader';

import { deleteStation, fetchStationsList } from 'features/stations/stations-list';

import stationIcon from 'assets/pages-icon/station.svg';

import { OpeningLink, PrinterIcon, Table } from './style';

const Stations = () => {
	const { list, loading } = useSelector(({ stationsList }) => stationsList);

	const dispatch = useDispatch();

	const handleDeleteStation = useCallback(
		(id) => (e) => {
			e.preventDefault();

			if (window.confirm('Are you sure you want to permanently delete this station?')) {
				dispatch(deleteStation(id));
			}
		},
		[]
	);

	useEffect(() => {
		dispatch(fetchStationsList());
	}, []);

	if (loading) return <PrimeSpinner message="Loading stations" />;

	return (
		<Container>
			<PageHeader justify="space-between" padding="10px 30px" titleSize="28px">
				<FaIcon size="40px" />

				<Header text="My Stations" icon="stations" />

				<Link
					to={{ pathname: 'create', state: { from: '/stations' } }}
					aria-label="Create a new station"
				>
					<FaIcon icon="create" size="40px" />
				</Link>
			</PageHeader>

			<Table aria-label="My Stations">
				<thead>
					<tr>
						<th scope="col">Stations</th>

						<th scope="col">Printers</th>

						<th></th>
					</tr>
				</thead>

				<tbody>
					<RenderList list={list} handleDeleteStation={handleDeleteStation} />
				</tbody>
			</Table>
		</Container>
	);
};

export default Stations;

const RenderList = ({ list = [], handleDeleteStation }) => {
	if (!list.length) {
		return (
			<tr className="nohover">
				<td colSpan="3">There are no stations created yet</td>
			</tr>
		);
	}

	return list.map(({ id, name, Printers = [] }) => {
		const active = Printers.filter(({ status }) => status === 'online');

		return (
			<tr key={id}>
				<td className="name">
					<OpeningLink to={`${id}`}>
						<img height="30" src={stationIcon} alt="Station icon" />

						{name}
					</OpeningLink>
				</td>

				<td className="printersCount">
					<OpeningLink to={`${id}`}>
						<b>{active.length}</b>

						<PrinterIcon icon="printer" type="solid" />
					</OpeningLink>
				</td>

				<td className="delete">
					<DeleteButton onClick={handleDeleteStation(id)} aria-label={`Delete ${name} station`} />
				</td>
			</tr>
		);
	});
};
