import React, { useRef, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';

import CheckoutForm from 'pages/Stripe/CheckoutForm';
import SubscribePlans from 'pages/Stripe/SubscribePlans';

import { useCurrentPlan } from 'hooks/useCurrentPlan';

const stripePubKey = process.env.REACT_APP_STRIPE_PUBLICK_KEY;
const stripePromise = loadStripe(stripePubKey);

const Stripe = () => {
	const { currentPlan, isHavePaymentMethod } = useCurrentPlan();
	const formRef = useRef();

	const executeScrollToForm = () => {
		if (!formRef.current) return;
		formRef.current.scrollIntoView();
	};

	const [plan, setPlan] = useState({
		id: null,
		name: null,
		perMonth: null,
		isFree: false
	});

	const handleSelectPlan = (planData) => {
		setPlan(planData);
		if (formRef.current) formRef.current.scrollIntoView();
	};

	return (
		<Container>
			<Elements stripe={stripePromise}>
				<SubscribePlans
					currentPlan={currentPlan}
					selectedPlan={plan}
					selectPlan={handleSelectPlan}
					executeScrollToForm={executeScrollToForm}
				/>

				{(currentPlan?.id || plan.id) && (
					<CheckoutForm
						currPlan={currentPlan}
						plan={plan}
						formRef={formRef}
						isHavePaymentMethod={isHavePaymentMethod}
					/>
				)}
			</Elements>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin: auto;

	@media only screen and (min-width: 1400px) {
		align-items: stretch;
		max-width: 1440px;
	}
`;

export default Stripe;
