import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Loader from 'components/Loader';
import { PrimeSpinner } from 'components/Spinners';
import Toggler from 'components/ui/Toggler';

import { useUserSettings } from 'hooks/useUserSettings';

const Settings = () => {
	const [data, setData] = useState(null);
	const [submitting, setSubmitting] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState('Loading settings');

	const toggle = (key) => setData({ ...data, [key]: !data[key] });

	const { settings, updateSettings } = useUserSettings(submitting);

	useEffect(() => {
		setData(settings);
		setSubmitting(false);
	}, [settings]);

	const submit = async (event) => {
		event.preventDefault();
		setSubmitting(true);
		setLoadingMessage('Updating settings');

		updateSettings(data);
	};

	if (data === null || submitting) return <PrimeSpinner message={loadingMessage} />;

	return (
		<Container>
			<Heading>Settings</Heading>

			<Form onSubmit={submit}>
				<Group>
					<Label>
						<Toggler
							checked={!!data.archiveOfflineJobs}
							onClick={() => toggle('archiveOfflineJobs')}
						/>
						Archive offline print jobs
					</Label>

					<Description>
						If BizPrint Cloud station app is not connected or offline, print jobs will be archived
						automatically. Disable setting to print all jobs when BizPrint Cloud station app
						reconnects.
						<br />
						<i>
							Note: This could cause multiple print jobs to occur at one time until all pending
							print jobs are cleared.
						</i>
					</Description>

					<br />
					<br />

					<Label>
						<Toggler checked={!!data.enableDevMode} onClick={() => toggle('enableDevMode')} />
						Development Mode
					</Label>

					<Description>
						Enable development mode to perform testing of applications, stations and print
						templates.
						<br />
						<i>
							Note: Print receipt templates will display development mode characters. Print jobs
							while using development mode will not be counted against usage plans.
						</i>
					</Description>
				</Group>

				<Submit type="submit">Save</Submit>
			</Form>
		</Container>
	);
};

export default Settings;

const Container = styled.div`
	display: flex;
	width: 100%;
	padding: 10px;
	justify-content: center;
	text-align: justify;
	flex-direction: column;

	@media only screen and (min-width: 452px) {
		max-width: 75%;
	}

	@media only screen and (min-width: 992px) {
		max-width: 65%;
	}
`;

const Group = styled.div`
	padding: 1rem;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
	background: rgba(255, 255, 255, 0.7);
	border-radius: 20px;
`;

const Heading = styled.h1`
	text-align: center;
	font-size: 2rem;
`;

const Description = styled.div`
	margin-top: 0.5rem;

	& i {
		display: inline-block;
		margin-top: 0.5rem;
	}
`;

const Form = styled.form`
	display: grid;
`;

const Submit = styled.button`
	margin-top: 1rem;
	background-color: #3ea742;
	color: white;
	border: 0;
	padding: 0.5rem;
	border-radius: 0.5rem;
	font-size: 1.5rem;
	margin-left: auto;
	cursor: pointer;
`;

const Label = styled.label`
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	column-gap: 10px;
	text-align: left;
`;
