import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	list: [],
	loading: true,
	error: null
};

const stationListSlice = createSlice({
	name: 'stations-list',
	initialState,
	reducers: {
		handleLoading: (state) => {
			state.loading = true;
		},

		handleSuccess: (state) => {
			state.loading = false;
			state.error = null;
		},

		handleData: (state, action) => {
			state.list = action.payload;
			state.loading = false;
			state.error = null;
		},

		handleError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.list = [];
		}
	}
});

export default stationListSlice.reducer;

export const { handleLoading, handleSuccess, handleData, handleError } = stationListSlice.actions;

export const fetchStationsList = () => async (dispatch) => {
	dispatch(handleLoading());

	try {
		const { data: list = [] } = await axios.get(`stations`);

		dispatch(handleData(list));
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const createStation = (data) => async (dispatch) => {
	dispatch(handleLoading());

	try {
		const {
			data: { idStation = null }
		} = await axios.post(`station`, data);

		dispatch(handleSuccess());

		return idStation;
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const deleteStation = (id) => async (dispatch) => {
	try {
		await axios.delete(`stations/${id}`);

		dispatch(fetchStationsList());
	} catch (error) {
		dispatch(handleError(error));
	}
};
