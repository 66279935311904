import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { uniqueId } from 'lodash';

import { TRIGGER_ASSISTANT_REFRESH, SHOW_ASSISTANT } from 'reducers/assistant';

const initialState = [];

export const createErrorAlert = createAsyncThunk(
	'alertService/createErrorAlert',
	async ({ message }, { rejectWithValue, dispatch }) => {
		try {
			const { data: { data: { id } } } = await axios.post('activities', { name: message, feedType: 'errorNotification' });

			dispatch({ type: SHOW_ASSISTANT });
			dispatch({ type: TRIGGER_ASSISTANT_REFRESH })

			return { id, message, type: 'error' };
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const alertServiceSlice = createSlice({
	name: 'alertService',
	initialState,
	reducers: {
		addAlert: (state, action) => {
			const { message = '', type = 'success' } = action.payload;
			const id = uniqueId('alert_');
			const isAlertExist = state.some((alert) => alert.message === message);

			if (!isAlertExist) state.push({ id, message, type });
		},
		removeAlert: (state, action) => {
			const itemIndex = state.findIndex(({ id }) => id === action.payload);

			if (itemIndex !== -1) state.splice(itemIndex, 1);
		}
	},
	extraReducers: {
		[createErrorAlert.fulfilled]: (state, action) => {
			const { message } = action.payload;
			const isAlertExist = message && state.some((alert) => alert.message === message);

			if (!isAlertExist) state.push(action.payload);
		}
	}
});

export const { addAlert, removeAlert } = alertServiceSlice.actions;

export default alertServiceSlice.reducer;
