import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { useDispatch, useSelector } from 'react-redux';

import { useUserStatus } from 'components/UserStatus';

import { useAccountInfo } from 'hooks/useAccountInfo';

import { SHOW_ASSISTANT, HIDE_ASSISTANT } from 'reducers/assistant';

import logoWithTextImage from 'assets/logo-with-bizprint-text.png';
import logoImage from 'assets/logo.png';

import Assistant from './Assistant';
import AssistantIcon from './Assistant/NavIcon';
import ProfileMenu from './ProfileMenu';
import { BurgerMenu, Nav, NavIcon, NavLogo, SidebarIcon, TooltipNavLink } from './style';

const NavBar = ({ isAdmin }) => {
	const dispatch = useDispatch();

	const { showAssistant } = useSelector(({ assistant }) => assistant);

	const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
	const [menuExpanded, setMenuExpanded] = useState(true);

	const { isBanned } = useUserStatus();
	const isMobileMenu = useMediaQuery('(max-width: 992px)');
	const { account, loading } = useAccountInfo();

	const { fullName, email } = account || {};

	const toggleMenuExpanded = () => {
		dispatch({ type: HIDE_ASSISTANT });
		setMenuExpanded(!menuExpanded);
	};

	const toggleAssistant = () => {
		dispatch({ type: showAssistant ? HIDE_ASSISTANT : SHOW_ASSISTANT });
	};

	const toggleMobileMenu = () => setMobileMenuOpened(!mobileMenuOpened);

	const closeMobileMenu = () => setMobileMenuOpened(false);

	useEffect(() => {
		if (showAssistant) setMenuExpanded(false);
	}, [showAssistant]);

	return (
		<Nav mobileMenuOpened={mobileMenuOpened} expanded={menuExpanded}>
			<Link to="/" aria-label="Logo">
				<NavLogo
					src={menuExpanded && !isMobileMenu ? logoWithTextImage : logoImage}
					alt="Logo Image"
				/>
			</Link>

			<h1>BizSwoop</h1>

			<BurgerMenu onClick={toggleMobileMenu} active={mobileMenuOpened} />

			{showAssistant && <Assistant closeAssistant={() => dispatch({ type: HIDE_ASSISTANT })} />}

			<ul onClick={closeMobileMenu}>
				<li>
					<TooltipNavLink to="/" data-tooltip="Dashboard" aria-label="Dashboard">
						<NavIcon icon="dashboard" />
					</TooltipNavLink>
				</li>

				<li>
					<TooltipNavLink
						to="/print-analytics"
						data-tooltip="Print Analytics"
						aria-label="Print Analytics"
					>
						<NavIcon icon="printAnalytics" size="30px" />
					</TooltipNavLink>
				</li>

				<li>
					<TooltipNavLink
						disabled={isBanned}
						to="/applications"
						data-tooltip="Applications"
						aria-label="Applications"
					>
						<NavIcon icon="applications" />
					</TooltipNavLink>
				</li>

				<li>
					<TooltipNavLink
						disabled={isBanned}
						to="/stations"
						data-tooltip="Stations"
						aria-label="Stations"
					>
						<NavIcon icon="stations" />
					</TooltipNavLink>
				</li>

				<li>
					<TooltipNavLink to="/resources" data-tooltip="Resources" aria-label="Resources">
						<NavIcon icon="resources" />
					</TooltipNavLink>
				</li>

				{isAdmin && (
					<li>
						<TooltipNavLink
							disabled={isBanned}
							to="/admin-panel"
							data-tooltip="Admin Panel"
							aria-label="Admin Panel"
						>
							<NavIcon icon="adminPanel" />
						</TooltipNavLink>
					</li>
				)}

				<li>
					<TooltipNavLink
						disabled={isBanned}
						to="/activity"
						data-tooltip="Activity"
						aria-label="Activity"
					>
						<NavIcon icon="activity" />
					</TooltipNavLink>
				</li>

				<li>
					<TooltipNavLink to="/settings" data-tooltip="Settings" aria-label="Settings">
						<NavIcon icon="settings" />
					</TooltipNavLink>
				</li>

				<li role="separator" data-level="main"></li>

				<li className="footerNav assistant" onClick={toggleAssistant}>
					<AssistantIcon
						isMobileMenu={isMobileMenu}
						hideTooltip={menuExpanded || showAssistant}
						hideFirstTaskTooltip={isMobileMenu || showAssistant}
						toggleAssistant={toggleAssistant}						
					/>
				</li>

				{mobileMenuOpened && isMobileMenu ? (
					<>
						<li>
							<TooltipNavLink
								as="a"
								href={`${process.env.REACT_APP_LOGIN_BASE_API_URL}/user-setting`}
								target="_blank"
								data-tooltip="Account settings"
								aria-label="Account settings"
							>
								<NavIcon icon="profile" type="light" color="#777" />
							</TooltipNavLink>
						</li>

						<li>
							<TooltipNavLink to="/subscription-plans" data-tooltip="My Plan" aria-label="My plan">
								<NavIcon icon="plans" />
							</TooltipNavLink>
						</li>

						<li>
							<TooltipNavLink
								as="a"
								href={process.env.REACT_APP_LOGIN_BASE_API_URL}
								target="_blank"
								data-tooltip="Apps dashboard"
								aria-label="Applications dashboard"
							>
								<NavIcon icon="appsDashboard" type="regular" color="#777" />
							</TooltipNavLink>
						</li>

						<li>
							<TooltipNavLink to="/logout" data-tooltip="Logout" aria-label="Logout">
								<NavIcon icon="logout" />
							</TooltipNavLink>
						</li>
					</>
				) : (
					<>
						<li className="footerNav sidebar" onClick={toggleMenuExpanded}>
							<SidebarIcon />
						</li>

						<li className="footerNav profile">
							<NavIcon icon="profile" type="solid" />
							<ProfileMenu />
							{!isMobileMenu && menuExpanded && !loading && (
								<span>{fullName || email || 'Name not specified'}</span>
							)}
						</li>
					</>
				)}
			</ul>
		</Nav>
	);
};

export default NavBar;
