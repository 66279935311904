import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import {
	ColumnItem,
	DownArrow,
	DropdownList,
	FirstColumn,
	ListContainer,
	PickerContainer,
	SecondColumn
} from './style';

dayjs.extend(localeData);

const RenderList = ({ handleCloseList, showRange }) => {
	const [chosenYear, setChosenYear] = useState();

	const today = new Date();
	const monthsList = dayjs.months();
	const yearsList = Array.from(
		{ length: today.getFullYear() - 2019 },
		(_, i) => i + 2020
	).reverse();

	const pickedDate = (monthNumber, disabled = false) => {
		if (disabled) return;
		if (!monthNumber) {
			return handleCloseList();
		}

		const date = `${chosenYear}-${monthNumber}-1`;
		handleCloseList(date);
	};

	return (
		<ListContainer>
			<FirstColumn>
				{yearsList.map((year) => (
					<ColumnItem key={year} active={year === chosenYear} onClick={() => setChosenYear(year)}>
						{year}
					</ColumnItem>
				))}
			</FirstColumn>

			<SecondColumn>
				<ColumnItem onClick={() => pickedDate()}>Current Period</ColumnItem>
				{monthsList.map((monthName, index) => {
					const isFutureMonth = chosenYear === today.getFullYear() && index >= today.getMonth();
					const disabled = !chosenYear || isFutureMonth;
					const monthNumber = index + 1;
					const monthValue = showRange
						? `${monthName} - ${monthsList[monthNumber % 12]}`
						: monthName;

					return (
						<ColumnItem
							key={monthName}
							onClick={() => pickedDate(monthNumber, disabled)}
							disabled={disabled}
						>
							{monthValue}
						</ColumnItem>
					);
				})}
			</SecondColumn>
		</ListContainer>
	);
};

const PeriodPicker = ({ value, disabled, handleDateChange, showRange = false, className = '' }) => {
	const [displayedDate, setDisplayedDate] = useState(value);
	const [openList, setOpenList] = useState(false);

	const handleCloseList = (date) => {
		setOpenList(false);

		handleDateChange(date);
	};

	useEffect(() => {
		let formatedDate = value ? dayjs(value).format('YYYY MMMM') : 'Current period';

		if (value && showRange) {
			formatedDate += ` - ${dayjs(value).add(1, 'month').format('MMMM')}`;
		}

		setDisplayedDate(formatedDate);
	}, [value, showRange]);

	return (
		<PickerContainer className={className}>
			<DropdownList onClick={() => setOpenList(true)} disabled={disabled} type="button">
				{displayedDate}
				<DownArrow icon="angleDown" />
			</DropdownList>

			{openList && <RenderList handleCloseList={handleCloseList} showRange={showRange} />}
		</PickerContainer>
	);
};

export default PeriodPicker;
