import ActivitiesItem from 'components/ActivitiesItem';

import { AnalyticsItem, ApplicationsItem, PaymentItem, StationsItem } from './MainPageItems';
import { Container, ItemsContainer } from './style';

const Main = () => (
	<Container>
		<ItemsContainer>
			<AnalyticsItem />

			<PaymentItem />

			<ApplicationsItem />

			<StationsItem />

			<ActivitiesItem />
		</ItemsContainer>
	</Container>
);

export default Main;
