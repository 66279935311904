import styled, { css } from 'styled-components';

import CustomContainer from 'components/ui/Container';
import CustomTable from 'components/ui/CustomTable';

export const Container = styled(CustomContainer)`
	padding-bottom: 10px;
	margin: 2rem 1rem 1rem;

	@media only screen and (min-width: 992px) {
		margin-left: auto;
		margin-right: auto;
	}

	@media (max-width: 770px) {
		max-width: 600px;
	}
`;

export const Form = styled.form`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 15px;
	margin: 10px 20px 15px;
`;

export const Label = styled.label`
	display: flex;
	align-items: center;
	width: 320px;
	font-weight: bold;
	cursor: pointer;

	input {
		height: 26px;
		width: 200px;
		margin-left: 20px;
		padding: 2px 0 2px 10px;
		border: 1px solid grey;
		border-radius: 5px;
	}

	@media (max-width: 470px) {
		flex-direction: column;
		place-items: flex-start;
		width: auto;

		input {
			margin: 5px 0 0 0;
		}
	}
`;

export const Table = styled(CustomTable)`
	th,
	td {
		width: 100px;
		padding: 10px 5px;
		font-size: 0.8rem;
	}

	th:first-child,
	td:first-child {
		padding-left: 20px;
	}

	th:last-child,
	td:last-child {
		padding-right: 20px;
	}

	.fullName,
	.email {
		text-align: start;
		word-break: break-all;
	}

	.email {
		width: 200px;
	}

	.createdAt {
		width: 150px;
	}

	@media only screen and (min-width: 992px) {
		th,
		td {
			width: 150px;
			font-size: 1rem;
		}

		.email {
			width: 300px;
		}
	}
`;

export const StatusButton = styled.button`
	width: 100px;
	height: 25px;
	border: 0;
	border-radius: 2px;
	color: white;
	background-color: ${(props) => (props.status !== 'block' ? '#2d9631' : '#e40c14')};

	&:hover {
		background-color: ${(props) => (props.status !== 'block' ? '#61ab64' : '#e45b60')};
		cursor: pointer;
	}
`;

export const DateRangeContainer = styled.div`
	display: flex;

	.startDate {
		display: flex;
		align-items: center;

		&::after {
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: #7e7e7e;
			margin: 0 10px;
		}
	}

	input[type='checkbox'] {
		margin-left: 20px;
	}

	@media (max-width: 470px) {
		flex-wrap: wrap;

		.endDate {
			align-self: flex-start;
			margin-top: 10px;
		}

		input[type='checkbox'] {
			margin: 10px 0 0 15px;
		}
	}
`;

export const EmptyText = styled.p`
	text-align: center;
	margin: 16px;
`;

export const MobCard = styled.div`
	display: flex;
	flex-wrap: wrap;
	border: 3px solid #e6e6e6;
	border-radius: 5px;
	width: 93%;
	margin: 0 auto 20px;
`;

export const MobItem = styled.div`
	display: flex;
	align-items: center;
	padding: 15px;
	word-break: break-all;
	border-top: 3px solid #e6e6e6;
	width: 50%;

	${(props) =>
		props.label &&
		css`
			&::before {
				content: '${props.label}:';
				font-weight: 700;
				margin-right: 10px;
				word-break: keep-all;
			}
		`}

	&.fullName,
	&.email {
		width: 100%;
	}

	&:last-child {
		justify-content: center;
	}

	@media (min-width: 425px) {
		&:nth-child(odd) {
			border-right: 3px solid #e6e6e6;
		}

		&.fullName {
			border: none;
		}
	}

	@media (max-width: 425px) {
		width: 100%;

		&.fullName {
			border: none;
		}
	}
`;
