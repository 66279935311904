const initialState = {
	data: [],
	loading: true,
	error: null
};

const pinnedPrinters = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_PINNED_PRINTERS_REQUEST':
			return {
				data: [],
				loading: true,
				error: null
			};
		case 'FETCH_PINNED_PRINTERS_SUCCESS':
			return {
				data: action.payload,
				loading: false,
				error: null
			};
		case 'FETCH_PINNED_PRINTERS_ERROR':
			return {
				data: [],
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};

export default pinnedPrinters;
