import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/ui/Buttons';

import { updateStation } from 'features/stations/station';

import angleDownIcon from 'assets/pages-icon/angle-down.svg';

const MIN_SUPPORTED_VERSION_OPTIONS = '1.8.0';

const StationForm = ({ stationId, version, name, displayOptions, onSubmit }) => {
	const dispatch = useDispatch();

	const [formValues, setFormValues] = useState({ name, displayOptions });

	const isBannerShown =
		formValues.displayOptions !== 'all' && (!version || version < MIN_SUPPORTED_VERSION_OPTIONS);

	const handleFormChange = useCallback((e) => {
		setFormValues((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
	}, []);

	const handleStationUpdate = (e) => {
		e.preventDefault();

		if (onSubmit) onSubmit(formValues);

		dispatch(updateStation(stationId, formValues));
	};

	return (
		<Form onSubmit={handleStationUpdate}>
			<FormControl>
				<label htmlFor="stationName">Name</label>

				<input
					name="name"
					id="stationName"
					placeholder="Enter your station name"
					value={formValues.name}
					onChange={handleFormChange}
					required
				/>

				<Button type="submit" value="Update" aria-label="Update Station Name" />
			</FormControl>

			<FormControl>
				<label htmlFor="displayOptions">Display on Station</label>

				<select
					name="displayOptions"
					id="displayOptions"
					value={formValues.displayOptions}
					onChange={handleFormChange}
				>
					<option value="all">Analytics and Plan details</option>

					<option value="station_analytics">Station Analytics only</option>

					<option value="none">No Analytics</option>
				</select>

				<Button type="submit" value="Update" aria-label="Update Display Options" />
			</FormControl>

			{isBannerShown && (
				<p>
					Display on Station options supported on BizPrint App version{' '}
					{MIN_SUPPORTED_VERSION_OPTIONS} or later
				</p>
			)}
		</Form>
	);
};

export default StationForm;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	margin: 0 20px 20px;

	p {
		margin: 0;
		padding: 5px;
		margin-top: 10px;
		font-size: 0.95rem;
		text-align: center;
		background-color: #fcf4e7;
		color: #3d2012;
		border: 1px solid #e8ddcc;
		border-radius: 4px;
	}
`;

const FormControl = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;

	label {
		margin-right: 20px;
		font-weight: 600;
		cursor: pointer;
	}

	input {
		width: 100%;
		max-width: 320px;
		padding: 8px 10px;
		border: 3px solid #e6e6e6;
		border-radius: 5px;
		font-size: 16px;
	}

	button {
		width: 80px;
		margin-left: 20px;
		padding: 5px;
		border-radius: 5px;
	}

	select {
		appearance: none;
		border: 3px solid #e6e6e6;
		padding: 7px 30px 7px 5px;
		border-radius: 4px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;

		background-image: url(${angleDownIcon});
		background-color: #ffffff;
		background-repeat: no-repeat;
		background-position: right 5px center;
		background-size: 10px;
	}
`;
