import { useEffect, useState } from 'react';

import styled from 'styled-components';

import PaymentMethodEditForm from './PaymentMethodEditForm';
import PaymentMethodsList from './PaymentMethodsList';

const PaymentMethodsForm = ({
	cards,
	onHandleShowPM,
	onAttachNewCard,
	onSwitchCard,
	onUpdateCard,
	...props
}) => {
	const [isEditFormShown, setIsEditFormShown] = useState(false);
	const [cardToEdit, setCardToEdit] = useState(null);

	useEffect(() => {
		props.resetBillingDetails();
	}, []);

	const handleShowEditForm = (e, card = null) => {
		e.preventDefault();

		props.setErrorMessage(null);

		setCardToEdit(card);
		setIsEditFormShown((prev) => !prev);
	};

	return (
		<Form>
			<Title>Payment Methods</Title>

			{isEditFormShown ? (
				<PaymentMethodEditForm
					card={cardToEdit}
					onUpdateCard={onUpdateCard}
					onShowEditForm={handleShowEditForm}
					{...props}
				/>
			) : (
				<PaymentMethodsList
					cards={cards}
					handleShowPM={onHandleShowPM}
					handleShowEditForm={handleShowEditForm}
					onAttachNewCard={onAttachNewCard}
					onSwitchCard={onSwitchCard}
					{...props}
				/>
			)}
		</Form>
	);
};

export default PaymentMethodsForm;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 1rem;
	border: solid 2px #e2e8f0;
	background: #ffffff;

	@media only screen and (min-width: 450px) {
		border-radius: 10px;
	}
`;

const Title = styled.h1`
	margin-top: 0;
	text-align: center;
	color: #585858;
`;
