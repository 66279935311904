import styled from 'styled-components';

import logo from 'pages/Electron/assets/bizswoop-logo.png';
import { openLinkInBrowser } from 'pages/Electron/ipc';

export const Footer = () => {
	return (
		<Container>
			<ExternalLink href="https://www.bizswoop.com" onClick={openLinkInBrowser}>
				<img src={logo} width="25px" height="22px" alt="" aria-hidden="true" />
				BizSwoop
			</ExternalLink>

			<FooterNav>
				<ul>
					<li>
						<FooterLink href="https://www.bizswoop.com/privacy/" onClick={openLinkInBrowser}>
							Privacy Policy
						</FooterLink>
					</li>

					<li role="separator"></li>

					<li>
						<FooterLink href="https://www.bizswoop.com/terms/" onClick={openLinkInBrowser}>
							Terms
						</FooterLink>
					</li>
				</ul>
			</FooterNav>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ExternalLink = styled.a`
	text-decoration: none;
	display: flex;
	align-items: center;
	font-size: 1.3rem;
	font-weight: 600;
	color: #000000;

	img {
		margin-right: 2px;
	}
`;

const FooterNav = styled.nav`
	ul {
		list-style: none;
		display: flex;
		align-items: center;
		margin: 5px 0 10px;
		padding: 0;

		li[role='separator'] {
			width: 5px;
			height: 5px;
			margin: 0 5px;
			border-radius: 50%;
			background-color: #53c691;
		}
	}
`;

const FooterLink = styled.a`
	text-decoration: none;
	font-size: 0.9rem;
	color: #b0aeae;

	&:hover {
		color: #585858;
	}
`;
