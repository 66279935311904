import axios from 'axios';

const subscriptionPlansIsLoading = () => {
	return {
		type: 'FETCH_SUBSCRIPTION_PLANS_REQUEST'
	};
};
const subscriptionPlansRequested = (list) => {
	return {
		type: 'FETCH_SUBSCRIPTION_PLANS_SUCCESS',
		payload: list
	};
};
const subscriptionPlansError = (error) => {
	return {
		type: 'FETCH_SUBSCRIPTION_PLANS_ERROR',
		payload: error
	};
};

const fetchSubscriptionPlans = () => {
	return async (dispatch) => {
		dispatch(subscriptionPlansIsLoading());

		try {
			const {
				data: { plans = [] }
			} = await axios.get(`/subscription-plans`);

			const activePlans = plans.filter(({ isLegacy }) => !isLegacy);

			// Sort to store free plans at the beginning
			activePlans.sort((a, b) => b.isFree - a.isFree);

			dispatch(subscriptionPlansRequested(activePlans));
		} catch (e) {
			dispatch(subscriptionPlansError(e));
		}
	};
};

export { fetchSubscriptionPlans };
