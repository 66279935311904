import styled from 'styled-components';

export const NoticeBox = styled.div`
	width: 100%;
	padding: 0 10px 0 10px;
	text-align: center;
	line-height: 1.8;
	color: #ffffff;

	p {
		margin: 0 auto;
		padding: 0;
		font-size: 1rem;
		line-height: 1.8;
	}

	a {
		text-underline-offset: 4px;
		color: inherit;
	}

	@media only screen and (min-width: 992px) {
		padding: 5px 10px 5px 85px;
	}
`;
