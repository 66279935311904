import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentPlan } from 'actions/current-plan';

export const useCurrentPlan = () => {
	const { plan, loading, error, isHavePaymentMethod } = useSelector(
		({ currentPlan }) => currentPlan
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(currentPlan());
	}, []);

	return { currentPlan: plan, loading, error, isHavePaymentMethod };
};
