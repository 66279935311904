import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useUserStatus } from 'components/UserStatus';

import { NoticeBox } from './style';

const PaymentOverdueBanner = ({ currentPlan }) => {
	const { pathname } = useLocation();
	const { isBanned } = useUserStatus();

	const [month, year] = new Date()
		.toLocaleString('en-US', { month: 'long', year: 'numeric' })
		.split(' ');

	const pastDueInvoices = currentPlan?.pastDueInvoices;

	if (!pastDueInvoices?.length) return null;

	if (isBanned && pathname !== '/subscription-plans') return null;

	return (
		<PaymentOverdueBox>
			<h2>Account Payment Overdue</h2>

			<p>
				The services will pause effective at the end of {month}, {year}, if current account charges
				are not paid.
			</p>

			<OverdueInvoicesList invoices={pastDueInvoices} />
		</PaymentOverdueBox>
	);
};

export default PaymentOverdueBanner;

const OverdueInvoicesList = ({ invoices }) => {
	return (
		<InvoicesContainer>
			<p>
				Number of Outstanding Invoices for Payment: <b>{invoices.length}</b>
			</p>

			<InvoicesList>
				{invoices.map(({ number, invoice_pdf, hosted_invoice_url }) => (
					<li key={number}>
						<b>Invoice: {number}</b>

						<LinksGroup>
							{invoice_pdf && (
								<a aria-label="Download Invoice" href={invoice_pdf}>
									View Details
								</a>
							)}

							{hosted_invoice_url && (
								<a href={hosted_invoice_url} rel="noreferrer" target="_blank" data-role="payment">
									Make Payment Now
								</a>
							)}
						</LinksGroup>
					</li>
				))}
			</InvoicesList>
		</InvoicesContainer>
	);
};

const PaymentOverdueBox = styled(NoticeBox)`
	background-color: #ff5c5a;
	padding-bottom: 5px;

	h2 {
		margin: 0;
		padding: 0;
		font-size: 1.4rem;
		font-weight: 600;
	}
`;

const InvoicesContainer = styled.div`
	margin: 0 auto;
	max-width: 800px;
	border-radius: 4px;
	background-color: #ffffff;
	color: #000000;

	p {
		width: 100%;
		font-size: 0.9rem;
		text-align: center;
	}

	@media only screen and (min-width: 500px) {
		p {
			margin-bottom: 0.4rem;
			font-size: 1rem;
		}
	}
`;

const InvoicesList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	color: #000000;

	li {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		align-items: center;
		padding-bottom: 5px;

		&:hover {
			background-color: rgba(240, 240, 240, 0.5);
		}

		a {
			text-decoration: none;
			padding: 2px 8px;
			font-size: 1rem;
			font-weight: 600;
			border: 1px solid #8bc9dc;
			border-radius: 3px;
			box-shadow: rgb(0 0 0 / 10%) 0px 2px 3px;
			background-color: #ffffff;
			color: #8bc9dc;

			:hover {
				background-color: #8bc9dc;
				color: #ffffff;
			}

			&[data-role='payment'] {
				background-color: #3ea742;
				border-color: #3ea742;
				color: #ffffff;

				:hover {
					background-color: #2d9631;
				}
			}
		}
	}
`;

const LinksGroup = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	max-width: 340px;
`;
