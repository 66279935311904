import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CustomContainer from 'components/ui/Container';
import CustomTable from 'components/ui/CustomTable';
import FaIcon from 'components/ui/Icons';

export const Container = styled(CustomContainer)`
	align-items: stretch;
	width: 100%;
	max-width: 600px;
`;

export const Icon = styled(FaIcon)`
	font-size: 40px;
`;

export const PrintersList = styled(CustomTable)`
	th,
	td {
		padding: 0;
	}

	thead {
		border: none;

		th {
			padding: 10px 0;
		}
	}
`;

export const OpeningLink = styled(Link)`
	display: block;
	padding: 10px 30px;
	text-decoration: none;
	color: ${({ isDeleted }) => (isDeleted ? '#bababa' : '#000')};
`;
