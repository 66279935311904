import { NavLink } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { BasicBackground } from 'components/BasicLayout/style';
import Burger from 'components/ui/BurgerMenu';
import FaIcon from 'components/ui/Icons';

import sidebarLeftArrow from 'assets/sidebar-arrow-left.svg';
import sidebarRightArrow from 'assets/sidebar-arrow-right.svg';
import sidebarIcon from 'assets/sidebar-icon.svg';

import { AssistantIconText } from './Assistant/style';
import { ProfileMenuBlock } from './ProfileMenu/style';

const sidebarIconBounceAnimation = (directionValue) => keyframes`
	0%, 100% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(${directionValue}px);
	}
`;

export const SidebarIcon = styled.button`
	position: relative;
	width: 25px;
	height: 25px;
	background-image: url(${sidebarIcon});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: transparent;
	border: 0;
	cursor: pointer;
`;

export const NavLogo = styled.img`
	width: 100%;
`;

const expandedMenuMixin = css`
	width: 200px;

	${NavLogo} {
		display: block;
		width: 75%;
		margin-left: auto;
		margin-right: 30px;
	}

	${AssistantIconText} {
		display: flex;
	}

	> ul {
		align-items: flex-start;
		padding: 0 10px;

		> li {
			width: 100%;
			height: auto;
			margin-top: 10px;

			&.footerNav {
				justify-content: flex-start;
				padding: 0 10px;
				height: 40px;

				&.assistant {
					padding-left: 5px;
				}

				&.profile {
					width: calc(100% + 10px);

					> span {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				&.sidebar {
					${SidebarIcon} {
						width: 100%;
						background-position: left;
						margin: 0;
					}

					&:hover {
						${SidebarIcon} {
							background-image: url(${sidebarLeftArrow});
							animation: ${sidebarIconBounceAnimation(-10)} 2s 2;
						}
					}

					&::after {
						content: 'Collapse';
						position: absolute;
						left: 47px;
					}
				}

				> span {
					margin-left: 10px;
				}
			}

			> a {
				display: flex;
				flex-direction: row-reverse;
				justify-content: flex-end;
				padding: 10px 0 10px 5px;
				border-radius: 8px;
				color: #000;

				&:hover {
					background-color: #dbdbdb;

					&::before,
					&::after {
						transform: translate(0, 0);
					}
				}

				&::before,
				&::after {
					transform: scale(1);
					transition: unset;
				}

				&::before {
					position: unset;
					background-color: transparent;
				}

				&::after {
					display: none;
				}

				&.active {
					background-color: #cfe4cf;
					color: #2d9631;

					i {
						color: inherit;
					}

					&::after {
						display: block;
						width: 5px;
						height: 75%;
						border: 0;
						border-color: transparent;
						background-color: #2d9631;
						left: -10px;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				i {
					width: 40px;
					text-align: center;
				}
			}
		}
	}

	~ ${BasicBackground} {
		padding-left: 225px;
	}
`;

export const Nav = styled.nav`
	position: fixed;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 60px;
	background-color: #f8f8f8;
	z-index: 100;

	a:first-child {
		width: 60px;
	}

	h1 {
		margin: 0;
	}

	> ul {
		position: fixed;
		top: 60px;
		right: -200px;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		width: 200px;
		min-height: 450px;
		margin: 0;
		padding: 0 10px;
		list-style: none;
		background-image: linear-gradient(to left, #f8f8f8 200px, rgba(0, 0, 0, 0.25) 0);
		opacity: 0;
		transition: all 500ms ease;

		${(props) =>
			props.mobileMenuOpened &&
			css`
				width: 100%;
				transform: translateX(-200px);
				opacity: 1;
			`}

		> li {
			position: relative;
			display: flex;
			height: 40px;
			width: 40px;
			margin-top: 20px;
			margin-right: 140px;

			&.footerNav {
				margin-top: 10px;
			}

			&[role='separator'] {
				flex-grow: 1;
			}

			&[role='separator'] ~ li {
				&:last-child {
					margin-bottom: 20px;
				}
			}
		}
	}

	@media only screen and (max-width: 992px) {
		> ul > li.footerNav.assistant {
			width: 100%;
			max-width: 175px;
			margin: 0;

			.progressBar {
				width: 30px;
			}

			${AssistantIconText} {
				display: flex;
				margin-left: 15px;
			}
		}
	}

	@media only screen and (min-width: 992px) {
		display: flex;
		top: auto;
		left: auto;
		right: auto;
		flex-direction: column;
		width: 75px;
		height: 100vh;

		a:first-child {
			width: 100%;
		}

		h1 {
			display: none;
		}

		ul {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 0;
			background-image: none;
			opacity: 1;

			${(props) =>
				props.mobileMenuOpened &&
				css`
					transform: translateX(0);
				`}

			> li {
				justify-content: center;
				align-items: center;
				margin-right: 0;

				&.footerNav {
					cursor: pointer;

					&.profile {
						width: 100%;

						&:hover > ${ProfileMenuBlock} {
							transform: scale(1);
						}
					}

					&.sidebar {
						&:hover ${SidebarIcon} {
							width: 30px;
							background-image: url(${sidebarRightArrow});
							animation: ${sidebarIconBounceAnimation(10)} 2s 2;
						}
					}
				}
			}
		}

		${(props) => props.expanded && expandedMenuMixin}
	}
`;

export const BurgerMenu = styled(Burger)`
	margin-right: 10px;

	@media only screen and (min-width: 992px) {
		display: none;
	}
`;

export const TooltipNavLink = styled(NavLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: #585858;

	&::before,
	&::after {
		position: absolute;
		right: 0%;
		top: 50%;
		transform: translate(calc(100% + 10px), -50%) scale(1);
		transform-origin: left center;
	}

	&::before {
		content: attr(data-tooltip);
		width: max-content;
		font-weight: 600;
	}

	&.active i {
		color: #2e9531;
	}

	&[disabled] {
		cursor: not-allowed !important;
	}

	&[disabled]:active {
		pointer-events: none;
	}

	@media only screen and (min-width: 992px) {
		color: #fff;

		&::before,
		&::after {
			transform: translate(calc(100% + 10px), -50%) scale(0);
		}

		&::before {
			max-width: 150px;
			padding: 5px 10px;
			background-color: #585858;
			border-radius: 5px;
			font-weight: normal;
			text-align: justify;
			transition: transform 250ms, background-color 500ms ease-in;
		}

		&::after {
			content: '';
			border: 14px solid transparent;
			border-right-color: #585858;
			transition: transform 250ms, border-color 500ms ease-in;
		}

		&:hover::before {
			transform: translate(calc(100% + 15px), -50%) scale(1);
		}

		&:hover::after {
			transform: translate(calc(100% - 11px), -50%) scale(1);
		}
	}
`;

export const NavIcon = styled(FaIcon)`
	font-size: ${(props) => (props.icon === 'printAnalytics' && '30px') || '28px'};
`;

NavIcon.defaultProps = {
	type: 'light'
};
