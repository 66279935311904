import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CustomTable from 'components/ui/CustomTable';
import FaIcon from 'components/ui/Icons';

export const OpeningLink = styled(Link)`
	display: block;
	padding: 10px 30px;
	text-decoration: none;
	color: black;
`;

export const PrinterIcon = styled(FaIcon)`
	margin-left: 20px;
	font-size: 26px;
`;

export const Table = styled(CustomTable)`
	margin-top: 1rem;

	th,
	td {
		width: 300px;
		padding: 10px 30px;
	}

	tbody td {
		padding: 0;
	}

	tr:not(.nohover) {
		&:hover td:last-of-type {
			background-color: rgba(255, 98, 62, 0.1);
		}

		th:first-of-type,
		td:first-of-type {
			text-align: left;
		}

		th:last-of-type,
		td:last-of-type {
			width: 90px;

			button {
				margin: 0 auto;
			}
		}
	}

	@media (max-width: 480px) {
		width: 90vw;
		margin: 0;

		thead {
			display: none;
		}

		tbody tr:not(.nohover) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			border: 3px solid lightgray;
			border-radius: 10px;
			margin: 0 20px 10px;
			text-align: left;

			td {
				width: auto;
				max-width: none;

				&.name,
				&.printersCount {
					a {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				&.name {
					grid-column: span 2;
					text-align: left;
					font-weight: 700;
					border-bottom: 3px solid lightgray;

					a {
						justify-content: start;
						font-size: 18px;
					}

					img {
						margin-right: 10px;
					}
				}

				&.delete {
					width: auto;
					border-left: 3px solid lightgray;
				}

				a,
				button {
					padding: 15px;
				}
			}

			&:hover td {
				&.name,
				&.printersCount {
					background-color: transparent;
				}
			}
		}
	}

	@media (min-width: 481px) {
		tbody td {
			img {
				display: none;
			}
		}
	}
`;
