import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	list: [],
	loading: true,
	error: null
};

const applicationsListSlice = createSlice({
	name: 'applications-list',
	initialState,
	reducers: {
		handleLoading: (state) => {
			state.loading = true;
		},

		handleData: (state, action) => {
			state.list = action.payload;
			state.loading = false;
			state.error = null;
		},
		handleError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.list = [];
		}
	}
});

export default applicationsListSlice.reducer;

export const { handleLoading, handleData, handleError } = applicationsListSlice.actions;

export const fetchApplicationsList = () => async (dispatch) => {
	dispatch(handleLoading());
	try {
		const { data: list = [] } = await axios.get(`applications`);

		dispatch(handleData(list));
	} catch (e) {
		dispatch(handleError(e));
	}
};

export const createApplication = (data) => async (dispatch) => {
	dispatch(handleLoading());

	try {
		const {
			data: idApplication = null
		} = await axios.post(`applications`, data);

		return idApplication;
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const deleteApplication = (id) => async (dispatch) => {
	try {
		await axios.delete(`applications/${id}`);

		dispatch(fetchApplicationsList());
	} catch (e) {
		dispatch(handleError(e));
	}
};
