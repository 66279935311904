import styled from 'styled-components';

export default styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px 0 auto;
	padding: 0;
	background-color: #fff;
	border: 3px solid #e6e6e6;
	border-radius: 10px;

	@media only screen and (min-width: 992px) {
		margin-top: 90px;
	}

	@media (min-width: 600px) {
		min-width: 400px;
	}
`;
