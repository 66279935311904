import styled from 'styled-components';

import CustomTable from 'components/ui/CustomTable';

export const Table = styled(CustomTable)`
	margin-top: 1rem;

	td,
	th {
		width: 300px;
		padding: 12px 20px;
	}

	tr:not(.nohover) {
		cursor: pointer;

		th:last-of-type,
		td:last-of-type {
			width: 110px;

			i {
				color: #3ea742;
			}
		}
	}

	@media (max-width: 480px) {
		width: 90vw;
		margin: 0;

		thead {
			display: none;
		}

		tbody tr:not(.nohover) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			border: 3px solid lightgray;
			border-radius: 10px;
			margin: 0 20px 10px;
			text-align: left;

			td {
				width: auto;
				max-width: none;

				&.name {
					display: flex;
					align-items: center;
					padding-bottom: 0;
					font-size: 18px;

					img {
						margin-right: 10px;
					}
				}

				&.url {
					border-bottom: 3px solid lightgray;
					padding: 10px auto 5px;
				}

				&.name,
				&.url {
					grid-column: span 2;
					text-align: left;
					font-weight: 700;
				}

				&.isAuthorized {
					border-right: 3px solid lightgray;
					border-radius: 0 0 0 8px;
					background: #ff623e;

					i {
						color: white;
					}

					&.positive {
						background: #3ea742;
					}
				}

				&.usage {
					width: auto;
					margin: auto 0;
					font-size: 18px;
				}

				a {
					padding: 15px;
				}
			}

			&:is(:hover, :focus) td:is(.name, .url, .usage) {
				background-color: unset;
			}
		}
	}

	@media (min-width: 481px) {
		tbody td {
			&.isAuthorized,
			img {
				display: none;
			}
		}
	}
`;
