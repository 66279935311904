import styled from 'styled-components';

export const FooterBlock = styled.footer`
	display: flex;
	flex-direction: column;
	margin: 1rem 0;
`;

export const LogoBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: -4px;

	b {
		font-size: 1rem;
		text-align: center;
		font-weight: normal;
	}
`;

export const FooterNav = styled.nav`
	width: 100%;

	ul {
		display: flex;
		justify-content: center;
		margin: 0 0 14px;
		padding: 0;
		color: rgb(83, 198, 145);

		li {
			position: relative;
			display: flex;
			align-items: center;

			&:not(:first-child) {
				margin-left: 16px;

				&:before {
					position: absolute;
					left: -10px;
					content: '';
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: rgb(83, 198, 145);
				}
			}
		}
	}
`;

export const FooterLink = styled.a`
	font-size: 0.8rem;
	text-decoration: none;
	color: rgb(176, 174, 174);

	&:hover {
		color: #585858;
	}
`;

export const Img = styled.img`
	height: 30px;
	width: 30px;
`;

export const Paragraph = styled.p`
	font-size: 0.8rem;
	margin: 0.1rem;
	text-align: center;
`;
