import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import axios from 'axios';
import { Route, Routes } from 'react-router-dom';

import Deactivated from '../pages/Deactivated';

const UserStatusContext = createContext({ isBanned: false });

export const useUserStatus = () => useContext(UserStatusContext);

const UserStatus = (props) => {
	const [isBanned, setIsBanned] = useState(false);
	const [isBanChecking, setIsBanChecking] = useState(false);

	const checkUserBan = useCallback(async () => {
		setIsBanChecking(true);

		try {
			const { data } = await axios.get('user/check-ban');

			setIsBanned(data.isBanned);
		} catch (error) {}

		setIsBanChecking(false);
	}, []);

	useEffect(() => {
		checkUserBan();
	}, []);

	useEffect(() => {
		window.addEventListener('check-user-ban', checkUserBan);

		return () => window.removeEventListener('check-user-ban', checkUserBan);
	}, []);

	const value = useMemo(() => ({ isBanned, isBanChecking }), [isBanned, isBanChecking]);

	return (
		<UserStatusContext.Provider value={value}>
			<Routes>
				<Route path="/deactivated" element={<Deactivated />} />
				<Route path="*" element={<Content {...props} />} />
			</Routes>
		</UserStatusContext.Provider>
	);
};

const Content = (props) => props.children;

export default UserStatus;
