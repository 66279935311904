import styled from 'styled-components';

export default styled.div`
	position: relative;
	width: 100%;
	max-width: 500px;
	background-color: #fff;
	box-shadow: 0 0 0 2px #e6e6e6;
	border-radius: 10px;
`;
