import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { PrimeSpinner } from 'components/Spinners';
import FaIcon from 'components/ui/Icons';

import Container from 'pages/Electron/components/Container';
import Header from 'pages/Electron/components/Header';
import Heading from 'pages/Electron/components/Heading';
import { connectToStation } from 'pages/Electron/ipc';

import { fetchStationsList } from 'features/stations/stations-list';

import { AssignButton, ButtonsGroup, Label, RadioButton, Table } from './style';

const StationList = () => {
	const { list, loading } = useSelector(({ stationsList }) => stationsList);

	const [selectedStationId, setSelectedStationId] = useState(null);
	const [connectionKeys, setConnectionKeys] = useState(null);
	const [isConnecting, setIsConnecting] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchStationsList());
	}, []);

	const selectStation = ({ id, publicKey, secretKey }) => {
		setSelectedStationId(id);

		setConnectionKeys({ publicKey, secretKey });
	};

	const assignStation = async () => {
		setIsConnecting(true);

		try {
			await connectToStation(connectionKeys);
		} catch (error) {
			setIsConnecting(false);
		}
	};

	if (loading) return <PrimeSpinner />;

	return (
		<div>
			<Container>
				{isConnecting ? <ConnectingSpinner /> : null}

				<Header>
					<FaIcon width="40px" height="40px" />

					<Heading>My Stations</Heading>

					<Link
						to={{ pathname: 'create' }}
						state={{ from: '/stations' }}
						aria-label="Create a new station"
					>
						<FaIcon icon="create" size="40px" />
					</Link>
				</Header>

				<Table aria-label="My Stations">
					<thead>
						<tr>
							<th scope="col">Stations</th>
							<th scope="col">Select</th>
						</tr>
					</thead>

					<tbody>
						<RenderList
							list={list}
							selectedStationId={selectedStationId}
							selectStation={selectStation}
						/>
					</tbody>
				</Table>
			</Container>

			<ButtonsGroup>
				{selectedStationId && (
					<AssignButton disabled={isConnecting} onClick={assignStation}>
						Assign Station
					</AssignButton>
				)}
			</ButtonsGroup>
		</div>
	);
};

const RenderList = ({ list, selectedStationId, selectStation }) => {
	if (!list.length)
		return (
			<tr className="nohover">
				<td colSpan="2">There are no stations created yet</td>
			</tr>
		);

	return list.map((station) => {
		const { id, name } = station;

		return (
			<tr key={id}>
				<td className="name">
					<Label htmlFor={id}>{name}</Label>
				</td>

				<td>
					<RadioButton
						id={id}
						type="radio"
						name="station"
						checked={selectedStationId === id}
						onChange={() => selectStation(station)}
					/>
				</td>
			</tr>
		);
	});
};

export default StationList;

const rotate = keyframes` 
	100% { transform: rotate(1turn) };
`;

const ConnectingSpinner = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.1);

	::after {
		content: '';
		width: 50px;
		height: 50px;
		margin: auto;
		border-radius: 50%;
		background: radial-gradient(farthest-side, #53c691 94%, #0000) top/8px 8px no-repeat,
			conic-gradient(#0000 30%, #53c691);
		mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
		animation: ${rotate} 1s infinite linear;
	}
`;
