import styled, { css } from 'styled-components';

export default styled.div`
	position: relative;
	width: 100px;
	height: 40px;
	border: 2px solid ${(props) => (props.checked ? '#3ea742' : '#dfe0df')};
	border-radius: 3px;
	background-color: #ffffff;
	cursor: pointer;

	&:after {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		height: 100%;
		width: 50%;
		font-size: 15px;
		font-weight: 600;

		${(props) =>
			props.checked
				? css`
						content: 'ON';
						color: #fff;
						background-color: #3ea742;
						left: 0;
				  `
				: css`
						content: 'OFF';
						color: #aaa;
						background-color: #dfe0df;
						right: 0;
				  `}
	}
`;
