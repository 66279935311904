import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	data: null,
	loading: null,
	error: null,
	webhookTestConnectMark: false
};

export const fetchApplication = createAsyncThunk(
	'application/fetchApplication',
	async (id, { rejectWithValue }) => {
		try {
			const response = await axios.get(`applications/${id}`);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

const stateChangeLoading = (state) => {
	state.loading = true;
	state.webhookTestConnectMark = false;
	state.error = null;
};

const applicationSlice = createSlice({
	name: 'application',
	initialState,
	reducers: {
		loading: stateChangeLoading,
		handleData: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.error = null;
		},
		testConnect: (state, action) => {
			state.data.webhookFail = action.payload;
			state.webhookTestConnectMark = true;
			state.loading = false;
			state.error = null;
		},
		clearTestConnectMark: (state) => {
			state.webhookTestConnectMark = false;
		},
		handleError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		}
	},
	extraReducers: {
		[fetchApplication.pending]: stateChangeLoading,
		[fetchApplication.fulfilled]: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.error = null;
		},
		[fetchApplication.rejected]: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		}
	}
});

export default applicationSlice.reducer;

export const { loading, handleData, testConnect, clearTestConnectMark, handleError } =
	applicationSlice.actions;

export const testWebhookConnect = (id) => async (dispatch) => {
	dispatch(loading());

	try {
		const { data } = await axios.post(`/applications/${id}/actions/test-webhook-connect`);
		dispatch(testConnect(data));
	} catch (error) {
		if (error.response.status === 422) {
			dispatch(testConnect(error.response.data));
		} else {
			dispatch(handleError('Something went wrong'));
		}
	}
};

export const updateApplication = (data, id) => async (dispatch) => {
	dispatch(loading());
	dispatch(clearTestConnectMark());
	try {
		const response = await axios.put(`applications/${id}`, data);
		dispatch(handleData(response.data));
	} catch (e) {
		dispatch(handleError(e.response.data.errors));
	}
};
