import FaIcon from 'components/ui/Icons';
import styled, { css } from 'styled-components';

export const MoveIcon = styled(FaIcon)`
	font-size: 1.5rem;

	${(props) =>
		props.reflect &&
		css`
			transform: rotate(180deg);
		`}
`;

export const NavBlock = styled.nav`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	margin-bottom: auto;
`;

export const PagesList = styled.ul`
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;

	span {
		font-size: 1.2rem;
		line-height: 30px;
	}
`;

export const DotsBlock = styled.span`
	width: 30px;
	height: 30px;
	margin: 0 10px;
	text-align: center;
`;

export const PageLink = styled.a`
	float: left;
	padding: 0;
	width: 30px;
	height: 30px;
	margin: 0 10px;
	border-radius: 50%;
	line-height: 30px;
	font-size: 0.9rem;
	font-weight: 900;
	text-align: center;
	text-decoration: none;
	color: black;

	${(props) =>
		props.active &&
		css`
			 {
				color: #fff;
				background-color: #3ea742;
				font-size: 1rem;
				font-weight: 700;
			}
		`}

	${(props) =>
		props.page > 999 &&
		css`
			width: 35px;
			height: 35px;
			line-height: 35px;
			font-size: 0.7rem;

			&.active {
				font-size: 0.8rem;
			}
		`}
`;
