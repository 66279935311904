export const openLinkInBrowser = (e) => {
	e.preventDefault();

	if (!e.target?.href) return;

	window.ipcBridge.send('shell:open-external', e.target.href);
};

export const closeAuthWindow = async () => {
	await window.ipcBridge.invoke('window:close-sso-window');
};

export const connectToStation = async (stationKeys) => {
	if (!stationKeys) return;

	await window.ipcBridge.invoke('station:connect', stationKeys);
};

export const resetTokenValue = async () => {
	await window.ipcBridge.invoke('token:set-store', { token: null });
};
