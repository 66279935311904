const initialState = {
	list: [],
	totalRecords: 0,
	isPinned: false,
	loading: true,
	error: null
};

const getJobsList = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_JOBS_LIST_REQUEST':
			return {
				...state,
				loading: true
			};

		case 'FETCH_JOBS_LIST_SUCCESS':
			return {
				list: action.payload.data,
				totalRecords: action.payload.totalRecords,
				isPinned: action.payload.isPinned,
				loading: false,
				error: null
			};

		case 'FETCH_JOBS_LIST_ERROR':
			return {
				list: [],
				loading: false,
				isPinned: false,
				error: action.payload,
				totalRecords: 0
			};

		case 'UPDATE_JOBS_LIST_PIN_STATUS_SUCCESS':
			return {
				...state,
				isPinned: action.payload,
				loading: false
			};

		case 'UPDATE_JOBS_LIST_PIN_STATUS_ERROR':
			return {
				...state,
				loading: false
			};

		default:
			return state;
	}
};

export default getJobsList;
