import { useEffect, useState } from 'react';

import useUserTasks from 'hooks/swr/useUserTasks';
import { useUserSettings } from 'hooks/useUserSettings';

import CircularProgressbar from './CircularProgressbar';
import { AssistantIconContainer, AssistantTaskTooltip, AssistantHeaderIcon, AssistantIconText, AssistantTaskTooltipButton } from './style';

const AssistantIcon = ({ isMobileMenu, hideTooltip, hideFirstTaskTooltip, toggleAssistant }) => {
	const { data } = useUserTasks();
	const { settings } = useUserSettings();

	const [omitTaskTooltip, setOmitTaskTooltip] = useState(settings.firstTaskCompleted ?? true);

	useEffect(() => {
		setOmitTaskTooltip(true);
	}, [hideFirstTaskTooltip]);

	useEffect(() => {
		setOmitTaskTooltip(settings.firstTaskCompleted);
	}, [settings.firstTaskCompleted]);

	const handleTaskTooltipClick = () => {
		setOmitTaskTooltip(true);
		toggleAssistant();
	};

	return (
		<AssistantIconContainer hideTooltip={hideTooltip || !omitTaskTooltip}>
			<CircularProgressbar
				value={data.list.completed.length}
				text={data.leftToComplete}
				total={data.totalAll}
				radius={isMobileMenu ? 15 : 17}
				strokeWidth={isMobileMenu ? 4 : 5}
				className="progressBar"
			/>

			{!omitTaskTooltip && !hideFirstTaskTooltip && (
				<AssistantTaskTooltip onClick={(e) => e.stopPropagation()}>
					<h2>
						<AssistantHeaderIcon icon="stars" type="duotone" />
						Assistant
					</h2>
					<span>The easiest way to start using the BizPrint.</span>
					<AssistantTaskTooltipButton onClick={handleTaskTooltipClick}>Get Started</AssistantTaskTooltipButton>
				</AssistantTaskTooltip>
			)}

			<AssistantIconText>
				Assistant
				<span>{data.leftToComplete} remaining tasks</span>
			</AssistantIconText>
		</AssistantIconContainer>
	);
};

export default AssistantIcon;
