import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';

import Header from 'components/Header';
import PageHeader from 'components/ui/PageHeader';
import { PrimeSpinner } from 'components/Spinners';

import { createStation } from 'features/stations/stations-list';

import useUserTasks from 'hooks/swr/useUserTasks';

import { Button, Container, Form, HeaderIcon, Input, Label } from './style';

const Create = () => {
	const { mutate: updateUserTasksList } = useUserTasks();

	const [name, setName] = useState('');
	const [idStation, setIdStation] = useState(null);
	const [creatingStation, setCreatingStation] = useState(false);

	const dispatch = useDispatch();

	const from = useLocation()?.state?.from || '/';

	const onFormChange = (e) => setName(e.target.value);

	async function createNewStation(e) {
		e.preventDefault();

		setCreatingStation(true);

		const idStation = await dispatch(createStation({ name }));

		setCreatingStation(false);
		setIdStation(idStation);
		updateUserTasksList();
	}

	if (creatingStation) return <PrimeSpinner message="Creating station" />;

	if (idStation) return <Navigate to={`/stations/${idStation}`} />;

	return (
		<Container>
			<PageHeader>
				<Link to={from} aria-label="Back to the list of stations">
					<HeaderIcon icon="backNavigation" type="regular" />
				</Link>

				<Header text="Create Station" icon="stations" />

				<HeaderIcon />
			</PageHeader>

			<Form onSubmit={createNewStation}>
				<Label>
					Name:
					<Input
						type="text"
						name="name"
						onChange={onFormChange}
						placeholder="Enter your station name"
						required
					/>
				</Label>

				<Button type="submit" value="Create" />
			</Form>
		</Container>
	);
};

export default Create;
