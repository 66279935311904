import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobsCount } from 'actions/jobs-count';

export const useJobsCount = () => {
	const { perMonth, perDay, total, loading, error } = useSelector(({ jobsCount }) => jobsCount);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchJobsCount());
	}, []);

	return { perMonth, perDay, total, loading, error };
};
