import { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import FaIcon from 'components/ui/Icons';

import { closeAuthWindow, resetTokenValue } from 'pages/Electron/ipc';

import {
	Buttons,
	LogoutButton,
	Overlay,
	Sidebar,
	SidebarContent,
	ToggleSidebar,
	UserInfo
} from './style';

export const SideBar = ({ account, isMacOS }) => {
	const [isVisible, setIsVisible] = useState(false);

	const sideBarRef = useRef(null);

	const navigate = useNavigate();

	const toggleShowMenu = () => setIsVisible(!isVisible);

	useEffect(() => {
		function handleClickOutside(event) {
			if (isVisible && sideBarRef.current && !sideBarRef.current.contains(event.target)) {
				setIsVisible(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [sideBarRef, isVisible]);

	const logout = async (e) => {
		e.preventDefault();

		await resetTokenValue();

		navigate('/logout');
	};

	const goHome = async () => {
		await closeAuthWindow();
	};

	return (
		<>
			<Overlay isVisible={isVisible} />

			<Sidebar id="sidebar" ref={sideBarRef} isVisible={isVisible}>
				<ToggleSidebar
					onClick={toggleShowMenu}
					isVisible={isVisible}
					aria-expanded={isVisible}
					aria-controls="sidebar"
					aria-label={isVisible ? 'Hide SideBar' : 'Show SideBar'}
				/>

				<SidebarContent isMacOS={isMacOS}>
					<AccountInfo account={account} />

					<Buttons>
						<LogoutButton onClick={goHome} tabIndex={!isVisible ? -1 : 0}>
							<FaIcon icon="home" /> Home
						</LogoutButton>

						<LogoutButton onClick={logout} tabIndex={!isVisible ? -1 : 0}>
							<FaIcon icon="logout" /> Log Out
						</LogoutButton>
					</Buttons>
				</SidebarContent>
			</Sidebar>
		</>
	);
};

const AccountInfo = ({ account }) => {
	if (!account) return null;

	const { fullName, email } = account || {};

	return (
		<UserInfo role="region" aria-label="Account Information">
			<h3>
				<FaIcon icon="profile" type="solid" /> Account Information
			</h3>

			<strong aria-label="User Full Name">{fullName}</strong>
			<span aria-label="User Email">{email}</span>
		</UserInfo>
	);
};
