import { CardIcon, CardInfo, CardLink, CardTitle, Container } from './style';

const Resources = () => {
	return (
		<Container>
			<Card
				href="https://getbizprint.com/quick-start-guide/"
				icon="rocket"
				title="Quick Start Guide"
				info="Step by step guide to getting started"
			/>

			<Card
				href="https://bizswoop.atlassian.net/servicedesk/customer/portal/4/group/4/create/10039"
				icon="question"
				title="Support"
				info="Get support from real people"
			/>

			<Card
				href="https://getbizprint.com/documentation/"
				icon="shapes"
				title="Documentation"
				info="Help articles and tutorials"
			/>

			<Card
				href="https://getbizprint.com/documentation/developer/"
				icon="code"
				title="Developer Tools"
				info="Templates, Hooks, API and more"
			/>

			<Card
				href="https://getbizprint.com/marketplace/"
				icon="bags"
				title="Marketplace"
				info="Shop templates and add-on features"
			/>

			<Card
				href="https://getbizprint.com/hardware/"
				icon="hardware"
				title="Hardware"
				info="Understand supported hardware requirements"
			/>
		</Container>
	);
};

const Card = ({ href, icon, title, info }) => (
	<CardLink href={href} target="_blank">
		<CardTitle>
			<CardIcon icon={icon} />
			{title}
		</CardTitle>
		<CardIcon icon="arrowFromSquare" type="regular" />
		<CardInfo>{info}</CardInfo>
	</CardLink>
);

export default Resources;
