import axios from 'axios';

const settingsAreLoading = () => {
	return {
		type: 'FETCH_USER_SETTINGS_REQUEST'
	};
};
const settingsRequested = (settings) => {
	return {
		type: 'FETCH_USER_SETTINGS_SUCCESS',
		payload: settings
	};
};
const settingsError = (error) => {
	return {
		type: 'FETCH_USER_SETTINGS_ERROR',
		payload: error
	};
};

export const fetchUserSettings = () => async (dispatch) => {
	dispatch(settingsAreLoading());
	try {
		const { data } = await axios.get(`/setting`);

		dispatch(settingsRequested(data));
	} catch (e) {
		dispatch(settingsError(e));
	}
};

export const updateUserSettings = (settings) => async (dispatch) => {
	dispatch(settingsAreLoading());
	try {
		const { data } = await axios.post('/setting', settings);

		dispatch(settingsRequested(data));
	} catch (e) {
		dispatch(settingsError(e));
	}
};
