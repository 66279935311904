import IncludedFeatures from './IncludedFeatures';
import { PlanInfoCard } from './PlanInfoCard';
import SelectButton from './SelectButton';
import { Container } from './style';

const Plan = ({ selectPlan, selectedPlan, planData, currentPlan }) => {
	const { id, name, isFree } = planData;

	const submit = (event) => {
		event.preventDefault();
		selectPlan(planData);
	};

	return (
		<Container isFreePlan={isFree}>
			<PlanInfoCard planData={planData} />

			<SelectButton
				id={id}
				planName={name}
				currentPlan={currentPlan}
				selectedPlanId={selectedPlan.id}
				submit={submit}
			/>

			<IncludedFeatures planName={name} />
		</Container>
	);
};

export default Plan;
