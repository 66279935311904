import styled from 'styled-components';

export const FormSpinnerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	& svg:hover {
		background: transparent !important;
	}
`;

export const FormContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 8px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	gap: 10px;
	margin-left: -5px;
	flex-direction: ${(props) => props.flexDirection ?? 'column'};
	justify-content: ${(props) => props.justifyContent ?? 'center'};
`;

export const SpinnerSvg = styled.svg`
	animation: rotate 2s linear infinite;
	width: 40px;
	height: 40px;

	& .path {
		stroke: #5652bf;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;

export const LoadingMessage = styled.span`
	position: relative;
	display: flex;
	align-items: baseline;
	gap: 4px;
	font-size: clamp(1rem, 1vw + 1rem, 1.2rem);
	font-weight: bold;
	color: ${({ color }) => color ?? '#000000'};

	::after {
		content: '';
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: currentColor;
		animation: loader 1s linear infinite;
	}

	@keyframes loader {
		0 % {
			box-shadow: 8px 0 rgba(255, 255, 255, 0), 16px 0 rgba(255, 255, 255, 0);
		}

		50% {
			box-shadow: 8px 0 currentColor, 16px 0 rgba(255, 255, 255, 0);
		}

		100% {
			box-shadow: 8px 0 currentColor, 16px 0 currentColor;
		}
	}
`;
