import { createContext, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateBlockStatus } from 'actions/admin-panel-list';

import { EmptyText, MobCard, MobItem, StatusButton, Table } from './style';

const KEYS_LABELS = [
	['id', '#'],
	['fullName', 'Full Name'],
	['email', 'Email'],
	['plan', 'Plan'],
	['jobsCount', 'Total Jobs'],
	['jobsCountMonth', 'Jobs Current Period'],
	['jobsCountToday', 'Jobs Last 24 hours'],
	['createdAt', 'CreatedAt'],
	['block', 'Status']
];

const AdminPanelContext = createContext();

const AdminTable = ({ isMobile }) => {
	const { list } = useSelector(({ getAdminPanelList }) => getAdminPanelList);

	return (
		<AdminPanelContext.Provider value={isMobile}>
			<Wrapper>
				{!list.length ? (
					<NoData>No users were found by your request</NoData>
				) : (
					list.map((user) => (
						<Group key={user.id}>
							{KEYS_LABELS.map(([key, label]) => (
								<Item key={key} params={{ key, label }} userInfo={user} />
							))}
						</Group>
					))
				)}
			</Wrapper>
		</AdminPanelContext.Provider>
	);
};

const Heading = () => {
	return (
		<thead>
			<tr>
				{KEYS_LABELS.map(([key, label]) => (
					<th key={key} className={key}>
						{label}
					</th>
				))}
			</tr>
		</thead>
	);
};

const Wrapper = (props) => {
	const isMobile = useContext(AdminPanelContext);

	return isMobile ? (
		<div>{props.children}</div>
	) : (
		<Table>
			<Heading />
			<tbody>{props.children}</tbody>
		</Table>
	);
};

const NoData = ({ children }) => {
	const isMobile = useContext(AdminPanelContext);

	return isMobile ? (
		<EmptyText>{children}</EmptyText>
	) : (
		<tr className="nohover">
			<td colSpan={KEYS_LABELS.length}>{children}</td>
		</tr>
	);
};

const Group = (props) => {
	const isMobile = useContext(AdminPanelContext);

	return isMobile ? <MobCard>{props.children}</MobCard> : <tr>{props.children}</tr>;
};

const Item = ({ params, userInfo }) => {
	const isMobile = useContext(AdminPanelContext);
	const { key, label } = params;
	const value = userInfo[key];

	if (key === 'block') {
		return <StatusItem id={userInfo.id} status={userInfo.block} />;
	}

	if (isMobile) {
		if (key === 'id') return null;

		return (
			<MobItem className={key} label={label}>
				{value}
			</MobItem>
		);
	}

	return <td className={key}>{value}</td>;
};

const StatusItem = ({ id, status }) => {
	const dispatch = useDispatch();
	const isMobile = useContext(AdminPanelContext);

	const handleStatus = () => {
		dispatch(updateBlockStatus(id, status));
	};

	const Btn = () => (
		<StatusButton status={status} onClick={handleStatus}>
			{status === 'block' ? 'Blocked' : 'Active'}
		</StatusButton>
	);

	return isMobile ? (
		<MobItem>
			<Btn />
		</MobItem>
	) : (
		<td>
			<Btn />
		</td>
	);
};

export default AdminTable;
