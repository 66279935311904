const initialState = {
	perDay: null,
	perMonth: null,
	total: null,
	loading: true,
	error: null
};

const jobsCount = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_JOBS_COUNT_REQUEST':
			return {
				perDay: null,
				perMonth: null,
				total: null,
				loading: true,
				error: null
			};

		case 'FETCH_JOBS_COUNT_SUCCESS':
			return {
				perDay: action.payload.perDay,
				perMonth: action.payload.perMonth,
				total: action.payload.total,
				loading: false,
				error: null
			};

		case 'FETCH_JOBS_COUNT_ERROR':
			return {
				perDay: null,
				perMonth: null,
				total: null,
				loading: false,
				error: action.payload
			};

		default:
			return state;
	}
};

export default jobsCount;
