import axios from 'axios';

export const verifyPromoCode = async (promoCode, planId, planTerm) => {
	try {
		const { data } = await axios.post('/promotion-code', { promoCode, planId, planTerm });
		return data;
	} catch (e) {
		return { error: 'An error occurred while verifying the promo code.\nPlease try again later' };
	}
};

export const checkAppliedDiscount = async (planId, planTerm) => {
	try {
		const { data } = await axios.post(`/promotion-code/active`, { planId, planTerm });
		return data;
	} catch (e) {
		return {};
	}
};
