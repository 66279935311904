import styled from 'styled-components';

import logo from 'pages/Electron/assets/bizprint-logo.png';

export const Logo = () => <LogoImage width="150" height="150" src={logo} alt="BizPrint Logo" />;

const LogoImage = styled.img`
	width: 150px;
	height: 150px;
	padding-top: 10px;
`;
