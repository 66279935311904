import { useEffect, useState } from 'react';

import axios from 'axios';

const usePaymentMethods = (props) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [cards, setCards] = useState(null);

	useEffect(() => {
		getPaymentMethods();
	}, [props]);

	const getPaymentMethods = async () => {
		try {
			setError(null);
			setLoading(true);

			const { data } = await axios.get('/payment-methods');

			setCards(data);
		} catch (error) {
			setError('An error occurred while receiving payment methods.\nPlease try again later');
		}

		setLoading(false);
	};

	const setPaymentMethod = async (paymentMethodId, updateAfter = false) => {
		try {
			setError(null);
			setLoading(true);

			await axios.post('/payment-method', { paymentMethodId });

			if (updateAfter) await getPaymentMethods();
		} catch (error) {
			setError('An error occurred while attaching payment method.\nPlease try again later');
		}

		setLoading(false);
	};

	const updatePaymentMethod = async (paymentMethodId, prevPaymentMethodId = null) => {
		try {
			setError(null);
			setLoading(true);

			await axios.put('/payment-method', { paymentMethodId, prevPaymentMethodId });

			await getPaymentMethods();
		} catch (error) {
			setError('An error occurred while updating payment method.\nPlease try again later');

			setLoading(false);
		}
	};

	const deletePaymentMethod = async (id) => {
		try {
			setError(null);
			setLoading(true);

			await axios.delete(`/payment-methods/${id}`);

			await getPaymentMethods();
		} catch (e) {
			setError('An error occurred while deleting payment method.\nPlease try again later');

			setLoading(false);
		}
	};

	return {
		cards,
		loading,
		error,
		setError,
		setPaymentMethod,
		updatePaymentMethod,
		deletePaymentMethod
	};
};

export default usePaymentMethods;
