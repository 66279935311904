import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	countStations: null,
	countPrinters: null,
	loading: true,
	error: null
};

const stationsCountSlice = createSlice({
	name: 'stations-count',
	initialState,
	reducers: {
		handleLoading: (state) => {
			state.loading = true;
		},

		handleData: (state, action) => {
			const { countStations, countPrinters } = action.payload;

			state.countStations = countStations;
			state.countPrinters = countPrinters;
			state.loading = false;
			state.error = null;
		},

		handleError: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		}
	}
});

export default stationsCountSlice.reducer;

const { handleLoading, handleData, handleError } = stationsCountSlice.actions;

export const fetchStationsCount = () => async (dispatch) => {
	dispatch(handleLoading());

	try {
		const {
			data: { countStations = 0, countPrinters = 0 }
		} = await axios.get(`stations/count`);

		dispatch(handleData({ countStations, countPrinters }));
	} catch (error) {
		dispatch(handleError(error));
	}
};
