import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import Header from 'components/Header';
import { PrimeSpinner } from 'components/Spinners';
import Container from 'components/ui/Container';
import ErrorLabel from 'components/ui/ErrorLabel';
import FaIcon from 'components/ui/Icons';
import PageHeader from 'components/ui/PageHeader';

import { fetchApplicationsList } from 'features/applications/applications-list';

import applicationIcon from 'assets/pages-icon/application.svg';

import { Table } from './style';

const RenderAppList = ({ list }) => {
	const isMobile = useMediaQuery('(max-width: 480px)');

	const navigate = useNavigate();

	if (!list.length) {
		return (
			<tr className="nohover">
				<td colSpan="3">There are no applications created yet</td>
			</tr>
		);
	}

	const openAppDetails = (id) => {
		navigate(`/applications/${id}`);
	};

	return list.map(({ id, name, homeUrl, webhookFail, isFree }) => (
		<tr key={id} onClick={isMobile ? null : () => openAppDetails(id)} role="link" tabIndex="0">
			<td className="name">
				<img height="30" src={applicationIcon} alt="" />
				{name}
				{webhookFail.hasError && <ErrorLabel paddingLeft />}
			</td>

			<td className="url">{homeUrl}</td>

			<td
				className={`isAuthorized ${!webhookFail.hasError && 'positive'}`}
				onClick={isMobile ? () => openAppDetails(id) : null}
			>
				<FaIcon size="30px" icon={webhookFail.hasError ? 'cross' : 'check'} />
			</td>

			<td className="usage">
				{isFree ? (
					'Included'
				) : (
					<FaIcon icon="applicationPaid" size="25px" title="Paid Application" />
				)}
			</td>
		</tr>
	));
};

const List = () => {
	const dispatch = useDispatch();

	const { list, loading } = useSelector(({ applicationsList }) => applicationsList);

	useEffect(() => {
		dispatch(fetchApplicationsList());
	}, []);

	if (loading) return <PrimeSpinner message="Loading applications" />;

	return (
		<Container>
			<PageHeader>
				<FaIcon icon="empty" size="40px" />

				<Header text="My Applications" icon="applications" />

				<Link to="create" state={{ from: '/applications' }} aria-label="Crete a new application">
					<FaIcon icon="create" size="40px" />
				</Link>
			</PageHeader>

			<Table aria-label="My Applications">
				<thead>
					<tr>
						<th scope="col">Name</th>

						<th scope="col">URL/Domain</th>

						<th scope="col">Usage</th>
					</tr>
				</thead>

				<tbody>
					<RenderAppList list={list} />
				</tbody>
			</Table>
		</Container>
	);
};

export default List;
