import axios from 'axios';
import dayjs from 'dayjs';
import useSWR from 'swr';

import Header from 'components/Header';
import { NavIcon } from 'components/NavBar/style';
import { PrimeSpinner } from 'components/Spinners';
import { useUserStatus } from 'components/UserStatus';

import * as activityIcons from 'assets/feeds';

import PinnedLogs from './PinnedLogs';
import {
	ActivitiesHeader,
	ActivitiesItemContainer,
	ActivitiesLink,
	ActivitiesRow,
	ActivitiesTable,
	EmptyText
} from './style';

export const activitiesListFetcher = async (limit = 25, page = 1) => {
	const {
		data: { data },
		headers
	} = await axios.get(`activities?page=${page}&limit=${limit}`);

	const totalPages = headers['x-biz-total-pages'];

	return { data, totalPages };
};

export const RenderActivitiesList = ({ list, error = false }) => {
	if (error || !list?.length) {
		return (
			<tr className="nohover">
				<EmptyText colSpan="2">There are no events tracked yet</EmptyText>
			</tr>
		);
	}

	return list.map(({ id, name, createdAt, feedType }) => {
		const date = dayjs(createdAt);
		const localTime = date.format('YYYY/MM/DD HH:mm');
		const localTimeZoneRaw = date.format('ZZ');
		const localTimeZone = localTimeZoneRaw.endsWith('00')
			? localTimeZoneRaw.slice(0, -2)
			: localTimeZoneRaw;

		return (
			<ActivitiesRow className="nohover" key={id}>
				<td className="icon">
					<img width="45" height="45" src={activityIcons[feedType]} alt="Activity feed icon" />
				</td>

				<td className="info">
					<p>{name}</p>
					<span>{`${localTime} GMT${localTimeZone}`}</span>
				</td>
			</ActivitiesRow>
		);
	});
};

const ActivitiesItem = () => {
	const limit = 5;

	const { isBanned } = useUserStatus();
	const { data, error, isLoading } = useSWR([limit], activitiesListFetcher, {
		shouldRetryOnError: false,
		revalidateOnFocus: false
	});

	if (isLoading) return <PrimeSpinner message="Loading activities" />;

	return (
		<>
			<ActivitiesItemContainer isBanned={isBanned}>
				<ActivitiesHeader>
					<Header text="Activity" icon="activity" />
				</ActivitiesHeader>
				<ActivitiesTable aria-label="My Activity">
					<tbody>
						<PinnedLogs />
						<RenderActivitiesList list={data?.data} error={error} />
					</tbody>
				</ActivitiesTable>
			</ActivitiesItemContainer>
			<ActivitiesLink to="/activity" aria-label="View Activity page" as={isBanned && 'div'}>
				View Activity Feed
				<NavIcon icon="activity" />
			</ActivitiesLink>
		</>
	);
};

export default ActivitiesItem;
