import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { delay } from 'nanodelay';
import { useDispatch, useSelector } from 'react-redux';

import { addAlert, removeAlert } from 'features/alertService';

import { fetchUserSettings } from 'actions/user-settings';

import useUserTasks from 'hooks/swr/useUserTasks';
import { useUserSettings } from 'hooks/useUserSettings';

import { ANIMATION_DURATION, AlertBox, AlertContainer } from './style';

const AlertItem = ({ id, message, type }) => {
	const dispatch = useDispatch();

	const [hideAlert, setHideAlert] = useState(false);

	useEffect(() => {
		Promise.resolve()
			.then(() => delay(ANIMATION_DURATION + 3000))
			.then(() => setHideAlert(true))
			.then(() => delay(ANIMATION_DURATION))
			.then(() => dispatch(removeAlert(id)));
	}, []);

	return (
		<AlertBox type={type} hide={hideAlert}>
			{message}
		</AlertBox>
	);
};

const AlertService = () => {
	const dispatch = useDispatch();

	const alerts = useSelector(({ alertService }) => alertService);

	const { data, isLoading } = useUserTasks();
	const { settings } = useUserSettings();

	const [isInitialRendering, setIsInitialRendering] = useState(true);
	const [prevCompletedAssistantTasks, setPrevCompletedAssistantTasks] = useState(data.list.completed.length);

	useEffect(() => {
		if (!isLoading) setIsInitialRendering(false);
	}, [isLoading]);

	useEffect(() => {
		if (!isInitialRendering && data.list.completed.length > prevCompletedAssistantTasks) {
			const recentCompletedTask = data.list.completed.reduce((prev, cur) => {
				const createdAt = dayjs(prev.createdAt);

				if (createdAt.isBefore(cur.createdAt)) prev = cur;

				return prev;
			}, data.list.completed[0]);

			const bubbleMsg = `🎉 Assistant task completed: ${recentCompletedTask.name}`;
			dispatch(addAlert({ message: bubbleMsg, type: 'taskCompletion' }));

			if (!settings.firstTaskCompleted) dispatch(fetchUserSettings());
		}

		setPrevCompletedAssistantTasks(data.list.completed.length);
	}, [data]);

	return (
		<AlertContainer>
			{alerts.map(({ id, message, type }) => (
				<AlertItem key={id} id={id} message={message} type={type} />
			))}
		</AlertContainer>
	);
};

export default AlertService;
