import { useEffect } from 'react';

import styled from 'styled-components';

import FaIcon from 'components/ui/Icons';

// eslint-disable-next-line import/no-relative-parent-imports
import { StatusMessage } from '../styles';
import { CardEditForm } from './CardForms';
import { ButtonsGroup, CardButton } from './styles';

const PaymentMethodEditForm = ({
	card,
	billingDetails,
	errorMessage,
	setErrorMessage,
	onFormChange,
	onUpdateCard,
	onShowEditForm,
	resetBillingDetails
}) => {
	useEffect(() => {
		resetBillingDetails();
	}, []);

	if (!card) return null;

	return (
		<div>
			<Subtitle>
				Update {card.funding} card [•••• {card.last4}] details <br />
				or Add new card
			</Subtitle>

			{card.services.length > 1 && (
				<WarningBanner>
					<FaIcon icon="warning" type="regular" size="1rem" />
					Changes will be applied to each service <br /> [{card.services.join(', ')}]
				</WarningBanner>
			)}

			<CardEditForm
				formType="edit"
				billingDetails={billingDetails}
				setErrorMessage={setErrorMessage}
				onFormChange={onFormChange}
			/>

			<StatusMessage>{errorMessage}</StatusMessage>

			<ButtonsGroup>
				<CancelButton onClick={onShowEditForm}>Cancel</CancelButton>

				<UpdateButton id={card.id} color="secondary" btnRole="optional" onClick={onUpdateCard}>
					Update Information
				</UpdateButton>
			</ButtonsGroup>
		</div>
	);
};

export default PaymentMethodEditForm;

const Subtitle = styled.h2`
	margin-top: 0;
	font-size: 1.25rem;
	text-align: center;

	br {
		display: none;
	}

	@media only screen and (min-width: 400px) {
		br {
			display: block;
		}
	}
`;

const WarningBanner = styled.p`
	padding: 5px 10px;
	border: 1px solid #ffeeba;
	border-radius: 4px;
	background-color: #fff7dc;
	text-align: center;
	color: #856404;

	i {
		margin-right: 5px;
	}

	br {
		display: none;
	}

	@media only screen and (min-width: 400px) {
		br {
			display: block;
		}
	}
`;

const CancelButton = styled(CardButton)`
	padding: 8px 20px;
`;

const UpdateButton = styled(CardButton)`
	margin-left: auto;
	padding: 0 20px;
	border: solid 2px #53c691;
	background-color: #53c691;
	color: #ffffff;
`;
