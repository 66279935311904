import styled from 'styled-components';
const Deactivated = () => {
	return (
		<>
			<Container>
				<DivStyle>
					<h1>The account is not active</h1>
				</DivStyle>
				<SupportBlock>support@bizswoop.com</SupportBlock>
			</Container>
		</>
	);
};

const SupportBlock = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`;

const Container = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const DivStyle = styled.div`
	border-collapse: collapse;
	border-bottom: solid 1px black;
	border-bottom: 0.2rem solid #2d9631;
`;

export default Deactivated;
