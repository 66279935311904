import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	station: null,
	printers: [],
	loading: true,
	error: null
};

export const fetchStation = createAsyncThunk(
	'stations/fetchById',
	async (id, { rejectWithValue }) => {
		try {
			const { data } = await axios.get(`stations/${id}`);

			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const handleLoading = (state) => {
	state.loading = true;
};

const handleData = (state, action) => {
	const { Printers, ...stationDetails } = action.payload;

	state.station = stationDetails;
	state.printers = Printers ?? [];
	state.loading = false;
	state.error = null;
};

const handleError = (state, action) => {
	state.error = action.payload;
	state.loading = false;
};

const stationSlice = createSlice({
	name: 'station',
	initialState,
	reducers: { handleLoading, handleData, handleError },
	extraReducers: {
		[fetchStation.pending]: handleLoading,
		[fetchStation.fulfilled]: handleData,
		[fetchStation.rejected]: handleError
	}
});

export default stationSlice.reducer;

const stationActions = stationSlice.actions;

export const updateStation = (id, data) => async (dispatch) => {
	dispatch(stationActions.handleLoading());

	try {
		const { data: result } = await axios.put(`stations/${id}`, data);

		dispatch(stationActions.handleData(result));
	} catch (error) {
		dispatch(stationActions.handleError(error));
	}
};
