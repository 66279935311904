import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import FaIcon from 'components/ui/Icons';
import { Button as CustomButton } from 'components/ui/Buttons';

import assistantBricks from 'assets/assistant-bricks.png';

export const AssistantContainer = styled.div`
	position: absolute;
	left: 75px;
	width: 100vw;

	@media (max-width: 992px) {
		top: 100%;
		left: 0;
	}
`;

export const AssistantWrapper = styled.div`
	position: relative;
	width: fit-content;
`;

export const AssistantSidebar = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 500px;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
	background-image: url(${assistantBricks});
	background-repeat: no-repeat;
	background-size: 70%;
	background-position-x: right;
	padding: 90px 30px 30px;

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background-color: #f4f4f4;
	}

	::-webkit-scrollbar-thumb {
		background-color: #d1d1d1;
		border-radius: 6px;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #a0a0a0;
	}

	@media (max-width: 992px) {
		height: calc(100vh - 60px);
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const AssistantHeader = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 25px;

	.progressBar {
		min-width: 80px;

		text {
			fill: #000;
			font-size: 30px;
			font-weight: 500;
		}
	}

	@media (max-width: 320px) {
		.progressBar {
			min-width: 60px;

			text {
				font-size: 25px;
			}
		}
	}
`;

export const AssistantHeaderText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5px;

	h2 {
		margin: 0;
	}

	@media (max-width: 320px) {
		h2 {
			font-size: 20px;
		}
	}
`;

export const AssistantHeaderIcon = styled(FaIcon)`
	color: #1d3455;

	&:hover {
		color: #1d3455;
	}

	&::after {
		color: #4289e9;
	}
`;

export const AssistantTasks = styled.div`
	display: flex;
	flex-direction: column;

	> p {
		font-size: 15px;
		color: #297dfc;
		margin-bottom: 10px;
	}
`;

export const AssistantTaskSupport = styled(AssistantTasks)`
	> p {
		color: #E94141;
	}
`;

export const AssistantItem = styled.div`
	position: relative;
	padding: 20px;
	border: 2px solid lightgray;
	border-radius: 8px;
	margin-bottom: 25px;
	background-color: #fff;
`;

export const AssistantItemCompleted = styled(AssistantItem)`
	display: flex;
	align-items: center;
	font-size: 14px;

	> p {
		flex: 1;
		font-weight: 700;
		margin: 0 auto 0 10px;
	}

	> span {
		color: #b3b3b3;
		font-weight: 700;
	}
`;

export const AssistantItemText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: 14px;
	width: 75%;

	> p {
		font-weight: 700;
		margin: 0;
	}

	.dependency {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #f14499;
		margin-bottom: -10px;

		&:before {
			display: block;
			content: '';
			width: 10px;
			height: 10px;
			background-color: #f14499;
			border-radius: 50%;
		}
	}
`;

export const ConfirmationContainer = styled.div`
	text-align: center;

	p {
		font-size: 15px;
		font-weight: 700;
	}

	span {
		font-size: 14px;
		color: #b3b3b3;
	}
`;

export const ConfirmationButtons = styled.div`
	display: flex;
	justify-content: center;
	gap: 30px;
	margin: 20px 0 -10px;
`;

export const DoneButton = styled.button`
	color: #fff;
	border: 0;
	border-radius: 5px;
	padding: 10px 15px;
	cursor: pointer;
	background-color: #3ea742;

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}

	&:not(:disabled):hover {
		background-color: #2d9631;
	}
`;

export const CancelButton = styled(DoneButton)`
	background-color: #737373;

	&:not(:disabled):hover {
		background-color: #585757;
	}
`;

export const AssistantItemSupport = styled(AssistantItem)`
	background-color: #E94141;
	color: #fff;
	border-color: #000;

	${AssistantItemText} {
		width: 60%;
	}

	${ConfirmationContainer} {
		> span {
			color: #e9e9e9;
		}

		${DoneButton} {
			background-color: #fff;
			color: #000;

			&:hover {
				background-color: #D9D6DF;
			}
		}

		${CancelButton} {
			background-color: #737373;
			color: #fff;

			&:hover {
				background-color: #585757;
			}
		}
	}
`;

export const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 10px;
	border: 0;
	background-color: transparent;
	cursor: pointer;
`;

const taskActionLinkMixin = css`
	position: absolute;
	right: 20px;
	bottom: 15px;
	text-decoration: none;
	color: #fff;
	border: 0;
	border-radius: 5px;
	padding: 10px 15px;
	cursor: pointer;
	background-color: #3ea742;

	${(props) =>
		props.disabled
			? css`
					opacity: 0.5;
					cursor: default;
					pointer-events: none;
			`
			: css`
					&:hover {
						background-color: #2d9631;
					}
			`}
`;

export const TaskActionRouterLink = styled(Link)`
	${taskActionLinkMixin}
`;

export const TaskActionRegularLink = styled.a`
	${taskActionLinkMixin}
`;

export const SupportTaskActionLink = styled.a`
	${taskActionLinkMixin}
	background-color: #fff;
	color: #000;

	&:hover {
		background-color: #D9D6DF;
	}
`;

export const IframeLink = styled.a`
	${taskActionLinkMixin}
`;

export const ScheduleOnboardingIframeContainer = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 95%;
	max-width: 1400px;
	height: 600px;
	z-index: 1;

	.closeOnboardingIframe {
		position: absolute;
		right: 20px;
		top: 10px;
		border: 0;
		background-color: transparent;
		cursor: pointer;

		i {
			font-size: 25px;
		}
	}
`;

export const ScheduleOnboardingIframe = styled.iframe`
	border: 1px solid lightgray;
	border-radius: 10px;
	background-color: #fff;
	width: 100%;
	height: 100%;
	box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.2);
`;

export const AssistantIconContainer = styled.button`
	display: flex;
	align-items: center;
	background-color: transparent;
	border: 0;
	padding: 0;
	cursor: pointer;

	.progressBar {
		text {
			fill: #000;
			font-size: 13px;
			font-weight: 700;
		}
	}

	@media only screen and (min-width: 992px) {
		&::before,
		&::after {
			position: absolute;
			right: 0%;
			top: 50%;
			transform: translate(calc(100% + 10px), -50%) scale(0);
			transform-origin: left center;
		}

		&::before {
			content: 'Assistant';
			width: max-content;
			max-width: 150px;
			padding: 5px 10px;
			background-color: #585858;
			color: #fff;
			border-radius: 5px;
			font-weight: normal;
			text-align: justify;
			transition: transform 250ms, background-color 500ms ease-in;
		}

		&::after {
			content: '';
			border: 14px solid transparent;
			border-right-color: #585858;
			transition: transform 250ms, border-color 500ms ease-in;
		}

		${(props) => !props.hideTooltip && css`
			&:hover::before {
				transform: translate(calc(100% + 15px), -50%) scale(1);
			}

			&:hover::after {
				transform: translate(calc(100% - 11px), -50%) scale(1);
			}
		`}
	}
`;

export const AssistantTaskTooltip = styled.div`
	position: absolute;
	bottom: 35px;
	left: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
	border: 1px solid lightgray;
	border-radius: 15px 15px 15px 0;
	background-color: #fff;
	z-index: 1;
	padding: 10px 25px;
	cursor: auto;

	h2 {
		display: flex;
		gap: 10px;
		align-items: baseline;
		justify-content: center;
		margin: 0;
	}
`;

export const AssistantIconText = styled.p`
	display: none;
	flex-direction: column;
	text-align: left;
	margin: 0 0 0 10px;

	span {
		font-size: 12px;
	}
`;

export const AssistantTaskTooltipButton = styled(CustomButton)`
	width: 120px;
	height: 40px;
	font-weight: 600;
	color: #fff;
	background-color: #3ea742;
	margin: 0 auto;

	&:hover {
		background-color: #2d9631;
	}
`;

export const CircularProgress = styled.circle`
	fill: transparent;
	stroke-linecap: round;
	transition: stroke-dashoffset 0.25s linear;
`;

export const CircularProgressText = styled.text`
	font-size: 22px;
	fill: #000;
`;

export const CloseAssistantBtnWrapper = styled.div`
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	width: 35px;
	overflow-x: hidden;
`;

export const CloseAssistantBtn = styled.button`
	background-color: #fff;
	border: 8px solid #F4F4F4;
	border-right: 0;
	border-radius: 50% 0 0 50%;
	padding: 8px 35px 8px 10px;
	cursor: pointer;

	i {
		color: #A3A2A2;
		font-size: 30px;
		font-weight: 500;

		&:hover {
			color: #A3A2A2;
		}
	}
`;
