import 'fonts/index.css';
import Pages from 'pages';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthorization } from 'components/Authorization';
import Loader from 'components/Loader';
import { PrimeSpinner } from 'components/Spinners';

import Landing from 'pages/Landing';

import { useCurrentPlan } from 'hooks/useCurrentPlan';

import './app.css';

const App = () => {
	const { isLoggedIn, isInitialChecking } = useAuthorization();
	const { currentPlan, loading } = useCurrentPlan();
	const { pathname } = useLocation();

	if (!isLoggedIn) return <Landing />;

	if (loading) return <PrimeSpinner />;

	if (isInitialChecking) {
		return <Loader config={{ loading: true, screen: true }} />;
	}

	if (!currentPlan && !pathname.includes('subscription-plans')) {
		return <Navigate to="/subscription-plans" />;
	}

	return <Pages />;
};

export default App;
